.fingerPrintModal
{
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 990;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);

	&_inner
    {
        position: relative;
    }

	&_box
	{
		max-width: 650px;
		max-height: 100vh;
		background-color: white;
		border-radius: 10px;
		overflow: auto;
        text-align: center;
        padding: 30px;

        textarea
        {
            width: 400px;
            height: 100px;
            border: 1px solid gray;
            border-radius: 5px;
            outline: none;
            padding: 5px;
            font-size: 14px;
        }

		img
		{
			margin: auto;
            width: 450px;
		}

		p
		{
			text-align: center;
			margin: 10px 0;
			font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
			font-weight: 600;
			color: gray;
		}
	}

    &_btns
    {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
    }

    &_close
    {
        position: absolute;
        right: -35px;
        top: 5px;
        font-size: 30px;
        cursor: pointer;
        font-family: sans-serif;
		color: white;
    }

	.arrow-right {
		color: black;
		font-size: 40px;
		position: absolute;
		top: 50%;
		right: 20px;
		border: 2px solid black;
		border-radius: 100%;
		padding: 10px;
		cursor: pointer;
	}

	.arrow-left {
		color: black;
		font-size: 40px;
		position: absolute;
		top: 50%;
		left: 20px;
		border: 2px solid black;
		border-radius: 100%;
		padding: 10px;
		cursor: pointer;
	}

    .download-btn {
        background: -webkit-linear-gradient(right,
                #e9dd9c,
                #a87833,
                hsl(37, 37%, 45%),
                #c9a465,
                #ab915d);
        border-radius: 50px;
        cursor: pointer;
        padding: 10px 20px;
        color: white;
        font-family: raven-bold-inline !important;
        font-size: 12px;
    }

    &_adminRes {
        h5, p {
            text-align: left;
        }

        h5 {
            margin-bottom: -10px;
        }
    }
}
.videoModal
{
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1060;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);

    &_inner
    {
        width: 750px;
        max-height: 100vh;
        overflow: auto;
        background-color: white;
        border-radius: 10px;
        position: relative;
        padding: 40px 0 0;
    }

    &_close
    {
        position: absolute;
        right: 10px;
        top: 5px;
        font-size: 22px;
        cursor: pointer;
    }

    .youtube-container > div {
        overflow: hidden;
        width: 100%;
        /* Keep it the right aspect-ratio */
        aspect-ratio: 16/9;
        /* No clicking/hover effects */
        pointer-events: none;
    } 
    
    .youtube-container > div iframe {
        /* Extend it beyond the viewport... */
        width: 300%;
        height: 100%;
        /* ...and bring it back again */
        margin-left: -100%;
    }

    &_text
    {
        padding: 10px;
        margin-bottom: 0;
    }

    &_text img
    {
        width: 20px;
        display: inline-block;
    }

    &_next
    {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 10px;
        font-family: segoe ui;
        font-size: 20px;
        font-weight: 500;
    }

    &_info
    {
        display: flex;
        align-items: center;
        padding: 10px 10px 0;
        font-family: segoe ui;

        img
        {
            cursor: pointer;
            width: 100px;
        }

        p
        {
            margin-bottom: 0;
            font-weight: 600;
            font-size: 15px;

            svg
            {
                display: inline-block;
            }

            img
            {
                width: 150px;
                display: inline-block;
            }
        }
    }

    &_imgBg
    {
        width: 100% !important;
    }

    &_playIcon {
        width: 30px;
        position: absolute;
        top: 273px;
        right: 187px;
        cursor: pointer;
    }

    .arrow-right {
		color: white;
		font-size: 40px;
		position: absolute;
		top: 50%;
		right: -50px;
		border: 2px solid white;
		border-radius: 100%;
		padding: 10px;
		cursor: pointer;
	}

	.arrow-left {
		color: white;
		font-size: 40px;
		position: absolute;
		top: 50%;
		left: -50px;
		border: 2px solid white;
		border-radius: 100%;
		padding: 10px;
		cursor: pointer;
	}
}
.finishModal
{
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 990;
    width: 100%;
    height: 100vh;

    &_inner
    {
        width: 100%;
        height: 100vh;
        overflow: auto;
        text-align: center;
    }

    &_bg
    {
        width: 100%;
        height: 100%;
        margin: auto;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;
        opacity: 0.9;
    }

    &_nail1
    {
        position: absolute;
        top: 15px;
        left: 30px;
        width: 50px;
    }

    &_nail2
    {
        position: absolute;
        top: 15px;
        right: 30px;
        width: 50px;
    }

    &_nail3
    {
        position: absolute;
        bottom: 15px;
        left: 30px;
        width: 50px;
    }

    &_nail4
    {
        position: absolute;
        bottom: 15px;
        right: 30px;
        width: 50px;
    }

    &_btn
    {
        color: white;
        border-radius: 100px;
        background-color: #c69c6d;
        padding: 8px 25px;
        display: flex;
        align-items: center;
        width: 280px;
        justify-content: center;
        margin: 0 10px;

        img
        {
            margin-right: 10px;
        }
    }

    &_btn:hover
    {
        color: black;
    }

    &_title
    {
        display: flex;
        align-items: center;
        color: white;
        font-size: 24px;
        justify-content: center;

        img
        {
            margin-left: 10px;
            width: 18px;
        }
    }

    &_bulb
    {
        margin: -15px 0 15px;

        >div
        {
            width: 150px !important;
        }
    }

    &_spider
    {
        margin: 0;

        >div
        {
            width: 100px !important;
        }
    }

    &_wave
    {
        margin: -15px 0 15px;
        text-align: center;
    }

    &_wave img
    {
        display: inline-block;
        width: 40px;
    }

    &_cric
    {
        margin: -15px 0 15px;

        >div
        {
            width: 650px !important;
        }
    }

    &_logo
    {
        text-align: center;

        img
        {
            width: 90px;
            display: inline-block;
            margin: 30px 0;
        }
    }

    &_text
    {
        color: #cbcbcb;
        font-family: sans-serif;
        padding: 0 180px;
        margin: 20px 0;
        font-size: 16px;

        img
        {
            display: inline-block;
            width: 40px;
        }
    }

    &_congrats
    {
        display: flex;
        justify-content: space-between;
        padding: 0 100px 20px;
    }

    &_textYellow1 {
        color: #a98c55;
        font-family: sans-serif;
    }

    &_textYellow2 {
        color: #a98c55;
        font-family: sans-serif;
        text-align: left;
        padding-left: 215px;
        font-size: 18px;
    }

    &_textYellow3 {
        color: #a98c55;
        font-family: sans-serif;
		width: 100px;
		margin: 10px auto 0;
        cursor: pointer;
        margin-bottom: 50px;
		animation: animateIcon 1.5s ease-in-out infinite alternate;
    }

    &_radio
    {
        width: 80px;
        margin: auto;
        margin-top: 50px;
        cursor: pointer;
    }

    &_video
    {
        width: 400px;
        margin: auto;
        margin-bottom: 100px;
    }

    &_shipImg
    {
        width: 180px;
        margin: auto;
    }

    .youtube-container {
        overflow: hidden;
        width: 100%;
        /* Keep it the right aspect-ratio */
        aspect-ratio: 16/9;
        /* No clicking/hover effects */
        pointer-events: none;
    }
    
    .youtube-container iframe {
        /* Extend it beyond the viewport... */
        width: 300%;
        height: 100%;
        /* ...and bring it back again */
        margin-left: -100%;
    }

    &_beawareBox
    {
        height: 50px;
    }

    &_beaware
    {
        transform: scale(0);
        transition: 0.5s;
        color: white;
        font-family: 'raven-bold-inline';
        font-size: 35px;

        img 
        {
            width: 25px;
            display: inline-block;
        }
    }

    &_beaware.active
    {
        transform: scale(1);
        transition: 0.5s;
    }

    &_btns {
        display: flex;
        justify-content: center;
        margin: 25px 0;
        position: relative;
    }

    &_dropdown {
        position: absolute;
        background-color: black;
        color: white;
        font-family: sans-serif;
        padding: 10px 50px;
        border-radius: 5px;
        bottom: 55px;

        p {
            cursor: pointer;
            margin: 15px 0;
        }
    }
}
.brainModal4
{
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 990;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);

    &_inner
    {
        width: 750px;
        max-height: 100vh;
        overflow: auto;
        background-color: white;
        border-radius: 10px;
        position: relative;
        padding: 40px 20px 30px;
    }

    &_close
    {
        position: absolute;
        right: 10px;
        top: 5px;
        font-size: 22px;
        cursor: pointer;
        font-family: sans-serif;
    }

    &_salt
    {
        position: absolute;
        top: 180px;
        width: 160px;
        left: 50px;
    }

    &_bg
    {
        width: 100%;
    }

    &_text
    {
        position: absolute;
        top: 140px;
        width: 470px;
        left: 250px;
        font-weight: 500;
        font-family: 'Segoe UI';
    }
}
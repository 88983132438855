.terms
{
    padding: 20px;

    .title {
        text-align: center;
        font-size: 35px;
        margin-bottom: 35px;
    }

    h1 {
        margin-top: 50px;
    }

    h2,h3 {
        margin-top: 20px;
        font-family: sans-serif;
        font-weight: 600;
    }

    h2 {
        font-size: 28px;
    }

    h3 {
        font-size: 22px;
    }

    p, li {
        font-family: "Segoe UI";
        font-size: 18px;
        margin-bottom: 10px;
    }

    li,ul
    {
        list-style-type: disc;
    }
}
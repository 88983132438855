.aboutstar
{
    &_body
    {
        background-image: url('../../../../assets/images/star_chart/background.jpg');
        padding: 130px 50px 20px 20px;
        min-height: calc(100vh - 97px);
        margin-top: -80px;
    }

    &_strip
    {
        width: 100%;
    }

    &_circle
    {
        position: relative;

        img
        {
            margin: auto;
            width: 800px;
        }

        h1
        {
            position: absolute;
            top: 380px;
            left: 50%;
            transform: translateX(-50%);
            color: white;
            font-size: 50px;
        }
    }
}
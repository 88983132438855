.island__animation__card
{
    position: absolute;
    top: 23.4em;
    z-index: 1;
    width: 37em;
    text-align: justify;
    padding: 30px 20px;
}

.island__animation__card1
{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.island__animation__text
{
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
}

.wiggling-arrow
{
    display: flex;
    width: 200px;
    align-items: center;
}

.wiggling-div>.wiggling-svg
{
    font-size: 40px !important;
    transition: 0.3s all;
    animation: 5s bounce ease infinite;
    color: #a87833;
    margin-top: -10px !important;
    margin-left: 20px !important;
}

.static-svg
{
    font-size: 40px !important;
    color: #a87833;
    margin-top: -10px !important;
}

.island__animation__key
{
    font-size: small;
    text-align: right;
    position: relative;
    cursor: pointer;
}

.island__animation__key svg
{
    display: inline-block;
    margin-right: 5px;
}

.index_magnify_icon
{
    position: absolute;
    top: 10px;
    right: 30px;
    z-index: 3;
    cursor: pointer;
}

.div_island_icon
{
    position: absolute;
    top: 150px;
    right: 330px;
    z-index: 3;
}

.card1-key-island
{
    font-size: 12px;
    text-align: right;
    position: relative;
    cursor: pointer;
}

.index-bookmark
{
    -webkit-transform: rotate(-2deg);
    -moz-transform: rotate(-2deg);
    -ms-transform: rotate(-2deg);
    -o-transform: rotate(-2deg);
    transform: rotate(-2deg);
    position: absolute;
    z-index: 0;
    left: -110px;
    top: -50px;
}

@-webkit-keyframes bounce
{

    0%,
    20%,
    50%,
    80%,
    100%
    {
        -webkit-transform: translateX(0);
    }

    40%
    {
        -webkit-transform: translateX(-30px);
    }

    60%
    {
        -webkit-transform: translateX(-15px);
    }
}

@keyframes bounce
{

    0%,
    20%,
    50%,
    80%,
    100%
    {
        transform: translateX(0);
    }

    40%
    {
        transform: translateX(-30px);
    }

    60%
    {
        transform: translateX(-15px);
    }
}
.islandModal
{
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 990;
    width: 100%;
    height: 100vh;

    &_inner
    {
        width: 100%;
        height: 100vh;
        overflow: auto;
        text-align: center;
        background-color: black;
    }

    &_bg
    {
        width: 100%;
        height: 100%;
        margin: auto;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0.8;
    }

    &_chest
    {
        width: 200px;
        position: absolute;
        bottom: 50px;
        right: 50px;
        cursor: pointer;
    }

    &_magneticField
    {
        width: 130px;
        position: absolute;
        right: 320px;
        bottom: 260px;

        > div
        {
            transform: translate(0px) !important;
        }

        img
        {
            width: 100%;
        }
    }

    &_magnet
    {
        width: 0;
        position: absolute;
        right: 320px;
        bottom: 30px;

        img
        {
            width: 100%;
        }
    }

    &_magnet.active
    {
        width: 120px;
        transition: 1s;
    }

    &_magnet.success
    {
        width: 120px;
        right: 325px;
        bottom: 240px;
    }

    &_msg
    {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 120px;
        color: white;

        p
        {
            font-size: 22px;
        }
    }

    &_dna
    {
        position: absolute;
        top: 150px;
        right: 100px;
        width: 100px;
    }

    &_click
    {
        position: absolute;
        bottom: 95px;
        right: 77px;
        color: white;
        z-index: 1;
        cursor: pointer;
        margin: 0;
    }

    &_close
    {
        position: absolute;
        right: 30px;
        top: 20px;
        font-size: 30px;
        cursor: pointer;
    }
}
.storyModal
{
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 990;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);

    &_inner
    {
        width: 900px;
        max-height: 100vh;
        overflow: auto;
        background-color: white;
        border-radius: 10px;
        position: relative;
        padding: 20px;
    }

    &_close
    {
        position: absolute;
        right: 10px;
        top: 5px;
        font-size: 22px;
        cursor: pointer;
    }

    &_title
    {
        text-align: center;
        font-size: 24px;
        margin-top: 10px;
        font-family: segoe ui;
        font-weight: 600;
    }

    &_desc
    {
        color: #6a6a6a;
        font-family: segoe ui;
        margin-top: 10px;
        text-align: center;
    }

	&_btn
	{
		text-align: center;
		margin-top: 15px;
		
		a
		{
			font-family: 'raven-bold-inline';
			background: -webkit-linear-gradient(right,
			#e9dd9c,
			#a87833,
			#9e7d49,
			#c9a465,
			#ab915d) !important;
			cursor: pointer;
			color: white;
			border-radius: 50px;
			padding: 5px 30px;
			text-align: center;
		}
	}

	.youtube-container {
		width: 400px;
		margin: auto;
	}

	.youtube-container > div {
        overflow: hidden;
        width: 100%;
        /* Keep it the right aspect-ratio */
        aspect-ratio: 16/9;
        /* No clicking/hover effects */
        pointer-events: none;
    } 
    
    .youtube-container > div iframe {
        /* Extend it beyond the viewport... */
        width: 300%;
        height: 100%;
        /* ...and bring it back again */
        margin-left: -100%;
    }
}
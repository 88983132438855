.imgModalLarge
{
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 990;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);

    &_inner
    {
        position: relative;
    }

	&_box
	{
		max-width: 900px;
		max-height: 100vh;
		background-color: white;
		border-radius: 10px;
		overflow: auto;

		img
		{
			margin: auto;
		}

		p
		{
			text-align: center;
			margin: 10px 0;
		}
	}

    &_close
    {
        position: absolute;
        right: -35px;
        top: 5px;
        font-size: 30px;
        cursor: pointer;
        font-family: sans-serif;
		color: white;
    }

	&_link1
	{
		position: absolute;
		color: white;
		left: 195px;
		top: 205px;
		transform: rotate(-30deg);
		font-size: 14px;
		font-family: raven-bold-inline;
	}

	&_link2
	{
		position: absolute;
		color: white;
		left: 410px;
		top: 270px;
		transform: rotate(5deg);
		font-size: 14px;
		font-family: raven-bold-inline;
	}
}
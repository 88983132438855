@import url("https://fonts.googleapis.com/css2?family=Aleo:ital,wght@1,700&display=swap");

.brain-main-section
{
    background-image: url("../../assets/images/BG.png");
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    position: relative;
    overflow-x: hidden;
}

.brain-section
{
    background-image: url("../../assets/images/brain.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 50%;
    height: 68vh;
    position: absolute;
    left: 50%;
    opacity: 0.3;
    top: 62%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
}

.brain-container
{
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 33vh;
    margin: auto;
    max-width: 80%;
}

.brain-img
{
    width: 80%;
    padding: 6rem 0rem 2rem 0rem;
}

p.brain-desc
{
    font-size: 0.9rem;
    color: #cfcfcf;
    text-align: center;
    font-family: system-ui;
}

.find-text
{
    color: #fff;
    font-size: 1.5rem;
    font-style: italic;
    font-weight: 600;
    padding: 1rem 0rem;
    font-family: Aleo;
}

.discover-wrapper
{
    background: #404040 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #7070708f;
    width: max-content;
    margin: auto;
    border-radius: 26px;
    padding: 1rem 3rem 1rem 3rem;
    transition: 0.3s all;
    animation: 5s bounce ease infinite;
    cursor: pointer;
}

.btn-bounce{
    animation: 5s bouncebtn ease infinite !important;
}

.discover-wrapper:hover
{
    background: black;
}

.discover-wrapper span
{
    border: none;
    font-family: raven-bold-inline;

    background: -webkit-linear-gradient(right,
            #e9dd9c,
            #a87833,
            #9e7d49,
            #c9a465,
            #ab915d);
    -webkit-background-clip: text;
    -webkit-text-fill-color: rgba(255, 255, 255, 0);
    font-weight: bold;
    font-size: 1rem;
}

.custom-title
{
    color: #b6a75c;
    font-family: raven-bold-inline;
    font-size: 2.2vw;
    margin-bottom: 25px;
    text-align: center;
}

@keyframes bounce
{
    50%
    {
        transform: translateY(-1rem);
    }
}

.shadow
{
    width: 50%;
    margin: auto;
    height: 1.3rem;
    border-radius: 50%;
    background-color: #e2d393;
    opacity: 0.2;
    animation: 5s pulsate ease infinite;
}

@keyframes pulsate
{
    0%
    {
        opacity: 0;
    }

    50%
    {
        transform: scale(0.4);
        opacity: 0.2;
    }

    100%
    {
        opacity: 0;
    }
}

@keyframes bouncebtn
{

    20%,
    53%,
    80%,
    0%,
    100%
    {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    40%,
    43%
    {
        -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        -webkit-transform: translate3d(0, -30px, 0);
        transform: translate3d(0, -30px, 0);
    }

    70%
    {
        -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        -webkit-transform: translate3d(0, -15px, 0);
        transform: translate3d(0, -15px, 0);
    }

    90%
    {
        -webkit-transform: translate3d(0, -4px, 0);
        transform: translate3d(0, -4px, 0);
    }
}

@media screen and (max-width: 1200px)
{
    p.brain-desc
    {
        font-size: 0.7rem;
    }
}

@media screen and (max-width: 1024px)
{
    .discover-wrapper
    {
        padding: 1rem 2rem 1rem 2rem;
    }

    .custom-title
    {
        font-size: 6vw;
    }

    .brain-section
    {
        top: 57%;
    }

}

@media screen and (max-width: 900px)
{
    .custom-title
    {
        font-size: 3.4vw;
    }
}

@media screen and (max-width: 767px)
{
    .brain-section
    {
        top: 45%;

    }

    .brain-section
    {
        width: 415px;
        position: absolute;
        top: 20rem;
    }
}

@media screen and (max-width: 575px)
{
    .discover-wrapper
    {
        width: 70%;
        margin: auto;
        border-radius: 18px;
        padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    }

    .brain-img
    {
        width: 95%;
        height: 105px;
    }

    .brain-section
    {
        width: 415px;
        position: absolute;
        top: 20rem;
    }

    .brain-container
    {
        padding-top: 30vh;
    }

    .custom-title
    {
        font-size: 7vw !important;
    }
}

@media screen and (max-width: 800px)
{
    .custom-title
    {
        font-size: 3.4vw;
    }
}

.box:hover
{
    /* background-color: #040404a8; */
    transition: 0.3s;
    /* box-shadow: 0 20px 22px 0 rgb(255 255 255 / 20%); */
}

.discoverLink
{
    padding: 20px 10px;
}
.loader-main {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
}

.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #9e7d49;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }

// .loader {
//     width: 16px;
//     height: 16px;
//     position: relative;
//     left: -32px;
//     border-radius: 50%;
//     color: #c4c4c4;
//     background: currentColor;
//     box-shadow: 32px 0 , -32px 0 ,  64px 0;
//   }
  
//   .loader::after {
//     content: '';
//     position: absolute;
//     left: -32px;
//     top: 0;
//     width: 16px;
//     height: 16px;
//     border-radius: 10px;
//     background:#9e7d49;
//     animation: move 3s linear infinite alternate;
//   }
  
//   @keyframes move {
//     0% , 5%{
//       left: -32px;
//       width: 16px;
//     }
//     15% , 20%{
//       left: -32px;
//       width: 48px;
//     }
//     30% , 35%{
//       left: 0px;
//       width: 16px;
//     }
//     45% , 50%{
//       left: 0px;
//       width: 48px;
//     }
//     60% , 65%{
//       left: 32px;
//       width: 16px;
//     }
  
//     75% , 80% {
//       left: 32px;
//       width: 48px;
//     }
//     95%, 100% {
//       left: 64px;
//       width: 16px;
//     }
// }
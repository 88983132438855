.catalogue-animation
{
    position: absolute;
    right: 1em;
    top: 10em;
}

.catalogue-animation .see-more
{
    font-weight: 600;
    text-align: center;
    cursor: pointer;
    width: 100px;
    margin: auto;
    font-size: 14px;
}

.card1-div-catalogue{
    top: 285px!important;
    min-height: 180px;
    width: 544px!important;
    left: 4px!important;
    padding: 0 20px;
    z-index: 2;
    position: absolute;
    text-align: justify;
    background-color: #fff;
    border-bottom: 2px solid #d5d5d5;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-left: 2px solid #d5d5d5;
    border-right: 2px solid #d5d5d5;
}

.card1-text-catalogue {
    font-size: 14px;
    margin: 10px 0!important;
    text-align: center;
}

.card1-key-catalogue {
    cursor: pointer;
    font-size: 12px;
    position: relative;
    text-align: right;
}

.catalogue_index_icon
{
    position: absolute;
    top: -225px;
    right: 220px;
    z-index: 3;
}

.catalogue_magnify_icon
{
    position: absolute;
    right: 25px;
    top: -90px;
    z-index: 3;
}

.catalogue-close
{
    position: absolute;
    top: 0px;
    right: 0px;
    font-size: 35px;
    z-index: 2;
    cursor: pointer;
    font-family: sans-serif;
    color: #c69c6d;
}

.index-modal
{
    background-color: rgb(255, 255, 255) !important;
    height: 700px;
    text-align: left !important;
    overflow-y: scroll;
    z-index: 13 !important;
}

.catalogue-bookmark
{
    -webkit-transform: rotate(-2deg);
    -moz-transform: rotate(-2deg);
    -ms-transform: rotate(-2deg);
    -o-transform: rotate(-2deg);
    transform: rotate(-2deg);
    position: absolute;
    z-index: 0;
    left: -110px;
    top: -50px;
}

.catalogue-animation .index-modal, .catalogue-animation .index-modal-spinner
{
    right: 50px;
    top: -100px;
}
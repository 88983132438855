.imageModal2
{
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 990;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);

	&_inner
    {
        position: relative;
    }

    &_box
    {
        max-width: 1250px;
        max-height: 100vh;
        overflow: auto;
        background-color: white;
        border-radius: 10px;
        position: relative;
        padding: 10px;
		display: flex;
    	justify-content: center;
		
		> div {
			width: 600px;
			margin: 0 5px;

			img {
				width: 100%;
			}
		}

		p
		{
			text-align: center;
			margin: 10px 0;
			font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
			font-weight: 600;
			color: gray;
		}
    }

    &_close
    {
        position: absolute;
        right: -35px;
        top: 5px;
        font-size: 30px;
        cursor: pointer;
        font-family: sans-serif;
		color: white;
    }

	p
	{
		margin-top: 10px;
		font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
		font-weight: 600;
		color: gray;
	}
}
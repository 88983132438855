.quizModal3
{
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1065;
    width: 100%;
    height: 100vh;

    &_inner
    {
        width: 100%;
        height: 100vh;
        overflow: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &_bg
    {
        width: 100%;
        height: 100%;
        margin: auto;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;
        opacity: 0.9;
    }

    &_nail1
    {
        position: absolute;
        top: 15px;
        left: 30px;
        width: 50px;
    }

    &_nail2
    {
        position: absolute;
        top: 15px;
        right: 30px;
        width: 50px;
    }

    &_nail3
    {
        position: absolute;
        bottom: 15px;
        left: 30px;
        width: 50px;
    }

    &_nail4
    {
        position: absolute;
        bottom: 15px;
        right: 30px;
        width: 50px;
    }

    &_qNum
    {
        position: absolute;
        left: 90px;
        top: 18px;
        color: white;
        font-size: 30px;
        font-family: segeo ui;
    }

    &_minimize
    {
        position: absolute;
        right: 85px;
        top: 30px;
        color: white;
        font-size: 18px;
        cursor: pointer;
        line-height: 20px;
        display: flex;
    }

    &_content
    {
        display: flex;
        justify-content: center;

        p
        {
            font-size: 12px;
            color: #bbbbbb;
            font-family: sans-serif;
            width: 220px;
        }
    }

    &_title
    {
        display: flex;
        align-items: center;
        color: white;
        font-size: 24px;
        justify-content: center;
        margin-bottom: 50px;
    }

    &_box
    {
        padding: 10px 80px;
        font-family: sans-serif;
    }

    &_question
    {
        color: #c69c6d;
        font-weight: 600;
        font-size: 24px;
        margin-bottom: 10px;
    }

    &_select
    {
        color: white;
        font-size: 18px;
        margin-left: 30px;
    }

    &_answer
    {
        color: #bfbfbf;
        position: relative;
        padding-left: 35px;

        >span
        {
            border: 3px solid #c69c6d;
            padding: 2px;
            border-radius: 100px;
            width: 20px;
            height: 20px;
            position: absolute;
            left: 0;
            top: 3px;

            >span
            {
                width: 12px;
                height: 12px;
                background: transparent;
                position: absolute;
                border-radius: 15px;
                top: 1px;
                left: 1px;
                cursor: pointer;
            }
        }
    }

    .right-answer
    {
        color: green !important;
        font-weight: 600 !important;
    }

    .wrong-answer
    {
        color: red !important;
        font-weight: 600 !important;
    }

    &_active
    {
        background: white !important;
    }

    &_answers
    {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &_btn
    {
        text-align: center;
        margin-top: 50px;

        button
        {
            background: -webkit-linear-gradient(right,
            #e9dd9c,
            #a87833,
            hsl(37, 37%, 45%),
            #c9a465,
            #ab915d);
            border-radius: 50px;
            cursor: pointer;
            padding: 10px 35px;
            color: white;
            font-family: raven-bold-inline !important;
            font-size: 12px;
            margin: 0px 15px;
        }
    }
}

@media (max-width: 767px)
{
    .quizModal2
    {
        &_qNum span
        {
            display: none;
        }

        &_title
        {
            font-size: 14px;
            margin-bottom: 0px;
        }

        &_box
        {
            padding: 5px 25px;
        }

        &_question
        {
            font-size: 12px;
        }

        &_select
        {
            font-size: 12px;
            margin-left: 0px;
        }

        &_answer
        {
            margin-bottom: 8px;
            font-size: 12px;

            >span
            {
                top: 0px;
            }
        }
    }
}
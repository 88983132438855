.alert-enter
{
    opacity: 0;
    transform: scale(0.9);
}

.alert-enter-active
{
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}

.alert-exit
{
    opacity: 1;
}

.alert-exit-active
{
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
}

.index-animation
{
    position: absolute;
}

.font-segoe
{
    font-family: "Segoe UI";
}

.card1-div
{
    position: absolute;
    top: 18em;
    z-index: 1;
    width: 39em;
    text-align: justify;
    padding: 0px 10px 0px;
}

.cardx-div
{
    position: absolute;
    top: 18em;
    z-index: 1;
    width: 30em;
    text-align: justify;
    padding: 30px 20px;
}

.card3-indexdiv
{
    position: absolute;
    z-index: 3;
    top: 17.5em;
    left: 1.5em;
    width: 36em;
    text-align: justify;

    padding: 30px 20px;
}

.card3-000—16—2a
{
    position: absolute;
    z-index: 3;
    top: 18.5em;
    left: 0.5em;
    width: 38em;
    text-align: justify;
    padding: 30px 20px;
}

.card3-div
{
    position: absolute;
    top: 18em;
    z-index: 2;
    width: 39em;
    text-align: justify;
    padding: 10px 10px 15px;
}

.card3-div_2
{
    position: absolute;
    top: 18em;
    z-index: 1;
    width: 37em;
    text-align: justify;
    /* height: 18vh; */
    padding: 26px 15px;
}

.card1-div2
{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.card1-div2_2
{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.card1-box
{
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.card1-key
{
    /* font-size: small; */
    font-size: 10px;
    text-align: right;
    position: relative;
    cursor: pointer;
    /* display: flex; */
    /* align-items: center;
    justify-content: flex-end; */
}

.card1-key svg
{
    display: inline-block;
}

.card1-key svg
{
    margin-right: 5px;
}

.card1-key_2
{
    /* font-size: small; */
    font-size: 10px;
    text-align: right;
    position: relative;
    cursor: pointer;
    margin-bottom: 6px !important;
    margin-top: 2px;
    /* display: flex; */
    align-items: center;
}

.card2-key svg
{
    margin-right: 5px;
}

.card1-key_2 svg,
.card1-text_2 svg,
.card1-text_2 img
{
    display: inline-block;
}

.card1-text
{
    font-size: 12px;
    text-align: center;
    margin: 10px 0 !important;
}

.card1-text svg,
.card1-text img
{
    display: inline-block;
}

.card1-text_2
{
    font-size: 12px;
    text-align: center;
    margin-bottom: 0px !important;
}

.card1-text_3
{
    font-size: 12px;
    text-align: center;
    margin-bottom: 0px !important;
}

.card1-text_3 img
{
    display: inline-block;
}

.text-gold
{
    color: #9e7d49;
}

.font-bold
{
    font-weight: bold;
}

.blink
{
    animation: blinker 1s step-start infinite;
    font-size: 20px;
}

.blink-arrow-animation
{
    position: absolute;
    margin-top: -65vh;
    margin-left: 43vw;
    font-size: 30px;
}

.read-to-index-blink
{
    position: absolute;
    /* margin-top: -63vh; */
    margin-left: 60vw;
    font-size: 30px;
}

.card1-btn
{
    border: none;
    background: transparent;
}

.div_index_icon
{
    position: absolute;
    top: 20px;
    right: 40%;
    z-index: 13;
    display: flex;
    align-items: center;
}

.div_index_icon img, .div_index_icon svg
{
    cursor: pointer;
}

.return-to-animation3
{
    width: 200px;
    font-size: smaller;
    position: absolute;
    margin-top: 21vh;
    margin-left: 4vw;
    /* display: flex; */
    font-family: Special Elite, cursive;
}

.loading-clip4
{
    font-size: smaller;
    position: absolute;
    margin-top: 135px;
    font-family: Special Elite, cursive;
    z-index: 99;
    margin-left: 310px;
}

.loading-clip
{
    font-size: 16px;
    position: absolute;
    margin-top: 120px;
    margin-left: 120px;
    font-family: Special Elite, cursive;
    z-index: 99;
}

.loading-clip span
{
    display: flex;
    align-items: center;
}

.return-to-animation3arrow
{
    position: absolute;
    margin-top: 28vh;
    margin-left: 16.4vw;
    color: #9e7d49;
    transform: rotate(13deg);
}

.read-to-index
{
    display: flex;
    width: 400px;
    /* justify-content: space-between; */
    font-size: smaller;
    position: absolute;
    margin-top: 27.6vh;
    margin-left: 26vw;
    font-family: Special Elite, cursive;
}

.underline-3
{
    position: relative;
    display: inline-block;
    height: 2px;
    width: 130px;
    background: red;
    animation: blinking 0.2s infinite;
    -webkit-animation: blinking 0.3s infinite backwards alternate;
}

.underline-6
{
    position: relative;
    display: inline-block;
    height: 2px;
    margin-right: 82px;
    width: 20px;
    background: red;
    animation: blinking 0.2s infinite;
    -webkit-animation: blinking 0.3s infinite backwards alternate;
}

.underline-7
{
    position: relative;
    display: inline-block;
    height: 2px;
    margin-right: 60px;
    width: 14px;
    background: red;
    animation: blinking 0.2s infinite;
    -webkit-animation: blinking 0.3s infinite backwards alternate;
}

.underline-8
{
    position: relative;
    display: inline-block;
    height: 2px;
    margin-right: 38px;
    width: 15px;
    background: red;
    animation: blinking 0.2s infinite;
    -webkit-animation: blinking 0.3s infinite backwards alternate;
}

.magnify-icon
{
    position: absolute;
    right: 5%;
    top: 15%;
    font-size: 40px;
    z-index: 20;
    cursor: pointer;
}

.hovertext
{
    margin-top: -3vh;
    margin-left: -4vw;
    -webkit-transform: rotate(2deg);
    -moz-transform: rotate(2deg);
    -ms-transform: rotate(2deg);
    -o-transform: rotate(2deg);
    transform: rotate(2deg);
    position: absolute;
    z-index: 4;
    /* width: 30vw; */
    /* background-color: white; */
}

.hovertext1
{
    margin-top: -3vh;
    margin-left: 34vw;
    -webkit-transform: rotate(2deg);
    -moz-transform: rotate(2deg);
    -ms-transform: rotate(2deg);
    -o-transform: rotate(2deg);
    transform: rotate(4deg);
    position: absolute;
    /* z-index: 4 */
    /* width: 30vw; */
    /* background-color: white; */
}

.hovertextnext
{
    margin-top: 10vh;
    margin-left: 70vw;
    -webkit-transform: rotate(2deg);
    -moz-transform: rotate(2deg);
    -ms-transform: rotate(2deg);
    -o-transform: rotate(2deg);
    transform: rotate(4deg);
    position: absolute;
}

.hovertext3
{
    margin-top: -10vh;
    margin-left: -27vw;
    -webkit-transform: rotate(2deg);
    -moz-transform: rotate(2deg);
    -ms-transform: rotate(2deg);
    -o-transform: rotate(2deg);
    transform: rotate(4deg);
    position: absolute;
    z-index: 4;
}

@-webkit-keyframes blinking
{
    0%
    {
        opacity: 1;
    }

    100%
    {
        opacity: 0;
    }
}

@keyframes blinking
{
    0%
    {
        opacity: 1;
    }

    100%
    {
        opacity: 0;
    }
}

@media (max-width: 768px)
{
    .loading-clip4
    {
        margin-left: 10px;
        width: 200px;
    }

    .loading-clip {
        margin-left: 10px;
        width: 200px;
    }

    .card1-text
    {
        font-size: 10px;
    }

    .card1-text_2
    {
        font-size: 10px;
    }

    .card1-text_3
    {
        font-size: 10px;
    }
}
.quizModal1
{
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 990;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.85);

    &_inner
    {
        width: 100%;
        height: 100vh;
        overflow: auto;
        align-items: center;
        display: flex;
        justify-content: center;

        >div
        {
            position: relative;
        }
    }

    &_magnet
    {
        position: absolute;
        right: 140px;
        top: 175px;
        width: 60px;
    }

    &_title
    {
        font-family: raven-bold-inline;
        color: #c69c6d;
        text-align: center;
        margin: 10px 0 0;
        font-size: 20px;
    }

    &_box
    {
        position: relative;
        padding-top: 20px;
        width: 760px;
    }

    &_bg
    {
        width: 100%;
        height: 620px;
        margin: auto;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;
    }

    &_title2
    {
        text-align: center;
        font-family: raven-bold-inline;
        color: #c69c6d;
        margin-top: 40px;
    }

    &_connection
    {
        width: 300px;
        margin: auto;
        margin-top: -20px;
    }

    &_content
    {
        display: flex;
        justify-content: center;

        p
        {
            font-size: 12px;
            color: #bbbbbb;
            font-family: sans-serif;
            width: 220px;
        }
    }

    &_btns
    {
        display: flex;
        justify-content: center;
        margin-top: -80px;

        img
        {
            width: 50px;
            margin: 0 5px;
            cursor: pointer;
        }
    }

    &_bot
    {
        margin-top: 50px;
    }

    &_title3
    {
        font-family: raven-bold-inline;
        color: #c69c6d;
        margin-bottom: 5px;
    }

    &_desc
    {
        font-size: 12px;
        width: 300px;
        font-family: sans-serif;
        font-weight: 600;
        color: white;
    }

    &_bulb
    {
        >div
        {
            margin: -80px -90px 0 !important;
            width: 350px !important;
        }
    }

    &_neuron
    {
        bottom: -24px;
        position: absolute;
        right: 120px;
    }
}

@media(max-width: 787px)
{
    .quizModal1
    {
        &_inner
        {
            display: block;
        }

        &_box
        {
            width: 350px;
        }

        &_title
        {
            font-size: 16px;
        }

        &_title2
        {
            width: 130px;
            margin: auto;
            font-size: 12px;
            margin-top: 12px;
        }

        &_connection
        {
            width: 160px;
            margin: auto;
            margin-top: 0px;
        }

        &_bulb>div
        {
            margin: -20px 5px 0 !important;
            width: 80px !important;
        }

        &_content p
        {
            width: 100px;
        }

        &_btns
        {
            margin-top: -15px;

            img
            {
                width: 35px;
            }
        }

        &_bot
        {
            margin-top: 100px;
            margin-left: 5px;
        }

        &_title3
        {
            font-size: 11px;
        }

        &_desc
        {
            font-size: 10px;
            width: 200px;
        }

        &_neuron
        {
            bottom: 70px;
            position: absolute;
            right: auto;
            left: -160px;

            >div
            {
                width: 500px !important;
            }
        }
    }
}
.userModal
{
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
    width: 100%;
    height: 100vh;

    &_inner
    {
        width: 100%;
        height: 100vh;
        overflow: auto;
        text-align: center;
		padding-bottom: 20px;
    }

    &_bg
    {
        width: 100%;
        height: 100%;
        margin: auto;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;
        opacity: 0.9;
    }

    &_nail1
    {
        position: absolute;
        top: 15px;
        left: 30px;
        width: 50px;
    }

    &_nail2
    {
        position: absolute;
        top: 15px;
        right: 30px;
        width: 50px;
    }

    &_nail3
    {
        position: absolute;
        bottom: 15px;
        left: 30px;
        width: 50px;
    }

    &_nail4
    {
        position: absolute;
        bottom: 15px;
        right: 30px;
        width: 50px;
    }

    &_title
    {
        display: flex;
        align-items: center;
        color: white;
        font-size: 24px;
        justify-content: center;

        img
        {
            margin-left: 10px;
            width: 18px;
        }
    }

    &_logo
    {
        text-align: center;

        img
        {
            width: 90px;
            display: inline-block;
            margin: 30px 0;
        }
    }

    &_heading
    {
        color: #cbcbcb;
        font-family: sans-serif;
        padding: 0 180px;
        margin: 20px 0;
        font-size: 20px;
    }

    &_text
    {
        color: #cbcbcb;
        font-family: sans-serif;
        padding: 0 180px;
        margin: 20px 0;
        font-size: 16px;

        img
        {
            display: inline-block;
            width: 40px;
        }

        svg
        {
            display: inline-block;
            font-size: 30px;
        }

        span
        {
            color: #c69c6d;
            cursor: pointer;
        }
    }

	&_wait
    {
        color: #cbcbcb;
        font-family: Orbitron;
        padding: 0 180px;
        margin: 20px 0;
        font-size: 24px;
	}

	&_visited
	{
		color: gray !important;
	}

    &_videoList
    {
        background: rgba(0,0,0,0.6);
        border-radius: 10px;
        width: 400px;
        margin: auto;
        padding: 5px 0;
		margin-top: -100px;
		position: relative;
		z-index: 1;
		max-height: 150px;
    	overflow: auto;
        margin-bottom: 80px;

        img
        {
            display: inline-block;
            width: 40px;
        }

        p 
        {
            color: white;
            font-family: sans-serif;
            margin: 10px 0;
            font-size: 16px;
        }

        span
        {
            color: #c69c6d;
            cursor: pointer;
        }
    }

    &_ghostImg
    {
        text-align: center;

        img
        {
            width: 120px;
            display: inline-block;
        }
    }

    &_fireGif
    {
        text-align: center;

        img
        {
            width: 180px;
            display: inline-block;
        }
    }

    &_breadImg
    {
        text-align: center;
        margin: 35px 0;

        img
        {
            width: 220px;
            display: inline-block;
            cursor: pointer;
        }
    }

    &_robot
    {
        display: flex;
        justify-content: center;
        width: 100%;

        img
        {
            animation: leftAndRight 1.5s ease-in-out infinite alternate;
            width: 110px;
            cursor: pointer;
        }
    }

    &_options
    {
        display: flex;
        justify-content: center;

        img
        {
            height: 45px;
            cursor: pointer;
            margin: 0 15px;
        }
    }
    
    &_btns
    {
        display: flex;
        justify-content: center;
		align-items: center;
        padding: 0 100px 20px;
		margin-top: 25px;
    }

    &_btn, &_btnBegin
    {
        background: -webkit-linear-gradient(right,
        #e9dd9c,
        #a87833,
        hsl(37, 37%, 45%),
        #c9a465,
        #ab915d);
        text-align: center;
        border-radius: 50px;
        cursor: pointer;
        padding: 10px 35px;
        color: white;
        font-family: raven-bold-inline !important;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
		margin: 0px 15px;

        img
        {
            margin-right: 10px;
        }
    }

	.icon {
		color: #a87833;
	}

	&_btnBegin {
		margin-right: 100px;
	}

    &_btnBegin:hover, &_btn:hover
    {
        color: black;
    }

	&_beginImg
	{
		width: 100px;
		cursor: pointer;
	}

    &_close
    {
        position: absolute;
        right: 90px;
        top: 22px;
        font-size: 26px;
        cursor: pointer;
        color: white;
    }

    &_skullImg
    {
        cursor: pointer;
		animation: animateIcon 1.5s ease-in-out infinite alternate;
    }

    &_roasterImg
    {
        width: 60px !important;
        height: 60px !important;
    }

    &_mapImg
    {
        text-align: center;

        img
        {
            display: inline-block;
            width: 400px;
        }
    }

    &_plus
    {
        color: white;
        font-size: 50px;
        line-height: 35px;
        cursor: pointer;
        margin-left: 10px;
    }

	&_plusText
	{
		color: white;
		font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
		margin-left: 20px;
	}

    &_text2
    {
        margin-top: 60px;
    }

    &_video
    {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        margin: 30px 0;

        p
        {
            color: #cbcbcb;
            font-family: sans-serif;
            font-size: 16px;
        }

        img
        {
            width: 400px;
        }

        > div
        {
            width: 400px;
            margin-right: 10px;
        }
    }

    &_tempBtn
    {
        background: white;
        margin-bottom: 50px;
        padding: 10px 40px;
        border-radius: 20px;
        margin-top: 20px;
    }

    &_iframe
    {
        height: 600px;
        margin-bottom: 20px;
    }

    &_playIcon
    {
        width: 50px;
        border-radius: 5px;
        margin: auto;
        margin-top: 60px;
    }

    &_imgPlayIcon
    {
        width: 60px !important;
        margin: auto !important;
        margin-top: 80px !important;
        border-radius: 10px;
    }

    &_iframe
    {
        width: 95%;
        margin: auto;
        height: calc(100vh - 270px);
        margin-bottom: 15px;
    }

    &_iframe.hidden
    {
        display: none;
    }

    .youtube-container2 {
        overflow: hidden;
        width: 400px;
        /* Keep it the right aspect-ratio */
        aspect-ratio: 16/9;
    }
    
    .youtube-container2 iframe {
        /* Extend it beyond the viewport... */
        width: 100%;
        height: 100%;
    }
}

@keyframes leftAndRight
{
    0%
    {
        transform: translateX(-30px);
    }

    100%
    {
        transform: translateX(30px);
    }
}
.progressModal
{
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 990;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);

    &_inner
    {
        width: 600px;
        max-height: 100vh;
        overflow: auto;
        background-color: white;
        border-radius: 10px;
        position: relative;
        padding: 20px;
    }

    &_close
    {
        position: absolute;
        right: 15px;
        top: 15px;
        font-size: 22px;
        cursor: pointer;
    }

    &_title
    {
        font-size: 22px;
        font-family: 'raven-bold-inline';
    }

    &_img
    {
        width: 80%;
        margin: auto;
        margin-top: -50px;
        margin-bottom: -20px;
    }

    &_desc
    {
        text-align: center;
        margin: 20px 0;
        font-family: sans-serif;
        font-weight: 600;
        font-size: 20px;
    }

    &_desc2
    {
        font-family: sans-serif;
        font-size: 18px;
    }
}
.navbar-toggler-icon
{
    background-image: url("../../assets/images/toggle.png") !important;
}

.nav-bar
{
    display: flex;
    border-bottom: 0.1rem solid #eee1a3;
    justify-content: center;
}

.nav-bar-responsive
{
    display: none;
}

ul.nav-unorder-list
{
    display: flex;
    /* flex:auto; */
    align-items: center;
    padding: 0;
    list-style: none !important;
    justify-content: center;
}

.login-wrapper
{
    display: flex;
    flex: 25% 1;
    align-items: center;
    list-style: none;
    color: #cfcfcf;
    font-weight: 700;
    justify-content: space-evenly;
    padding-left: 4rem;
    margin-bottom: 20px;
}

.nav-bar ul
{
    margin-bottom: 3px;
}

.logo-one-wrapper
{
    height: 55px !important;
}

ul.login-wrapper.nav-item-text a
{
    color: #cfcfcf !important;
}

ul.nav-unorder-list li
{
    cursor: pointer;
    padding: 0.5rem 1.5rem 0.5rem 1.5rem;
}

li.menuitems .main-drop-button
{
    padding-top: 0;
}

.nav-login,
.nav-about
{
    cursor: pointer;
    font-family: ui-serif;
}

/* #header .header-bottom{margin:-10px 0 0;} */
#header .container
{
    max-width: 1720px;
}

#header.home-header
{
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    z-index: 1;
}

#header .logo
{
    width: 172px;
}

#header .contact-details
{
    font-size: 20px;
    line-height: 24px;
    color: #7aa77b;
}

#header .contact-details a
{
    color: #7aa77b;
}

#header .contact-details li
{
    margin: 0 0 0 26px;
}

#header .navbar
{
    font-size: 30px;
    line-height: 34px;
    font-family: "burnaby-stencil";
}

#header .navbar li
{
    margin: 0 0 0 26px;
}

#header .navbar li a,
#header .navbar li a:focus
{
    color: #fff;
}

#header .navbar li a.btn-zombie-green,
#header .navbar li a.btn-zombie-green:focus
{
    color: #0e0e10;
}

#header .navbar li a:after
{
    content: "";
    width: 0;
    height: 3px;
    position: absolute;
    left: 0;
    bottom: -4px;
    /* background: #28fa2e; */
}

#header .navbar li a:hover:after,
#header .navbar .active a:after
{
    width: 100%;
}

#header .navbar li a.nav-link
{
    padding: 0;
}

.custom-collapse
{
    background-color: rgb(54, 53, 53);
    border-color: #eee1a3;
    box-shadow: none;
    min-width: 345px;
}

.img-custom-menu-responsive
{
    height: 55px;
    cursor: pointer;
}

.img-custom-menu
{
    height: 60px;
}

.img-custom-menu-medium
{
    height: 110px;
}

.text-custom-menu
{
    font-family: ui-serif;
    font-size: 14px;
    color: rgb(155, 151, 151);
    /* align-self: center; */
    padding: 5px 10px;
}

.menu-navbar-responsive
{
    position: relative;
    right: 10px;
}

@media only screen and (max-width: 991px)
{
    .logo-two-wrapper
    {
        height: 90px !important;
    }

    #header .header-bottom
    {
        margin: 0;
    }

    #header .logo
    {
        width: 100px;
    }

    .navbar-toggler
    {
        padding: 0;
        border: none;
        right: 15px;
    }

    .navbar-light .navbar-toggler-icon
    {
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='rgba(40, 250, 46, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
    }

    .navbar-collapse
    {
        border-radius: 5px;
        background-color: rgb(94, 94, 94);
        opacity: 0.9;
        border-color: #e4d173;
        color: white;
        border: 1px solid #e4d173;
        margin: 10px 0 0 0;
    }

    /* .navbar-collapse {
  width: calc(100% - 0px);
  position: absolute;
  left: 0px;
  right: 0;
  top: calc(100% + 0px);
  border-radius: 0 0 3px 3px;
} */
    #header .navbar li
    {
        margin: 0;
    }

    #header .navbar li a
    {
        border-bottom: 1px solid #0e0e10;
        color: #0e0e10;
    }

    #header .navbar .navbar-nav li:last-child a
    {
        border-bottom: none;
    }

    #header .navbar li a.nav-link
    {
        padding: 7px 10px;
    }

    #header .navbar li a:after
    {
        display: none;
    }

    #header .navbar li.active a,
    #header .navbar li a:hover
    {
        background-color: #0e0e10;
    }

    #header .btn-enter-app
    {
        right: 60px;
    }

    #header .contact-details
    {
        font-size: 14px;
        line-height: 19px;
    }
}

@media screen and (max-width: 768px)
{
    .subwelcome-container
    {
        margin-top: 8vh;
    }

    .mob-signup-navbar
    {
        margin-top: -9vh !important;
    }

    .img-custom-menu-responsive
    {
        height: 85px;
        cursor: pointer;
    }

    .custom-collapse
    {
        min-width: 734px;
    }

    .navbar-collapse
    {
        border-radius: 5px;
        background-color: rgb(94, 94, 94);
        opacity: 0.9;
        border-color: #e4d173;
        color: white;
        border: 1px solid #e4d173;
        margin: 10px 0 0 0;
        position: absolute;
        top: 88px;
        z-index: 99;
    }

    .logo-two-wrapper
    {
        height: 75px !important;
    }

    .logo-one-wrapper
    {
        height: 40px !important;
    }

    .logo-three-wrapper
    {
        height: 60px !important;
    }
}

@media only screen and (max-width: 575px)
{
    #header .logo
    {
        width: 80px;
    }

    #header .btn-enter-app
    {
        font-size: 20px;
        line-height: 24px;
        padding: 5px 10px;
    }

    #header .contact-details li
    {
        margin: 0 10px;
    }

    .login-wrapper
    {
        display: none;
    }

    ul.nav-unorder-list
    {
        display: flex;
        /* flex: auto; */
        align-items: center;
        padding: 0;
        padding: 0rem;
        list-style: none !important;
        /*justify-content: space-evenly;*/
    }

    .custom-collapse
    {
        min-width: 372px;
    }

    ul.nav-unorder-list
    {
        display: flex;
        flex: 0 0 85%;
        margin-bottom: 0 !important;
        margin-left: 30px;
    }
}

@media screen and (max-width: 375px)
{
    .custom-collapse
    {
        min-width: 280px;
    }

    .navbar-collapse
    {
        border-radius: 5px;
        background-color: rgb(94, 94, 94);
        opacity: 0.9;
        border-color: #e4d173;
        color: white;
        border: 1px solid #e4d173;
        margin: 10px 0 0 0;
    }
}

@media screen and (max-width: 320px)
{
    .navbar-collapse
    {
        top: calc(100% + -0px) !important;
    }

    .custom-collapse
    {
        min-width: 280px !important;
    }
}

@media screen and (max-width: 900px)
{
    .nav-bar
    {
        display: none;
    }

    .nav-bar-responsive
    {
        display: inherit;
        padding-bottom: 8px;
        justify-content: center;
    }

    ul.nav-unorder-list li
    {
        cursor: pointer;
        padding: 0.5rem 1rem;
    }
}

@media screen and (max-width: 400px)
{
    ul.nav-unorder-list li
    {
        cursor: pointer;
        padding: 0.5rem 1.5rem 0.5rem 0.3rem;
    }

    .img-custom-menu-responsive
    {
        height: 45px;
    }

    .logo-one-wrapper
    {
        height: 40px !important;
    }

    .logo-two-wrapper
    {
        height: 50px !important;
    }

    .logo-three-wrapper
    {
        height: 50px !important;
    }
}

@media screen and (max-width: 590px)
{
    .mob-signup-navbar
    {
        margin-top: -9.4vh !important;
    }
}

@media (min-width: 320px) and (max-width: 570px)
{
    .mob-signup-navbar
    {
        margin-top: -7.4vh !important;
    }
}
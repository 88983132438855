.chapterModal
{
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 990;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);

    &_inner
    {
        width: 500px;
        max-height: 100vh;
        overflow: auto;
        background-color: white;
        border-radius: 10px;
        position: relative;
        padding: 20px;
    }

    &_close
    {
        position: absolute;
        right: 10px;
        top: 5px;
        font-size: 22px;
        cursor: pointer;
    }

    &_text
    {
        font-family: "Segoe UI";
        margin-top: 20px;
        border-top: 1px solid #e2e2e2;
        padding-top: 5px;
        color: gray;

        span
        {
            font-weight: 600;
            color: #a87833;
            cursor: pointer;
        }
    }

    &_title
    {
        font-size: 20px;
    }
}
.imageModal
{
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 990;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);

	&_inner
    {
        position: relative;
    }

	&_box
	{
		max-width: 750px;
		max-height: 100vh;
		background-color: white;
		border-radius: 10px;
		overflow: auto;

		img
		{
			margin: auto;
		}

		p
		{
			text-align: center;
			margin: 10px 0;
			font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
			font-weight: 600;
			color: gray;
		}
	}

    &_close
    {
        position: absolute;
        right: -35px;
        top: 5px;
        font-size: 30px;
        cursor: pointer;
        font-family: sans-serif;
		color: white;
    }

	.arrow-right {
		color: white;
		font-size: 40px;
		position: absolute;
		top: 50%;
		right: -50px;
		border: 2px solid white;
		border-radius: 100%;
		padding: 10px;
		cursor: pointer;
	}

	.arrow-left {
		color: white;
		font-size: 40px;
		position: absolute;
		top: 50%;
		left: -50px;
		border: 2px solid white;
		border-radius: 100%;
		padding: 10px;
		cursor: pointer;
	}

    &_plus {
        display: none;
        color: white;
        position: absolute;
        top: 50%;
        left: 50%;
        font-size: 50px;
        cursor: pointer;
        transform: translate(-50%, -50%);
    }

    &_box:hover ~ &_plus {
        display: block;
    }

    &_plus:hover {
        display: block;
    }
}
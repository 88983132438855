.billboardModal
{
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 990;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);

    &_inner
    {
        width: 900px;
        max-height: 100vh;
        overflow: auto;
        background-color: white;
        border-radius: 10px;
        position: relative;
        padding: 40px 0px 10px;

        img
        {
            margin: auto;
            width: 600px;
        }
    }

    &_close
    {
        position: absolute;
        right: 10px;
        top: 5px;
        font-size: 22px;
        cursor: pointer;
        font-family: sans-serif;
    }

	&_link1
	{
		position: absolute;
		color: white;
		left: 195px;
		top: 205px;
		transform: rotate(-30deg);
		font-size: 14px;
		font-family: raven-bold-inline;
	}

	&_link2
	{
		position: absolute;
		color: white;
		left: 410px;
		top: 270px;
		transform: rotate(5deg);
		font-size: 14px;
		font-family: raven-bold-inline;
	}
}
.model-profile-1 .modal-content {
  margin-top: -3.8rem;
  width: 100%;
  background-color: #fff;
  border: none;
  padding: 15px;
}
.edit-upload-mainmodal {
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 8px 0px #0000002e;
  padding: 10px;
}
.edit-upload-modal {
  width: 320px;
  height: 320px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.column1-modal-img-overlay {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 14rem;
  left: 0;
  text-align: center;
  right: 0;
  margin: 0 auto;
  max-width: 200px;
}
.column1-modal:hover .column1-modal-real-img {
  opacity: 0.3;
}
.column1-modal:hover .column1-modal-img-overlay {
  opacity: 1;
}
.column1-modal-img-span {
  cursor: pointer;
  background-color: #c9a465;
  color: white;
  font-size: 14px;
  padding: 12px 22px;
  border-radius: 10px;
}
.preview-star {
  margin-left: 12vw;
  cursor: pointer;
}
.preview-star-artifact {
  margin-left: 12vw;
  cursor: pointer;
}
.preview-star-video {
  margin-left: 21vw;
  cursor: pointer;
}
.model-island-1 .modal-header .btn-close {
  background: transparent url("https://s3.us-east-2.amazonaws.com/inter-routes.com/cros-1656617767066.png") center / 1em auto no-repeat !important;
  margin-top: 165px;
  font-size: x-large;
  right: 180px;
}
.letter-modal .modal-header .btn-close {
    margin-top: 70px !important;
    right: 10px !important;
}
.reality-modal .modal-header .btn-close {
  margin-top: -100px !important;
  right: 40px !important;
}
.modal-island-profile .btn-close {
    margin-top: 85px !important;
    right: 20px !important;
}
.divFinalImage-letter2 {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: -2rem;
}
.checkout-icon {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 10px 20px;
}
.column1-modal-real-img {
  width: 100%;
  object-fit: scale-down;
}

li.li-polaroids a p {
  margin-bottom: 0;
  margin-top: 15px;
}
.islandprofile-gotomap {
  background: -webkit-linear-gradient(
    right,
    #e9dd9c,
    #a87833,
    hsl(37, 37%, 45%),
    #c9a465,
    #ab915d
  );
  width: auto;
  border-radius: 50px;
  cursor: pointer;
  padding: 10px 15px;
  margin-left: auto;
  margin-right: auto;
  color: white;
  font-family: raven-bold-inline !important;
  font-size: 1vw;
  z-index: 9;
}
.islandprofile-gotomap img
{
  display: inline-block;
}
.islandprofile-gotomap a.text-link:hover {
  color: #fff;
}
.islandprofile-islandimage {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
  text-align: center;
  flex-direction: row;
  width: 100vw;
}
.islandprofile-main-container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  animation: fadein 2s;
  -moz-animation: fadein 2s; /* Firefox */
  -webkit-animation: fadein 2s; /* Safari and Chrome */
  -o-animation: fadein 2s;
}
.left-part,
.right-map {
  flex: 0 0 30%;
  display: flex;
  align-content: flex-end;
  flex-direction: column;
  margin-top: 25px;
}
.center-img {
  /* flex: 0 0 40%; */
  display: flex;
  justify-content: center;
  position: relative;
}
.center-img .spinning-cups > div
{
    width: 150px !important;
    height: 350px !important;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
}
.center-img .island-img
{
    width: 400px;
}
.islandprofile-bookrow {
  display: flex;
  justify-content: space-between;
}
.space-between {
  display: block;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.text-center {
  text-align: center;
}
.font-elite {
  font-family: Special Elite, cursive;
}
.column2-modal textarea.font-elite {
  padding: 10px 15px;
}
.space-around {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.text-island {
  color: #8a8a8a;
  font-family: Special Elite, cursive;
  font-size: 17px;
  text-transform: uppercase;
}

.islandprofile-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-left: -2rem;
}
.profile-book .book-img {
  position: absolute;
  top: 10px;
  right: -15px;
  width: auto;
  filter: drop-shadow(0px 0px 15px #00000070);
  border-radius: 50px 0px 0px 50px;
}
.profile-book {
  position: relative;
}
span.font-elite {
  font-size: 14px;
}
.menuitems-submenu {
  padding: 6px 12px;
  font-size: 15px;
  text-align: left;
}
.menuitems-submenu button.main-drop-button {
  font-size: 13px;
}
.islandprofile-username {
  color: #8a8a8a;
  padding-top: 0;
  font-family: raven-bold-inline;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  font-size: 20px;
}
.divIslandProfileLetterMain {
  width: 100%;
  margin-top: 160px;
  position: absolute;
  margin-left: -15px;
}
.divIslandProfileLetter2 {
  width: 100%;
  position: absolute;
  margin-top: 10px;
}
.username-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}
.popup-username{
  text-transform: capitalize;
}
.divIslandProfileLetter2 .popup-username, .divIslandProfileLetter2 .text-username-letter2 {
    font-size: 2rem !important;
}
.islandprofile-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.text-username {
  font-weight: bold;
  letter-spacing: 0.3px;
  margin-top: 3rem;
}
.text-username-letter2 {
  font-weight: bold;
  letter-spacing: 0.3px;
  margin-top: 1rem;
  text-transform: capitalize;
}
.key-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding-bottom: 18px;
  margin-top: 10px;
}
.margin-auto {
  margin-left: auto;
  margin-right: auto;
}

.letterprofile-body {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: start;
  font-family: Special Elite, cursive;
  padding-left: 50px !important;
  padding-right: 20px !important;
}
.text-updateDate {
  text-align: center;
  font-family: Special Elite, cursive;
  padding-left: 50px !important;
  padding-right: 20px !important;
}
.text-link {
  text-decoration: none;
  color: white;
}
.letter-modal {
  display: flex;
  justify-content: center;
}
.profileimage-wrapper {
  margin-bottom: 30px;
}
.profileisland-Tap {
  position: absolute;
  margin-right: auto;
  margin-top: -30rem;
  /* margin-left: 17rem; */
}
.profileisland-Tap-letter2 {
  position: absolute;
  margin-right: auto;
  margin-top: -30rem;
  margin-left: 5rem;
}
.profileisland-bagupload {
  height: 251px;
  width: 251px;
}
.profileisland-picture {
  /* margin-left: 18.16rem; */
  max-height: 220px;
  height: 220px;
  max-width: 330px;
  margin-top: -15.7rem;
  transform: rotate(-4.4deg);
}
.profileisland-picture-letter2 {
  margin-left: 4.36rem;
  max-height: 220px;
  height: 220px;
  max-width: 330px;
  margin-top: -15.7rem;
  transform: rotate(-4.4deg);
}
.container-main {
  display: flex;
  flex-direction: row;
}
.column1-modal {
  display: flex;
  flex-direction: column;
  flex: 0 0 45%;
  width: 100%;
  margin-bottom: 15px;
}
.column1-modal img {
  object-fit: scale-down;
}
.column1-modal input {
  height: 50px;
  padding: 0 10px;
}
.column1-modal-img {
  display: flex;
  align-items: center;
  justify-content: center;
}
.column2-modal {
  display: flex;
  flex-direction: column;
  flex: 0 0 50%;
  width: 100%;
}
.left-wrapper {
  flex: 0 0 45%;
}
.right-wrapper {
  flex: 0 0 50%;
}
.logo-row {
  width: 200%;
  display: flex;
  margin-left: -220px;
  flex-direction: row;
  justify-content: space-evenly;
}
.ImageMediaRow > ul > li {
  list-style: none;
}
.ImageMediaRow > ul {
  display: flex;
  flex-wrap: wrap;
  padding: 40px 0 0 0;
  max-height: 900px;
  overflow-y: auto;
  scrollbar-gutter: stable;
}
.ImageMediaRow > ul::-webkit-scrollbar {
  width: 8px;
}
.ImageMediaRow > ul::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.ImageMediaRow > ul::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 1rem;
}
.ImageMediaRow > ul::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.letter2-sticky ul li a.stick-notesl2 {
  text-decoration: none;
  color: #000;
  background: white;
  display: block;
  height: 8em;
  width: 8em;
  padding: 7px;
  box-shadow: 5px 5px 7px rgb(33 33 33 / 70%);
  -webkit-box-shadow: 5px 5px 7px rgb(33 33 33 / 70%);
  -moz-box-shadow: 5px 5px 7px rgb(33 33 33 / 70%);
  /* -webkit-transform: rotate(-2deg); */
  -webkit-transition: -webkit-transform 0.15s linear;
  /* -moz-transform: rotate(-2deg); */
}

.seemore-button {
  cursor: pointer;
  text-align: center;
  color: white;
  padding: 6px;
  border-radius: 15px;
  font-weight: bold;
  font-size: 0.8rem;
  font-family: Special Elite, cursive;
  letter-spacing: 1px;
  width: 70%;
  background: #000;
}

.letter2-sticky ul li:nth-child(even) a {
  transform: rotate(-4deg);
  position: relative;
  top: 5px;
  background: #cfc;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.letter2-sticky ul li:nth-child(3n) a {
  transform: rotate(-3deg);
  position: relative;
  top: -5px;
  background: #ccf;
}
.letter2-sticky ul li:nth-child(5n) a {
  transform: rotate(5deg);
  position: relative;
  top: 0px;
}

ul.polaroids a:after {
  content: attr(title);
}
/* By default, we tilt all our images -2 degrees */
ul.polaroids a {
  display: inline;
  float: left;
  margin: 0 0 27px 10px;
  width: auto;
  padding: 15px 15px 20px;
  background: #efefef;
  text-align: center;
  font-family: Special Elite, cursive;
  text-decoration: none;
  color: #333;
  font-size: 18px;
  box-shadow: 5px 5px 7px rgb(33 33 33 / 70%);
  -webkit-box-shadow: 0 3px 6px rgb(0 0 0 / 25%);
  -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);

  /* -webkit-transform: rotate(-2deg);
  -moz-transform: rotate(-2deg);
  -ms-transform: rotate(-2deg);
  -o-transform: rotate(-2deg);
  transform: rotate(-2deg);

  position: relative;
  z-index: 1; */
}

/* Rotate all even images 2 degrees */
/* ul.polaroids li:nth-child(even) a {
  -webkit-box-shadow: 0 3px 6px rgb(0 0 0 / 25%);
  -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
  -webkit-transform: rotate(-2deg);
  -webkit-transition: -webkit-transform 0.15s linear;
} */

/* Don't rotate every third image, but offset its position */
/* ul.polaroids li:nth-child(3n) a {
  -webkit-box-shadow: 0 3px 6px rgb(0 0 0 / 25%);
  -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
  -webkit-transform: rotate(-2deg);
  -webkit-transition: -webkit-transform 0.15s linear;
  position: relative;
  top: -5px;
} */

/* Rotate every fifth image by 5 degrees and offset it */
/* ul.polaroids li:nth-child(5n) a {
  -webkit-box-shadow: 0 3px 6px rgb(0 0 0 / 25%);
  -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
  -webkit-transform: rotate(-2deg);
  -webkit-transition: -webkit-transform 0.15s linear;
  position: relative;
  right: 5px;
} */

/* Keep default rotate for every eighth, but offset it */
/* ul.polaroids li:nth-child(8n) a {
  -webkit-box-shadow: 0 3px 6px rgb(0 0 0 / 25%);
  -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
  -webkit-transform: rotate(-2deg);
  -webkit-transition: -webkit-transform 0.15s linear;
  position: relative;
  top: 8px;
  right: 5px;
} */

/* Keep default rotate for every eleventh, but offset it */
/* ul.polaroids li:nth-child(11n) a {
  -webkit-box-shadow: 0 3px 6px rgb(0 0 0 / 25%);
  -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
  -webkit-transform: rotate(-2deg);
  -webkit-transition: -webkit-transform 0.15s linear;
  position: relative;
  top: 3px;
  left: -5px;
} */

ul.polaroids a:hover {
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  position: relative;
  z-index: 5;
}
ul.polaroids a:hover .settings-ul {
  width: 440px !important;
  font-family: Special Elite, cursive !important;
  font-size: 10px !important;
}
ul.polaroids a:hover .settings-ul .small {
  font-size: 10px !important;
  font-family: Special Elite, cursive !important;
}

ul.polaroids a {
  -webkit-transition: -webkit-transform 0.15s linear;
  -webkit-box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.25);
}

ul.polaroids img {
  display: block;
  width: 100%;
  height: 100%;
  max-width: 100%;
  pointer-events: auto;

  object-fit: scale-down;
}
.img-poloroid {
  width: 210px;
  pointer-events: auto;

  height: 210px;
  overflow: hidden;
  background: #fff;
  border: 1px solid #dedede;
  padding: 5px;
}
.li-polaroids .middle {
  transition: 0.5s ease;
  opacity: 0;
  width: 80px;
  position: absolute;
  right: 0;
  bottom: 80px;
  text-align: center;
  cursor: pointer;
  left: 0;
  margin: 0 auto;
}

.li-polaroids:hover .image {
  opacity: 0.3;
}
.middle-video {
  transition: 0.5s ease;
  opacity: 0;
  width: 80px;
  margin-top: -14rem !important;
  margin-left: 10em;
  font-family: Special Elite, cursive;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  cursor: pointer;
}
.li-videos:hover .middle-video {
  opacity: 1;
}
.middle-misc {
  transition: 0.5s ease;
  opacity: 0;
  width: 80px;
  margin-top: -6em;
  margin-left: 5em;
  font-family: Special Elite, cursive;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  cursor: pointer;
}
.li-misc:hover .middle-misc {
  opacity: 1;
}

.li-polaroids:hover .middle {
  opacity: 1;
}

.polaroids .text {
  background-color: #000000;
  border-radius: 5px;
  font-weight: bold;
  color: white;
  font-size: 12px;
  padding: 6px 2px;
}

ul.singlepolaroids a:after {
  content: attr(title);
}
/* By default, we tilt all our images -2 degrees */
ul.singlepolaroids a {
  -webkit-transform: rotate(-2deg);
  -moz-transform: rotate(-2deg);
  background: rgb(255, 255, 255);
  display: inline;
  float: left;
  margin: 0;
  width: auto;
  padding: 10px 10px 35px;
  text-align: center;
  font-family: Special Elite, cursive;
  text-decoration: none;
  background: #efefef;
  color: #333;
  font-size: 18px;
  box-shadow: 5px 5px 7px rgb(33 33 33 / 70%);
  -webkit-box-shadow: 0 3px 6px rgb(0 0 0 / 25%);
  -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
  -webkit-transform: rotate(-2deg);
  -webkit-transition: -webkit-transform 0.15s linear;
  -moz-transform: rotate(-2deg);
}

ul.singlepolaroids a img {
  display: block;
  width: 100%;
  height: 100%;
  max-width: 100%;
  object-fit: scale-down;
}

/* Rotate all even images 2 degrees */
ul.singlepolaroids li:nth-child(even) a {
  -webkit-transform: rotate(2deg);
  -moz-transform: rotate(2deg);
}

/* Don't rotate every third image, but offset its position */
ul.singlepolaroids li:nth-child(3n) a {
  -webkit-transform: none;
  -moz-transform: none;
  position: relative;
  top: -5px;
}

/* Rotate every fifth image by 5 degrees and offset it */
ul.singlepolaroids li:nth-child(5n) a {
  -webkit-transform: rotate(5deg);
  -moz-transform: rotate(5deg);
  position: relative;
  right: 5px;
}

/* Keep default rotate for every eighth, but offset it */
ul.singlepolaroids li:nth-child(8n) a {
  position: relative;
  top: 8px;
  right: 5px;
}

/* Keep default rotate for every eleventh, but offset it */
ul.singlepolaroids li:nth-child(11n) a {
  position: relative;
  top: 3px;
  left: -5px;
}

ul.singlepolaroids a:hover {
  /* -webkit-transform: scale(1.25);
  -moz-transform: scale(1.25);
  -webkit-box-shadow: 0 3px 6px rgb(0 0 0 / 50%);
  -moz-box-shadow: 0 3px 6px rgba(0,0,0,.5); */
  position: relative;
  z-index: 5;
}
ul.singlepolaroids a {
  -webkit-transition: -webkit-transform 0.15s linear;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
}

/* On hover, darken the shadows a bit */
ul.singlepolaroids a {
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
}
ul.singlepolaroids img {
  display: block;
  width: 100%;
  margin-bottom: 0px;
  object-fit: contain;
}
.li-singlepolaroids .middle {
  transition: 0.5s ease;
  opacity: 0;
  width: 80px;
  position: absolute;
  right: 15px;
  margin: auto;
  bottom: 80px;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  cursor: pointer;
}

.li-polaroids:hover .image {
  opacity: 0.3;
}

.li-polaroids:hover .middle {
  opacity: 1;
}

.polaroids .text {
  background-color: #000000;
  border-radius: 5px;
  font-weight: bold;
  color: white;
  font-size: 12px;
  padding: 6px 2px;
}

.middle-video .text {
  color: black;
  font-size: 12px;
  padding: 8px;
  border-radius: 5px;
  width: 120px;
  background-color: rgb(255, 255, 255);
}
.middle-misc .text {
  color: black;
  font-size: 12px;
  padding: 8px;
  border-radius: 5px;
  width: 120px;
  background-color: rgb(255, 255, 255);
}
.img-poloroid-sticky {
  background: #fff;
  border: 1px solid #dedede;
  padding: 2px 5px 15px 5px;
  /* width: 164px; */
  height: 150px;
  overflow: hidden;
}
.captionModalclass {
  position: fixed;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 50px;
  width: 100%;
  overflow-y: scroll;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: 720px;
  z-index: 99;
}

/* Modal Content */
.captionModalclass-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  max-width: 800px;
  max-height: 640px;
  overflow: hidden;
  overflow-y: scroll;
  scrollbar-gutter: stable;
}

.captionModalclass-content::-webkit-scrollbar {
  width: 8px; /* width of the entire scrollbar */
}

.captionModalclass-content::-webkit-scrollbar-track {
  background: rgba(255, 235, 197, 0); /* color of the tracking area */
}

.captionModalclass-content::-webkit-scrollbar-thumb {
  background-color: rgb(99, 99, 99); /* color of the scroll thumb */
  border-radius: 10px; /* roundness of the scroll thumb */
}
.left_btn {
  text-align: left;
}

.right_btn {
  text-align: right;
}
/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.savebutton {
  background: -webkit-linear-gradient(
    right,
    #e9dd9c,
    #a87833,
    #9e7d49,
    #c9a465,
    #ab915d
  );
  width: max-content;
  border-radius: 50px;
  border: none;
  margin-top: 15px;
  margin-right: 15px;
  color: white;
  font-weight: bold;
  padding: 10px 30px;
  cursor: pointer;
}
.discardbutton {
  border: 1px solid #9e7d49;
  background: black;
  width: max-content;
  border-radius: 50px;
  border: none;
  font-weight: bold;
  margin-top: 15px;
  margin-right: 0;
  color: white;
  padding: 10px 30px;
  cursor: pointer;
}
.delete_btn {
  text-align: left;
}

.save_btn {
  text-align: right;
}
.PaginateComponent-row {
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  margin-right: 20px;
  flex-wrap: wrap;
}
.PaginateComponent-right {
  flex: 0 0 80%;
}
.Instructions-left {
  flex: 0 0 20%;
  margin-top: 6rem;
}
.img-poloroid {
  width: 210px;
  height: 210px;
  overflow: hidden;
}
.Instructions-left p {
  font-size: 12px;
}
.uploadmore-button {
  cursor: pointer;
  text-align: center;
  background-color: black;
  padding: 10px;
  border-radius: 15px;
  font-weight: bold;
  font-size: 14px;
  font-family: Special Elite, cursive;
  letter-spacing: 1px;
  width: 100%;
}

.nav-link {
  color: black !important;
}
.video-react-video {
  width: 200px;
}
.div-videoplayers {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.video-react {
  height: 50% !important;
}
.video-player-div {
  width: 375px;
  height: auto;
  margin-right: 20px;
  margin-left: 20px;
}
.pg-viewer-wrapper .document-container {
  padding: 9px !important;
  width: auto !important;
  font-size: 5px !important;
}
/* Pagination CSS */
.eOnEVr,
.cHAmji,
.knuHzK {
  justify-content: center !important;
  margin-left: -95px;
}
.eOnEVr > li,
.knuHzK > li,
.cHAmji > li {
  margin: 20px;
}
.eOnEVr > li.active > a,
.knuHzK > li.active > a,
.cHAmji > li.active > a {
  padding-top: 6px;
}
.swal2-container.swal2-center > .swal2-popup {
  font-family: Special Elite, cursive !important;
}

.tab_centent {
  display: grid;
  grid-template-columns: 5fr 5fr 5fr 5fr 5fr;
  gap: 15px;
  padding: 0 30px;
}
.model-island-1 .modal-header {
  position: relative;
  z-index: 15;
}

.modal-body {
  width: 100%;
  margin-left: 0px !important;
  text-align: center;
  padding: 0 !important;
}
p.image_des {
  font-size: 13px;
  margin-top: 20px;
  display: inline-block;
  font-weight: 300;
}

.middle-tweet {
  transition: 0.5s ease;
  opacity: 1;
  width: 80px;
  margin-top: 2em;
  margin-left: 4em;
  font-family: Special Elite, cursive;
  transform: translate(-50%, 50%);
  -ms-transform: translate(-50%, 50%);
  text-align: center;
  cursor: pointer;
}

.divFinalImage {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.polaroid-card {
  margin: 0 10px;
  padding: 0;
}

li.li-polaroids {
  display: inline-grid;
  position: relative;
  left: 0;
  right: 0;
  margin: 0 auto;
}
ul.singlepolaroids li.li-polaroids {
  width: 100%;
}
ul.singlepolaroids {
  padding: 0;
  margin: 0;
}
.tab_centent::-webkit-scrollbar {
  width: 8px; /* width of the entire scrollbar */
}

.tab_centent::-webkit-scrollbar-track {
  background: rgba(255, 235, 197, 0); /* color of the tracking area */
}

.tab_centent::-webkit-scrollbar-thumb {
  background-color: rgb(99, 99, 99); /* color of the scroll thumb */
  border-radius: 10px; /* roundness of the scroll thumb */
}
.preview-star-tweet {
  margin-left: 0;
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 0;
}
.preview-star-tweet svg {
  width: 16px !important;
  height: 16px;
}
span.preview-star {
  margin-left: 0;
  cursor: pointer;
  position: absolute;
  right: 3px;
  top: -3px;
  z-index: 999;
}

span.preview-star svg {
  width: 16px;
  height: 16px;
}
li.li-tweet p {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  font-size: 14px;
  margin: 0;
}

.polaroid-card li h2.font-elite {
  font-size: 16px;
  font-weight: bold;
  word-break: break-word;
  padding: 0 20px 0 10px;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.polaroid-card li p.font-elite {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-size: 14px;
}

li.li-tweet h2 {
  font-size: 16px;
  font-weight: bold;
  word-break: break-word;
  padding: 0 20px 0 10px;
}
.divIslandProfileLetter2 .navbar {
  display: block;
}
.pop_case_btns {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  width: 100%;
}

@media only screen and (max-width: 1199px) {
  .tab_centent {
    display: grid;
    grid-template-columns: 5fr 5fr 5fr;
    gap: 15px;
    padding: 20px;
  }
  .nav-tabs .nav-link {
    font-size: 12px;
  }
  .tab_centent {
    max-height: 500px;
    overflow-y: auto;
    scrollbar-gutter: stable;
  }
  .ImageMediaRow > ul > li {
    list-style: none;
    margin-bottom: 10px;
  }
  .uploadmore-button {
    font-size: 10px;
  }
  .ImageMediaRow > ul {
    max-height: 700px;
  }
}

@media only screen and (max-width: 991px) {
  .letter2-sticky {
    padding: 0 20px;
  }
  .captionModalclass-content {
    margin: 0 0.8rem;
  }
  .Instructions-left {
    flex: 0 0 100%;
    margin-top: 3rem;
  }
  .PaginateComponent-right {
    flex: 0 0 100%;
  }

  .tab_centent {
    display: grid;
    grid-template-columns: 5fr 5fr;
    grid-gap: 15px;
    gap: 15px;
    padding: 20px;
  }
  .nav-tabs .nav-link {
    font-size: 14px;
    padding: 3px 4px;
  }
  .nav.nav-tabs {
    margin: 0px 15px 0 10px;
    text-align: center;
  }
  .tab_centent {
    max-height: 400px;
    overflow-y: auto;
    scrollbar-gutter: stable;
  }

  .container-main {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .left-wrapper {
    flex: 0 0 100%;
  }
  .right-wrapper {
    flex: 0 0 100%;
  }
 
  .ImageMediaRow > ul {
    max-height: 400px;
    overflow-y: auto;
    scrollbar-gutter: stable;
  }
}
@media only screen and (max-width: 768px) {
  .pop_case_btns {
    display: block;
    margin-top: 15px;
  }
  .islandprofile-islandimage {
    display: block;
  }
  .left_btn button {
    margin: 0 0 10px 0 !important;
  }
  .left_btn,
  .right_btn {
    text-align: center;
  }
  .column1-modal-real-img {
    width: 100%;
  }
  .ImageMediaRow > ul {
    max-height: 300px;
    overflow-y: auto;
    scrollbar-gutter: stable;
  }
  .text-link {
    font-size: 14px;
  }
  .center-island-icons li.menuitems-submenu a {
    font-size: 13px;
  }

  .center-island-icons li.menuitems-submenu button {
    font-size: 13px;
  }
  .center-img img {
    max-width: 100% !important;
  }
  .book-img {
    position: absolute;
    top: 0;
    right: 0;
    width: 120px;
  }
  .retrieve-button button {
    margin-left: 0;
  }
  .uploadcaseprofile-button {
    margin-left: 0;
  }
  .bottom-map-mob {
    box-shadow: none;
    margin-left: 0px;
    margin-right: 10px;
  }
  .container-main {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: center;
  }
  .left-wrapper {
    flex: 0 0 100%;
  }
  .right-wrapper {
    flex: 0 0 100%;
  }
  .Instructions-left {
    flex: 0 0 100%;
  }
  .PaginateComponent-row {
    flex-wrap: wrap;
  }
  .PaginateComponent-right {
    flex: 0 0 100%;
  }
  .tab_centent {
    overflow-y: scroll;
    height: 300px;
  }
  .tab_centent {
    display: grid;
    grid-template-columns: 5fr;
    gap: 15px;
    padding: 20px;
  }
  .letter-mob {
    overflow-y: hidden;
    /* height: 500px!important; */
  }
  .letter3-mob {
    display: none !important;
  }
  .nav.nav-tabs {
    margin: 0px 15px 0 10px;
    text-align: center;
  }
  p.image_des {
    font-size: 14px;
    margin-top: 40px;
    display: inline-block;
  }
 
  ul.polaroids a {
    margin: 0 0 0px 0px;
  }
}
@media only screen and (max-width: 530px) {
  .tab_centent {
    display: grid;
    grid-template-columns: 5fr;
    gap: 15px;
    padding: 20px;
  }
  .captionModalclass-content img {
    max-width: 100%;
    max-height: 200px;
  }
  .ImageMediaRow > ul {
    max-height: 380px;
    overflow-y: auto;
    scrollbar-gutter: stable;
  }
  .wiggle-briefcase-condition2 {
    position: absolute;
    margin-top: 90px;
    margin-left: 0;
    transition: 0.3s all;
    animation: 5s bounce ease infinite;
  }
  .tab_centent {
    overflow-y: auto;
    max-height: 380px;
  }

  ul.polaroids a {
    margin: 0 0 0px 0px;
  }
  .eOnEVr > li,
  .knuHzK > li,
  .cHAmji > li {
    margin: 0;
  }
}

.wiggling-div-island
{
    justify-content: flex-end !important;
    margin-top: 10px;
}
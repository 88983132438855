.splitModal
{
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 990;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);

    &_inner
    {
        width: 1200px;
        max-height: 100vh;
        overflow: auto;
        background-color: white;
        border-radius: 10px;
        position: relative;
        padding: 40px 0px 10px;
		display: flex;
		justify-content: space-between;

        img
        {
            margin: auto;
            width: 600px;
        }
    }

    &_close
    {
        position: absolute;
        right: 10px;
        top: 5px;
        font-size: 22px;
        cursor: pointer;
        font-family: sans-serif;
    }

	.youtube-container
	{
		width: 600px;
	}

	&_img
	{
		margin: auto;
		width: 100%;
	}	

	.youtube-container > div {
        overflow: hidden;
        width: 100%;
        /* Keep it the right aspect-ratio */
        aspect-ratio: 16/9;
        /* No clicking/hover effects */
        pointer-events: none;
    } 
    
    .youtube-container > div iframe {
        /* Extend it beyond the viewport... */
        width: 300%;
        height: 100%;
        /* ...and bring it back again */
        margin-left: -100%;
    }
}
.profile-suitcase-wrapper
{
    text-align: left;
    height: 200px;
    transition: 0.3s all;
    animation: 5s bounce-1 ease infinite;
    cursor: pointer;
    border: 1px dotted red;
    display: flex;
}

.profile-suitcase-wrapper img
{
    width: 100px !important;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 20px;
}

.font-elite
{
    font-family: Special Elite, cursive;
}

.artifacts-modal .accordion-body .usersArtifact
{
    display: grid;
    grid-template-columns: 4fr 4fr 4fr;
    gap: 25px;

}

.text-wave
{
    font-size: 8px;
    padding: 0;
    margin: 0;
    /* border: 1px solid red; */

}

.go_back_arrow
{
    cursor: pointer;
}

.go_back_arrow>button
{
    background-color: transparent;
    border: none;
    color: white;
    width: 116%;
    /* border: 1px solid red; */
    /* display: flex; */
    /* margin: -1px;  */
    /* margin-right: 117px; */
    margin-left: -17px;
}

.artifacts-modal
{
    background-color: black !important;
    color: rgb(240, 239, 239);
    font-family: Special Elite, cursive;
    /* border: 1px solid red; */
}

.artifacts-modal-close
{
    position: absolute;
    right: 12px;
    top: 5px;
    font-size: 20px;
    cursor: pointer;
    color: white;
}

.model-artifacts-1 .modal-dialog .modal-content
{
    background: #000;
    padding: 20px;
    border-radius: 8px;
}

.model-artifacts-1 .accordion-button:focus
{
    box-shadow: none;
}

.model-artifacts-1 .accordion-body
{
    padding: 1rem 1.25rem;
    color: #000;
}

.model-artifacts-1 .accordion-body button
{
    font-weight: 600;
}

.model-artifacts-1 .modal-dialog .modal-content button.btn-close
{
    background: transparent url("data:image/svg+xml, %3csvg xmlns='http://www.w3.org / 2000/svg' viewBox='0 0 16 16' fill='%23fc0'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
    margin-top: -2rem;
    font-size: xx-large;
    right: 10px;
}

.model-receipt-1 .modal-dialog .modal-content button.btn-close
{
    background: transparent url("data:image/svg+xml, %3csvg xmlns='http://www.w3.org / 2000/svg' viewBox='0 0 16 16' fill='%23fc0'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
    margin-top: -2rem;
    font-size: xx-large;
    right: 10px;
}

.model-artifacts-1 h2
{
    color: #fff;
    font-size: 2rem;
}

.imagepreview-carousel
{
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.imagepreview-carousel .alice-carousel
{
    width: 700px;
}

.crossbutton-previewImage
{
    background-color: transparent;
    border: none;
    display: none;
    text-align: center;
    cursor: pointer;
}

.crossbutton-previewVideo
{
    cursor: pointer;
}

.crossbutton-previewMisc
{
    cursor: pointer;
    position: absolute;
    margin-left: 90px;
    margin-top: 10px;
    font-family: Special Elite, cursive;
}

.flex-wrap-space-around
{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}

.preview-img:hover .crossbutton-previewImage
{
    display: block;
}

.usersArtifact-div
{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.usersArtifact-imagecontainer
{
    height: 100%;
}

.model-artifacts-1 .accordion-body .usersArtifact img
{
    object-fit: contain;
    height: 100%;
}

.profile-arrow-div
{
    position: absolute;
    margin-left: -13rem;
    margin-top: 0;
    animation: blinker 1s step-start infinite;
}

.profile-expand-button
{
    position: relative;
    margin-top: 8rem;
    background: -webkit-linear-gradient(right,
            #e9dd9c,
            #a87833,
            #9e7d49,
            #c9a465,
            #ab915d);
    width: 230px;
    margin-left: 0;
    border-radius: 50px;
    transition: 0.3s all;
    animation: 5s bounce ease infinite;
    cursor: pointer;
    text-align: center;
    padding: 10px 20px;
    margin-left: auto;
    margin-right: auto;
}

.profile-expand-button span
{
    border: none;
    color: white !important;
    font-size: 12px;
    font-family: raven-bold-inline;
}

.profile-casepaper
{
    height: 30px;
    width: 10px;
    background-color: white;
    position: absolute;
    transform: skewX(-20deg) rotate(65deg);
    margin-top: 114px;
    margin-left: 80px;
    display: none;
}

.model-profile-1
{
    overflow-y: scroll;
}

.model-profile-1 .modal-header
{
    padding: 10px;
}

.model-profile-1 .modal-header .btn-close:focus
{
    outline: 0;
    box-shadow: none;
    opacity: 1;
}

.model-profile-1 .modal-header .btn-close
{
    background: transparent url("data:image/svg+xml, %3csvg xmlns='http://www.w3.org / 2000/svg' viewBox='0 0 16 16' fill='%23fc0'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
    margin-top: -20px;
    font-size: 20px;
}

.accordion-button,
.accordion-body
{
    font-family: Special Elite, cursive;
}

.upload-accordion-body
{
    background: #efefef;
    border: 1px solid black;
    padding: 3px 5px 0;
}

.accordion-button:focus,
.accordion-button:not(.collapsed)
{
    color: black !important;
    background-color: white !important;
    border-color: white !important;
}

.profile-modal
{
    background-color: rgb(255, 255, 255) !important;
    height: 400px;
    overflow-y: scroll;
    padding: 0 15px 0 0 !important;
}

.model-profile-1 .profile-modal
{
    padding: 0 15px 0 0 !important;
}

.profile-modal p
{
    font-family: Special Elite, cursive;
}

.profile-modal-close
{
    position: absolute;
    right: 12px;
    top: 5px;
    font-size: 20px;
    cursor: pointer;
}

.address-modal-close
{
    position: absolute;
    right: 22px;
    top: -235px;
    font-size: 20px;
    z-index: 20;
    cursor: pointer;
}

.profile-gotomap
{
    display: flex;
    flex-direction: column;
    justify-content: space-around;

}

.profile-gotoisland
{
    margin-top: 20px;
    text-align: center;
}

.profile-gotomap
{
    margin-top: 42px;
}

.profile-book img
{
    margin-top: 0;
    margin-right: 14px;
}

.uploadcaseprofile-button
{
    margin-top: 2rem;
    border-radius: 50px;
    transition: 0.3s all;
    animation: 5s bounce ease infinite;
    cursor: pointer;
    text-align: center;
    border-radius: 15px;
    position: relative;
    z-index: 5;
}

.retrieve-button
{
    text-align: center;
    position: relative;
    z-index: 5;
}

.go_back_arrow .menus
{
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
}

.go_back_arrow .menus
{
    padding: 10px 20px;
}

.go_back_arrow .menus .main-drop-button
{
    padding-top: 0;
}

.menuitems-casesetting button.main-drop-button:hover
{
    color: white;
}

.menuitems-casesetting
{
    height: 0;
    padding: 10px 0;
}

.menuitems-casesetting button.main-drop-button svg
{
    margin-top: 0;
    /* color: #fff !important; */
}

.wave_img
{
    text-align: center;
    /* padding: 23px 0 0; */
}

.go_back_arrow .menus .menuitems button
{
    display: flex;
    flex-direction: unset;
    font-size: 100% !important;
    align-items: center !important;
    margin-left: 5px;
}

.profile-gotomap .go_back_arrow
{
    width: 230px;
    margin: 0 auto;
}

/* .go_back_arrow .menus .menuitems .customdropdown {
position: absolute;
right: 0px !important;
left: 0;


} */
.go_back_arrow .menus .menuitems button.main-drop-button img
{
    width: 35px;
    height: 25px;
}

.customdropdown .customdropdown-submenu
{
    width: max-content !important;
}

.retrieve-button button
{
    margin-left: 0;
    margin-top: 2rem;
    padding-top: 4px;
    transition: 0.3s all;
    animation: 5s bounce ease infinite;
    font-family: Special Elite, cursive;
    cursor: pointer;
    text-align: center;
    background-color: black;
    color: white;
    padding: 7px 10px 2px;
}

.bottom-map-mob
{
    text-align: center;
}

.bottom-map-mob img
{
    display: inline-block;
}

.uploadcaseprofile-button button
{
    font-size: 0.9rem;
    font-family: Special Elite, cursive;
    font-weight: bold;
    background: #efefef;
    border: 1px solid black;
    padding: 5px 5px 0;
}

.gotoisland-button
{
    cursor: pointer;
    text-align: center;
    background-color: black;
    padding: 10px !important;
    border-radius: 15px;
    font-weight: bold;
    font-size: 0.9rem;
    letter-spacing: 1px;
    width: 70%;
    margin-top: 30px;
}

.profile-gotoisland span
{
    color: white;
    font-family: Special Elite, cursive !important;
}

.accordion-button:focus
{
    z-index: 3;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: none;
}

.profile-breifcase
{
    padding: 115px 0 64px;
}

.profile-breifcase-video
{
    width: 100%;
    border: 1px dotted red;
}

.accordion-button:not(.collapsed)
{
    color: #0c63e4;
    background-color: #e7f1ff;
    box-shadow: none;
}

.img_text
{
    padding-top: 40px;
}

.rowss
{
    /* border: 10px solid; */
    position: relative;
    /* top: -53px; */
}

.profile-breifcase
{
    background-image: url('https://s3.us-east-2.amazonaws.com/inter-routes.com/overall-1681228708327.png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
}

/* .back {
  position: relative;
  top: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border: 1px solid blue;


} */

/* .top {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  /* margin-right: 5%; */
/* border: 1px solid red;
/* } */
.upload
{
    margin-top: 76px;
}

/* 23/5/2022 */
.img_text
{
    position: relative;
}

.img_text a
{
    position: absolute;
    top: 8%;
    right: 20%;
}

.img_text span img
{
    padding-top: 30px;
}

.img_text span img
{
    padding-top: 30px;
}

.model-artifacts-1 .accordion-body .usersArtifact img
{
    width: 100%;
    max-width: 100%;
}

.model-artifacts-1 .accordion-body .usersArtifact img
{
    width: 100%;
    max-width: 100%;
}



.model-receipt-1 .modal-content
{
    background-color: #fff !important;
    padding: 50px;
    text-align: center;
}

.model-receipt-1 .modal-content table.details-table
{
    margin: 0 auto;
}

.model-receipt-1 .modal-content p
{
    text-align: center !important;
    font-family: Special Elite, cursive;
    font-size: 17px !important;
    margin-top: 20px;
}

.model-receipt-1
{
    background-color: #0000006e;
}

.model-receipt-1 .modal-content h2
{
    font-size: 26px;
    font-weight: 600;
}

.model-receipt-1 .modal-content table.details-table th,
tr td
{
    padding: 12px;
}

.model-artifacts-1 .accordion-body ul
{
    padding: 0;
}

ul.date_sec li
{
    cursor: pointer;
    width: max-content;
    margin: 0 auto;
}

.fingerprint-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.fingerprint-btn img {
    width: 20px;
}

@media (max-width: 767px)
{

    .model-profile-1 .profile-modal 
    {
        font-size: 12px;
    }

    .accordion-body 
    {
        font-size: 12px;
    }

    .upload-accordion-body 
    {
        margin-top: 10px;
    }
}
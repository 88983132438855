.skeleton
{
    background-color: rgb(228, 228, 228);
    display: flex;
    justify-content: space-between;
    padding: 40px 20px;

    &_circleImg
    {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    &_circle
    {
        height: 700px;
        width: 700px;
        position: relative;
        border-radius: 100%;
        overflow: hidden;
    }

    &_star
    {
        background: gray;
        width: 15px;
        height: 15px;
        border-radius: 100px;
    }

    &_right
    {
        width: 900px;
    }

    &_iframe
    {
        height: 700px;
    }

	&_box
    {
        position: relative;

        img
        {
            width: 35px;
        }
    }

    &_tooltip
    {
        background: black;
        color: white;
        font-family: sans-serif;
        border-radius: 5px;
        padding: 10px;
        position: absolute;
        top: 0;
        left: 55px;
        display: none;
        min-width: 200px;
        max-width: 280px;
        z-index: 999;
    }

    &_box:hover &_tooltip
    {
        display: block;
    }

    &_label
    {
        font-size: 10px;
    }

    &_name
    {
        font-size: 13px;
        margin: -5px 0 3px;
        color: darkgrey;
    }

	&_btn
	{
		margin-top: 15px;
		display: flex;
		align-items: center;
		
		button 
		{
			font-family: 'raven-bold-inline';
			background: -webkit-linear-gradient(right,
			#e9dd9c,
			#a87833,
			#9e7d49,
			#c9a465,
			#ab915d) !important;
			cursor: pointer;
			color: white;
			border-radius: 50px;
			padding: 5px 30px;
			text-align: center;
		}
	}

	&_plus
    {
        font-size: 50px;
        line-height: 35px;
        cursor: pointer;
        margin-left: 10px;
    }

	&_plusText
	{
		font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
		margin-left: 20px;
	}

    &_soundBtn
    {
        border: 2px solid black;
        border-radius: 5px;
        padding: 5px 20px;
        margin: 30px 0;
    }
}
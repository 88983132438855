.ani
{
    position: absolute;
    top: 156px;
    height: 703px;
    right: -347px;
    margin-right: 552px;
    width: max-content;
}

.btn-add
{
    z-index: 1;
    position: relative;
    margin-left: 42px;
    border: 3px solid #6a6a6a;
}

.checkout-main-span
{
    margin-left: 4px;
    color: white;
    font-family: Special Elite, cursive;
    text-transform: uppercase;
    font-size: 12px;
}

.center-content-d
{
    background-color: white;
    text-align: center;
    border-radius: 10px;
    padding: 20px;
}

.see-gead
{
    font-size: 0.9rem;
    font-family: Special Elite, cursive;
    font-weight: bold;
    background: #efefef;
    border: 1px solid black;
    padding: 5px 5px 0;
    display: flex;
    align-items: center;
    margin: auto;
}

.see-gead svg
{
    margin-left: 5px;
}

.btn-check:active+.btn-light:focus,
.btn-check:checked+.btn-light:focus,
.btn-light.active:focus,
.btn-light:active:focus,
.show>.btn-light.dropdown-toggle:focus
{
    box-shadow: none !important;
}

.btn-check:focus+.btn-light,
.btn-light:focus
{
    color: #000;
    background-color: #f9fafb;
    border-color: #f9fafb;
    box-shadow: none;
}

.thumbs .btn-prev
{
    border-bottom: 3px solid;
    border-left: 3px solid;
    transform: rotate(45deg);
    left: -5px;
}

.thumbs .btn-next
{
    border-bottom: 3px solid;
    border-left: 3px solid;
    transform: rotate(-135deg);
    right: -10px;
}

.alice-carousel__prev-btn,
.alice-carousel__next-btn
{
    position: absolute;
    top: 55px;
    width: auto;
    font-size: 32px;
    cursor: pointer;
}

.alice-carousel__next-btn
{
    right: -4px;
}

.arrows
{
    width: 22px;
    height: 22px;
    border-color: #000;
}

.thumbs .btn-prev,
.thumbs .btn-next
{
    position: absolute;
    top: 65px;
    font-size: 50px;
    color: #dbba51;
    cursor: pointer;
    z-index: 99999;
}

.thumbs
{
    position: relative;
}

.thumbs .alice-carousel__wrapper
{
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;
    box-sizing: border-box;
    max-width: 650px;
    height: auto;
    margin: 0 auto;
}

.thumbs .alice-carousel__stage-item
{
    position: relative;
    display: inline-block;
    padding: 0 15px;
    margin: 0;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    vertical-align: top;
    white-space: normal;
    line-height: 0;
}

.space-around-width
{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}

.version-text
{
    font-family: novecento sans;
    text-transform: uppercase;
    font-size: 13px;
    text-align: center;
    color: #8a8a8a;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 20px;
}

.version-text span
{
    cursor: pointer;
}

.checkout-icon
{
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 10px 5px;
}

.cart-count
{
    font-size: 13px;
    cursor: pointer;
    padding-left: 6px;
    color: #000;
}

.cart-count:hover
{
    color: #000;
}

.catalogue-mobile
{
    width: 100%;
}

.text-center
{
    text-align: center;
}

.final-image-div
{
    background-color: white;
    text-align: center;
    border-radius: 10px;
    padding: 15px;
}

.mtop-10
{
    margin-top: 10px;
}

.mtop-4
{
    margin-top: 40px;
}

.space-between
{
    display: flex;
    justify-content: space-between;
}

.text-tree-version
{
    font-family: novecento sans;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: bold;
    text-align: center;
    color: #8a8a8a;
    margin-top: 10px;
    margin-bottom: 20px;
}

.text-white
{
    color: white !important;
}

.text-grey
{
    color: #8a8a8a;
}

.unlock-text
{
    font-family: raven-bold-inline;
    text-transform: uppercase;
    font-size: 1.6vw;
}

.treesoflife-maindiv
{
    margin-top: 20px;
}

.offcanvas-margin-top
{
    margin-top: 20px;
    text-align: center;
}

.navbar-margin-top
{
    margin-top: 20px;
}

.margin-left-50
{
    margin-left: 50px;
}

.margin-left-25
{
    margin-left: 25px;
}

.space-around
{
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.you-text
{
    color: #fff9ff !important;
    font-size: 1.5vw !important;
}

.price-text
{
    font-family: raven-bold-inline;
    color: #ffffff;
    display: block;
    text-align: center;
    margin-bottom: 10px;
}

.color-gold
{
    color: #a87833;
}

.font-elite
{
    font-family: Special Elite, cursive;
}

.island_mobile_div1
{
    display: none;
}

.font-raven-inline
{
    font-family: raven-bold-inline;
}

.font-raven
{
    font-family: raven-bold-inline;
    text-transform: uppercase;
    color: #8a8a8a;
}

.island-life
{
    font-size: 13px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 20;
}

.catalogue-main-container
{
    animation: fadein 2s;
    -moz-animation: fadein 2s;
    /* Firefox */
    -webkit-animation: fadein 2s;
    /* Safari and Chrome */
    -o-animation: fadein 2s;
}

.navigation_section_catalogue
{
    flex: 0 0 50%;
    width: 24%;
}

img.img-fluid.left-catalogue-logo
{
    margin-top: 2rem;
    width: 18%;
}

.catalogue-right-logos-header
{
    padding-bottom: 10px;
    background-image: url('../../assets/images/topbar_v2.png');
    width: 100%;
}

.right-catalogue-section
{
    flex: 0 0 50%;
}

.catalogue-header
{
    background-image: url('../../assets/images/topbar_v2.png');
    height: 100px;
    padding-bottom: 10px;
}

.catalogue-body
{
    font-family: Special Elite, cursive;
    font-size: 16px !important;
    display: flex;
}

table,
tr,
td
{
    margin-bottom: 10px;
    padding: 5px;
    border: 1px dotted rgb(136, 135, 135);
    table-layout: fixed;
}

.catalogue-body-div1
{
    display: flex;
    padding: 26px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.catalogue-body-text1
{
    font-family: Special Elite, cursive !important;
}

.catalogue-body-left
{
    background-color: #20141e;
    width: 50%;
    float: left;
    bottom: 0;
    height: inherit;
    padding: 20px;
}

.catalogue-body-right
{
    background-color: #fafafa;
    width: 50%;
    height: inherit;
    float: right;
    bottom: 0;
    position: relative;
}

.catalogue-body-right .gallery-images
{
    display: flex;
    justify-content: center;
    margin-top: 15px;
}

.catalogue-body-right .gallery-images img
{
    width: 50px;
    margin: 0 8px;
    cursor: pointer;
}

.catalogue .footer
{
    margin: 0 !important;
    text-align: left !important;
}

.cat-footer.catalogue
{
    position: fixed;
    width: 100%;
    bottom: 0;
    background: #fafafa;
    margin: 0 auto;
}

.cat-footer.catalogue .footer ul
{
    padding: 0px;
    margin: 1rem 2rem 0;
}

.cat-footer.catalogue ul.footer-social
{
    padding: 0px;
    margin: 1rem 12rem !important;
}

.arrow-text
{
    /* background: -webkit-linear-gradient(
    right,
    #e9dd9c,
    #a87833,
    #9e7d49,
    #c9a465,
    #ab915d
  ); */
    -webkit-text-fill-color: transparent;
}

.images-background
{
    height: 140px;
    width: 140px;
    background-color: white;
    margin: 15px auto;
    /* margin-right: 50px !important; */
    text-align: center;
    border-radius: 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 6px solid #ffffff;
}

.lock-image
{
    /* margin-left: 92px;
  margin-top: -16px !important; */
    height: 45px;
    width: 45px;
    position: absolute;
    top: -5px;
    right: -15px;
}

.right-body
{
    margin-top: 20px;
    display: flex;
    justify-content: space-evenly;
}

.right-body-bookopen
{
    margin-top: 20px;
    display: flex;
    margin-left: 10px;
    /* justify-content: space-evenly; */
    flex-direction: column;
}

.catalogue-image-shadow
{
    box-shadow: 0 0 10px #fff;
    max-width: 100%;
    /* width: 400px; */
    height: auto !important;
}

.catalogue-blink
{
    animation: blinker 1s step-start infinite;

    @keyframes blinker
    {
        50%
        {
            opacity: 0;
        }
    }
}

/* random */
.flex-center
{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.catalogue-uploadBtn img
{
    width: 20px;
    margin-right: 5px;
}

.catalogue-uploadBtn
{
    background: -webkit-linear-gradient(right,
            #e9dd9c,
            #a87833,
            #9e7d49,
            #c9a465,
            #ab915d);
    width: 100%;
    border-radius: 50px;
    border: none;
    color: white;
    padding: 8px 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
}
.addressBtn{
    background: -webkit-linear-gradient(right,
    #e9dd9c,
    #a87833,
    #9e7d49,
    #c9a465,
    #ab915d);
width: max-content;
border-radius: 50px;
border: none;
color: white;
padding: 8px 10px;
cursor: pointer;
display: flex;
font-family: raven-bold-inline;
align-items: center;
}
.catalogue-uploadBtn-mob
{
    display: none;
}

.catalogue-discover-wrapper
{
    transition: 0.3s all;
    animation: 5s bounce ease infinite;
}

.btn-next
{
    font-size: 33px;
    cursor: pointer;
}

.btn-prev
{
    cursor: pointer;
    margin-top: 6px;
    font-size: 33px;
    /* position: relative;
  left: 30px; */
}

.alice-carousel
{
    overflow: hidden;
}

.islandbodies_4
{
    padding-top: 0px;
}

.islandbodies_5
{
    padding-top: 0px;
}

.thumbs .alice-carousel__wrapper
{
    max-width: 580px;
}

.islandbodies_4a
{
    height: 40px !important;
    width: 70px !important;
}

.islandbodies_5a
{
    height: 30px !important;
    width: 110px !important;
}

.tree_4
{
    display: flex;
    align-items: center;
    justify-content: center;
}

.tree_5
{
    display: flex;
    align-items: center;
    justify-content: center;
}

.selected-border
{
    border: 6px solid #a87833;
}

.right-mob-body
{
    display: none;
}

.catalogue-body-mobile
{
    display: none;
}

.catalogue-uploadBtn-mob span
{
    margin-left: 4px;
    color: white;
    font-family: Special Elite, cursive;
    text-transform: uppercase;
    font-size: 1.5vw;
}

.catalogue-uploadBtn-mob img
{
    width: 20px;
}

.images-foreground
{
    /* height: 70px; */
    width: 70px;
}

.images-background-modems
{
    display: none;
}

.book-catologue img.catalogue-book-img
{
    position: absolute;
    right: 0;
    top: 60px;
}

.book-catologue
{
    position: relative;
}

.catalogue-main-container .see-more {
    font-size: 14px;
    font-weight: 600;
    color: #9e7d49;
    cursor: pointer;
}

@media only screen and (max-width: 599px)
{
    .ani
    {
        position: absolute;
        top: 156px;
        /* height: 703px; */
        left: -471px;
        /* margin-right: 552px; */
        width: max-content;
    }
}

@media only screen and (min-width: 767px)
{
    .ani
    {
        position: absolute;
        top: 156px;
        /* height: 703px; */
        left: -471px;
        /* margin-right: 552px; */
        width: max-content;
    }
}

@media only screen and (max-width: 1350px)
{

    .thumbs .alice-carousel__wrapper
    {
        max-width: 390px;
        height: auto;
        margin: 0 auto;
    }

    .thumbs .images-background
    {
        height: 100px;
        width: 100px;
    }

    .thumbs button.btn.btn-light
    {
        font-size: 0.7rem;
    }

    .cat-footer.catalogue ul.footer-social
    {
        padding: 0px;
        margin: 1rem 8rem !important;
    }

    .catalogue-body-left 
    {
        width: 100%;
    }

    .catalogue-body-right 
    {
        width: 100%;
    }
}

@media only screen and (max-width: 1024px)
{
    .thumbs .alice-carousel__stage-item
    {
        width: 100% !important;
    }

    .thumbs .alice-carousel__wrapper
    {
        max-width: 380px;
        height: auto;
        margin: 0 auto;
        width: 240px;
    }
}

@media screen and (max-width: 768px)
{

    .thumbs .btn-prev,
    .thumbs .btn-next
    {
        position: absolute;
        top: 25px;
        font-size: 33px;
        color: #dbba51;
        cursor: pointer;
        z-index: 99999;
    }

    .catalogue .footer
    {
        margin: 0 !important;
        padding: 0 !important;
    }

    .catalogue .footer
    {
        margin: 0 !important;
        text-align: center !important;
    }

    .cat-footer.catalogue ul.footer-social
    {
        padding: 0px;
        margin: 1rem 0 !important;
    }

    .catalogue .footer
    {
        position: relative !important;
    }

    .alice-carousel__prev-btn,
    .alice-carousel__next-btn
    {
        position: absolute;
        top: 55px;
        width: auto;
        font-size: 25px;
        cursor: pointer;
    }

    .alice-carousel__next-btn
    {
        right: -4px;
    }

    .margin-left-25
    {
        margin-left: 0 !important;
    }

    .offcanvas-header
    {
        position: absolute !important;
        right: 0px;
    }

    .offcanvas-header .btn-close
    {
        background: transparent url('https://s3.us-east-2.amazonaws.com/inter-routes.com/minussign-1656616164060.png') center/1em auto no-repeat;
        padding: 1px;
        margin-bottom: 2px;
        margin-right: 0.5px;
        margin-top: -3.2px;
        border: 1px solid #a87833;
        border-radius: 50px;
    }

    .islandbodies_4a
    {
        height: 30px !important;
        width: 50px !important;
    }

    .islandbodies_4
    {
        padding-top: 0;
    }

    .islandbodies_5
    {
        padding-top: 0;
    }

    .islandbodies_5a
    {
        height: 10px !important;
        width: 60px !important;
    }

    /* .price-text {
    margin-left: 4.5vw;
  } */
    .treesoflife-div
    {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        width: 100%;
    }

    .treesoflife-text
    {
        font-family: raven-bold-inline;
        text-transform: uppercase;
        font-size: 2vw;
        color: white;
        margin-top: 20px;
        margin-left: 8px;
    }

    .unlock-text
    {
        font-family: raven-bold-inline;
        text-transform: uppercase;
        font-size: 1.1vw;
    }

    .bestrepresents-text
    {
        font-family: raven-bold-inline;
        text-transform: uppercase;
        font-size: 12px;
    }

    .btn-prev
    {
        cursor: pointer;
        margin-top: 0px;
        font-size: 20px;
        margin-left: 0px;
        margin-right: 10px;
        color: #dbba51;
    }

    .margin-left-50
    {
        margin-left: 20px;
    }

    .btn-next
    {
        margin-right: 0px;
        font-size: 20px;
        cursor: pointer;
        margin-left: 10px;
        color: #dbba51;
    }

    .lock-image
    {
        /* margin-left: 56px;
    margin-top: -10px !important; */
        height: 35px;
        width: 35px;
    }

    .images-foreground
    {
        height: 50px;
        width: 50px;
    }

    .catalogue-uploadBtn-mob
    {
        display: block;
        background: -webkit-linear-gradient(right,
                #e9dd9c,
                #a87833,
                #9e7d49,
                #c9a465,
                #ab915d);
        width: 100%;
        border-radius: 50px;
        border: none;
        color: white;
        padding: 8px;
        cursor: pointer;
        margin-left: 20px;
    }

    .catalogue-uploadBtn-mob span
    {
        font-size: 2vw;
    }

    .catalogue-uploadBtn-mob img
    {
        width: 20px;
        display: inline-block;
    }

    .island_mobile_div1
    {
        display: none !important;
    }

    .catalogue-body-mobile
    {
        font-family: Special Elite, cursive;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #707070;
    }

    .catalogue-right-logos-header
    {
        display: none !important;
    }

    .catalogue-body1
    {
        display: none !important;
    }

    /* .navbar-toggler {
    display: block !important;
    margin-left: 0rem;
  } */
    .catalogue-mob-body-right
    {
        background-color: #fafafa;
        width: 100vw;
        bottom: 0;
    }

    .right-mob-body
    {
        display: block !important;
        padding-top: 20px;
        margin-top: 0px;
        display: flex;
        justify-content: space-evenly;
    }

    .all-islands-mob
    {
        padding-left: 2.9rem;
    }

    .images-background-modems
    {
        height: 80px;
        width: 80px;
        background-color: white;
        margin-right: 20px !important;
        margin-top: 15px;
        text-align: center;
        border-radius: 20px;
        display: block;
    }

    .images-background
    {
        height: 140px;
        width: 140px;
    }

    .thumbs .alice-carousel__wrapper
    {
        max-width: 300px;
        height: auto;
        margin: 0 auto;
        width: 200px;
    }

    .thumbs .alice-carousel__stage-item
    {
        position: relative;
        display: inline-block;
        padding: 0 15px;
        margin: 0;
        box-sizing: border-box;
        width: 100% !important;
        height: 100%;
        vertical-align: top;
        white-space: normal;
        line-height: 0;
    }

    .space-around-width .alice-carousel
    {
        width: 350px;
    }

    /* checkout elements */
    .payment-container
    {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 90%;
        border: #000;
        border-style: solid;
        border-color: #dcdbdb;
        border-width: 1px;
        border-radius: 0.3rem;
        margin-bottom: 1rem;
        height: 4rem;
    }

    .payment-btn-container
    {
        display: flex;
    }

    .payment-btn-decrease,
    .payment-btn-increase,
    .payment-remove-btn
    {
        width: 1.8rem;
        height: 1.8rem;
        padding: 0 !important;
        background: white;
        border: 1px solid #dcdbdb;
        border-radius: 0.3rem;
        color: #707070;
        font-family: 'Courier New', Courier, monospace;
    }

    .payment-remove-btn
    {
        background-color: #dd334461;
    }

    .payment-title,
    .payment-price,
    .payment-qty
    {
        font-size: 0.6rem;
    }

    .payment-img
    {
        height: 1.5rem;
        width: 1.5rem;
    }
}

@media (min-width: 481px) and (max-width: 767px)
{

    /* .catalogue-book-img {
    width: 20px !important;
  } */
    .island_mobile
    {
        width: 155% !important;
        height: 12%;
        background-image: url('../../assets/images/left_panel_2.png');
        background-position: center;
        position: relative;
        top: 0;
        padding-top: 10px;
    }

    .island_mobile .custom-collapse
    {
        margin-top: -20px;
        margin-left: -20px;
        border: 3px solid #eee1a3;
    }

    .left-island-logo
    {
        margin-top: 3px !important;
        margin-right: 8px;
    }

    .island_mobile .island-nav-bar-responsive
    {
        margin-left: 120px;
    }

    .island_mobile_div1
    {
        width: 100% !important;
    }

    .all-islands-mob
    {
        padding-left: 1.2rem !important;
    }

    .lock-image
    {
        /* margin-left: 92px;
    margin-top: -16px !important; */
        height: 35px;
        width: 35px;
    }
}

@media (min-width: 425px) and (max-width: 570px)
{
    .offcanvas-header
    {
        padding: 0px !important;
    }

    .offcanvas-header .btn-close
    {
        margin-bottom: 0px;
        margin-right: 2px;
        margin-top: 1.5px;
    }

    .selected-border
    {
        border: 3px solid #a87833;
    }

    .treesoflife-maindiv
    {
        margin-top: 0px;
    }

    .offcanvas-margin-top
    {
        margin-top: 15px;
    }

    .navbar-margin-top
    {
        margin-top: 5px;
    }

    .unlock-text
    {
        font-size: 1.2vw;
    }

    .you-text
    {
        color: #f1edf1;
        font-size: 2.4vw !important;
    }

    .tree_5
    {
        padding-top: 10px;
    }

    .tree_4
    {
        padding-top: 10px;
    }

    .islandbodies_4
    {
        padding-top: 0;
    }

    .islandbodies_5
    {
        padding-top: 2rem;
    }

    .islandbodies_4a
    {
        height: 30px !important;
        width: 40px !important;
    }

    .islandbodies_5a
    {
        height: 10px !important;
        width: 50px !important;
    }

    .images-background
    {
        height: 130px;
        width: 130px;
    }

    .__active
    {
        width: 90px !important;
    }

    .treesoflife-text
    {
        margin-top: 5px;
    }

    .bestrepresents-text
    {
        font-size: 12px;
    }

    .margin-left-50
    {
        margin-left: 0px;
    }

    .margin-left-25
    {
        margin-left: 0px;
    }

    /* .treesoflife-div {
    width: 260px;
  } */
    .space-around-width .alice-carousel
    {
        width: 160px;
    }

    .btn-prev
    {
        margin-left: 0px;
    }

    .offcanvas
    {
        margin-top: 75px !important;
        width: 60%;
    }

    /* .navbar-dark .navbar-toggler {
    display: block !important;
  } */

    .island_heading_tablet img
    {
        margin-right: 3.6rem !important;
    }

    .navbar
    {
        position: inherit !important;
    }

    .island_navigation_tablet
    {
        display: none !important;
    }

    .island_heading_mobile
    {
        display: block !important;
    }

    .island_mobile .navbar-toggler-icon
    {
        display: block !important;
    }

    .island_heading_tablet
    {
        margin-left: -5vw !important;
        margin-top: -15px !important;
    }

    .island_heading_tablet img
    {
        margin-left: -5vw !important;
        margin-top: 0px !important;
    }

    .text-island-mob
    {
        font-size: 3.5vw;
    }

    .island_mobile_div1
    {
        width: 100% !important;
    }

    .island_image
    {
        height: 200px;
        object-fit: contain;
    }

    .volcano-bottom-row-island
    {
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .file-text
    {
        font-size: 3vw;
    }

    .hard-copy-text
    {
        font-size: 2.4vw;
    }

    .hard-copy-image
    {
        height: 12px !important;
    }

    .video-island
    {
        width: 100% !important;
        border: 1px dashed red;
    }

    .island-suitcase-wrapper img
    {
        width: 40px !important;
    }

    .classified-mob
    {
        cursor: pointer;
    }

    .show-expand-button-mob
    {
        margin-left: 6.4rem;
        width: 40%;
        margin-top: -2rem;
        font-size: 0.6vw !important;
    }

    .go_back_arrow
    {
        cursor: pointer;
    }

    /* .navbar-toggler {
    display: block !important;
    position: inherit !important;
    margin-top: -7rem !important;
    margin-left: -8rem !important;
  } */
    /* .drawer-button {
    margin-left: -0.5rem !important;
    margin-top: -206vh !important;
  } */
    .catalogmenu_btn .drawer-button
    {
        margin-left: 0px !important;
        left: auto !important;
    }

    .modal-content
    {
        width: 100% !important;
        /* padding-left: 7% !important;
    margin-top: -5rem;
    margin-bottom: 2rem !important; */
    }

    .letter-mob
    {
        overflow-y: scroll;
        height: 320px !important;
    }

    .letter3-web
    {
        display: none !important;
    }

    .uploadBtn
    {
        width: 28%;
        border-radius: 50px;
        cursor: pointer;
        border: none;
        color: white !important;
        padding: 2px;
    }

    .cropper-wrap-box
    {
        width: 380px !important;
        height: 500px !important;
    }

    .without_FI_tap
    {
        margin-left: -180px !important;
        margin-top: -15px !important;
        position: absolute !important;
        width: 140px !important;
    }

    .without_FI_Ubutton
    {
        width: 130px;
        font-family: 'raven-bold-inline' !important;
        text-transform: uppercase;
        transform: rotate(-5deg);
        position: absolute;
        margin-top: 90px;
        margin-left: -170px;
    }

    .imageDesticationTap
    {
        position: absolute;
        margin-right: auto;
        margin-top: -390px;
        margin-left: 100px;
    }

    .imageDestication
    {
        margin-left: 100px;
        max-height: 180px;
        height: 180px;
        max-width: 200px;
        margin-top: -216px;
        transform: rotate(-4.5deg);
    }

    .bag_upload
    {
        height: 220px;
        width: 220px;
    }

    .crossBtn
    {
        margin-left: -220px !important;
        margin-top: 220px !important;
        width: 30%;
    }

    .saveBtn
    {
        margin-left: -90px !important;
        margin-top: 210px !important;
    }

    .all-islands-mob
    {
        padding-left: 1.9rem !important;
    }

    .lock-image
    {
        /* margin-left: 40px;
    margin-top: -10px !important; */
        height: 25px;
        width: 25px;
    }
}

@media (min-width: 375px) and (max-width: 424px)
{

    /* .navbar-dark .navbar-toggler {
    display: block !important;
  } */
    .crossBtn
    {
        margin-left: -220px !important;
        margin-top: 220px !important;
        width: 30%;
    }

    .saveBtn
    {
        margin-left: -90px !important;
        margin-top: 210px !important;
    }

    .island_heading_tablet img
    {
        margin-right: 0.6rem !important;
    }

    .navbar
    {
        position: inherit !important;
    }

    /* .navbar-toggler {
    display: block !important;
    margin-top: -6rem !important;
    margin-left: -8rem !important;
    position: inherit !important;
  } */

    .island_navigation_tablet
    {
        display: none !important;
    }

    .island_heading_mobile
    {
        display: block !important;
    }

    .island_mobile .navbar-toggler-icon
    {
        display: block !important;
    }

    .island_heading_tablet
    {
        margin-left: -17vw !important;
        margin-top: -15px !important;
    }

    .text-island-mob
    {
        font-size: 3.5vw;
        margin-top: 20px;
    }

    /* .navbar-toggler {
    display: block !important;
    position: inherit !important;
    margin-top: -7rem !important;
    margin-left: -8rem !important;
  } */
    .drawer-button
    {
        margin-left: -0.4rem !important;
        margin-top: -70.6rem !important;
    }

    .volcano-bottom-row-island
    {
        margin-left: 1rem;
        margin-right: 4rem;
    }

    .go_back_arrow
    {
        width: 65%;
        margin-left: 0;
    }

    .file-text
    {
        font-size: 2.8vw;
    }

    .hard-copy-image
    {
        height: 9px !important;
    }

    .open_button
    {
        height: 25px;
    }

    .island-suitcase-wrapper img
    {
        width: 30px !important;
    }

    .video-island
    {
        width: 100% !important;
        border: 1px dashed red;
    }

    .show-expand-button-mob
    {
        margin-left: 5.1rem;
        width: 42%;
        margin-top: -1rem;
        padding: 0px;
        padding-bottom: 5px;
    }

    .show-expand-button-mob span
    {
        border: none;
        font-size: 1.8vw;
        font-family: raven-bold-inline;
        margin-bottom: 10px;
    }

    .close-button-mob
    {
        /* margin-left: 14.5vw; */
        /* width: 40%; */
        border-radius: 50px;
        cursor: pointer;
        padding: 5px 20px;
    }

    .close-button-mob span
    {
        border: none;
        color: white !important;
        font-size: 0.6rem;
        font-family: raven-bold-inline;
    }

    .classified-mob
    {
        cursor: pointer;
        margin-bottom: -15px;
        margin-top: 2px;
    }

    .letter-mob
    {
        overflow-y: scroll;
        height: 250px !important;
    }

    .letter3-web
    {
        display: none !important;
    }

    .cropper-wrap-box
    {
        width: 380px !important;
        height: 500px !important;
    }

    .uploadBtn
    {
        width: 38%;
        padding: 2px;
        margin-left: -2px;
    }

    .without_FI_tap
    {
        margin-left: -172px !important;
        margin-top: -11px !important;
        position: absolute !important;
        width: 120px;
    }

    .without_FI_Ubutton
    {
        font-family: 'raven-bold-inline' !important;
        text-transform: uppercase;
        font-size: 12px !important;
        transform: rotate(-5deg);
        position: absolute;
        margin-left: -165px !important;
        margin-top: 90px !important;
    }

    .upload-text
    {
        font-size: 4vw !important;
    }

    .bag_upload
    {
        height: 220px;
        width: 220px;
    }

    .arcticbrr img
    {
        width: 88% !important;
    }

    .imageDesticationTap
    {
        position: absolute;
        margin-right: auto;
        margin-top: -390px;
        margin-left: 50px;
    }

    .imageDestication
    {
        margin-left: 58px;
        max-height: 180px;
        height: 180px;
        max-width: 200px;
        margin-top: -216px;
        transform: rotate(-4.5deg);
    }

    .offcanvas
    {
        margin-top: 75px !important;
        width: 75%;
    }

    .islands-background
    {
        width: 80px;
        height: 80px;
    }

    .islands-background img
    {
        height: 40px !important;
    }

    .access_Island_text a img
    {
        width: 90%;
    }

    .all-islands-mob
    {
        padding-left: 1.7rem !important;
    }

    .all-islands-mob img
    {
        width: 20rem;
    }

    .images-background
    {
        height: 90px;
        width: 90px;
        margin-top: 4px;

        text-align: center;
        border-radius: 14px;
    }

    .space-around-width .alice-carousel
    {
        width: 150px;
    }

    .margin-left-50
    {
        margin-left: 0px;
    }

    .margin-left-25
    {
        margin-left: -12px;
    }

    .lock-image
    {
        /* margin-left: 40px;
    margin-top: -10px !important; */
        height: 25px;
        width: 25px;
    }

    .__active
    {
        width: 80px !important;
    }

    .images-foreground
    {
        height: 40px;
        width: 40px;
    }

    .selected-border
    {
        border: 2px solid #a87833;
    }

    /* .treesoflife-div {
    width: 200px;
  } */
    .unlock-text
    {
        font-size: 1.8vw;
    }
}

.treesoflife-div
{
    width: 100%;
}

@media (min-width: 320px) and (max-width: 374px)
{
    .all-islands-mob
    {
        padding-left: 0;
    }

    .all-islands-mob img
    {
        max-width: 100%;
    }

    .catalogue .footer
    {
        position: relative;
    }

    .offcanvas
    {
        width: 65% !important;
    }

    .thumbs .alice-carousel__wrapper
    {
        max-width: 100px;
        height: auto;
        margin: 0 auto;
        width: 160px;
    }

    .alice-carousel__stage-item button
    {
        font-size: 7px !important;
        padding: 5px 9px;
    }

    .images-background
    {
        height: 60px;
        width: 60px;
        margin-top: 4px;
        text-align: center;
        border-radius: 14px;
    }

    /* .treesoflife-div {
    width: 180px;
  } */
    .lock-image
    {
        /* margin-left: 40px;
    margin-top: -10px !important; */
        height: 25px;
        width: 25px;
    }

    .__active
    {
        width: 80px !important;
    }

    .space-around-width .alice-carousel
    {
        width: 150px;
    }

    .margin-left-50
    {
        margin-left: 20px;
    }

    .margin-left-25
    {
        margin-left: -12px;
    }

    .unlock-text
    {
        font-size: 1.6vw !important;
    }

    .bestrepresents-text
    {
        font-size: 2.5vw !important;
    }

    /* .navbar-dark .navbar-toggler {
    display: block !important;
  } */
    .cancelicon
    {
        display: none !important;
    }

    .crossBtn
    {
        margin-left: -160px !important;
        margin-top: 176px !important;
        font-size: 8px !important;
        width: 26% !important;
    }

    .saveBtn
    {
        margin-left: -70px !important;
        margin-top: 170px !important;
        font-size: 8px !important;
        width: 26% !important;
    }

    /* .navbar-toggler {
    display: block !important;
    margin-left: 0px;
  } */
    .island_heading_tablet img
    {
        margin-right: 7px !important;
    }

    /* .navbar-toggler {
    position: inherit !important;
    margin-top: -15.5rem !important;
    margin-left: -0.6rem !important;
  } */
    .rounded-circle
    {
        width: 25px !important;
        height: 25px !important;
    }

    .drawer-button
    {
        /* margin-left: -19.3rem !important; */
        margin-top: -70.4rem !important;
        position: absolute;
    }

    .images-foreground
    {
        height: 35px;
        width: 35px;
    }

    .upload-text
    {
        font-size: 4vw !important;
    }

    .island_navigation_tablet
    {
        display: none !important;
    }

    .island_heading_mobile
    {
        display: block !important;
    }

    .island_mobile .navbar-toggler-icon
    {
        display: block !important;
        width: 20px;
    }

    .island_heading_tablet
    {
        margin-left: -30vw !important;
        margin-top: -12px !important;
    }

    .text-island-mob
    {
        font-size: 3.5vw;
    }

    .uploadBtn
    {
        width: 38%;
        padding: 2px;
        margin-left: -2px;
    }

    .letter-mob
    {
        overflow-y: scroll;
        height: 230px !important;
    }

    .without_FI_Ubutton
    {
        width: 100px;
        font-family: 'raven-bold-inline' !important;
        text-transform: uppercase;
        transform: rotate(-5deg);
        font-size: 2.4vw;
        position: absolute;
        margin-left: -200px !important;
        margin-top: 110px !important;
    }

    .bag_upload
    {
        height: 180px;
        width: 180px;
    }


    /* .modal-content {
    width: 100% !important;
    padding-left: 7% !important;
    margin-top: -5rem;
    margin-bottom: 2rem !important;
  } */
    .without_FI_tap
    {
        margin-left: -152px !important;
        margin-top: -11px !important;
        position: absolute !important;
        width: 120px;
    }

    .without_FI_Ubutton
    {
        font-family: 'raven-bold-inline' !important;
        text-transform: uppercase;
        transform: rotate(-5deg);
        position: absolute;
        margin-left: -132px !important;
        margin-top: 90px !important;
    }

    .imageDestication
    {
        margin-left: 60px;
        max-height: 140px;
        height: 140px;
        max-width: 200px;
        margin-top: -176px;
        transform: rotate(-4.5deg);
    }

    .imageDesticationTap
    {
        width: 120px;
        position: absolute;
        margin-right: auto;
        margin-top: -323px;
        margin-left: 63px;
    }

    .island_image
    {
        height: 150px;
    }

    .getIsland
    {
        text-align: center;
        margin: 20px;
    }

    .volcano-bottom-row-island
    {
        margin-left: 8px;
        margin-right: 1px;
    }

    .file-text
    {
        font-size: 3.2vw;
    }

    .video-island
    {
        width: 100% !important;
        border: 1px dashed red;
    }

    .show-expand-button-mob
    {
        margin-left: 4.7rem;
        width: auto !important;
        margin-top: -1rem;
        padding: 5px 20px;
        padding-bottom: 5px;
    }

    .show-expand-button-mob span
    {
        border: none;
        font-size: 2.4vw;
        font-family: raven-bold-inline;
        margin-bottom: 10px;
    }

    .close-button-mob
    {
        /* margin-left: 14.5vw; */
        /* width: 40%; */
        border-radius: 50px;
        cursor: pointer;
        padding: 5px 20px;
    }

    .close-button-mob span
    {
        border: none;
        color: white !important;
        font-size: 2.5vw;
        margin-bottom: 1rem;
        font-family: raven-bold-inline;
    }

    .island-suitcase-wrapper img
    {
        width: 40px !important;
    }

    .hard-copy-image
    {
        height: 12px !important;
    }

    .go_back_arrow
    {
        width: 55%;
        margin-left: 0;
    }

    .arcticbrr img
    {
        width: 88% !important;
    }

    .offcanvas
    {
        margin-top: 65px !important;
        width: 80%;
    }

    .islands-background
    {
        width: 80px;
        height: 80px;
    }

    .islands-background img
    {
        height: 40px !important;
    }

    .access_Island_text a img
    {
        width: 90%;
    }

    .arrow-text
    {
        font-size: 20px;
    }

    .classified-mob
    {
        margin-bottom: 5px;
    }

    .all-islands-mob
    {
        padding-left: 2rem !important;
    }

    .all-islands-mob img
    {
        width: 80vw;
    }

    .catalogue-uploadBtn-mob
    {
        width: 100%;
        border-radius: 50px;
        padding: 3px;
        margin-left: 0px !important;
    }

    .catalogue-uploadBtn-mob span
    {
        font-size: 3vw;
    }

    .payment-title,
    .payment-price,
    .payment-qty
    {
        font-size: 0.6rem;
    }

    .payment-img
    {
        height: 1.5rem;
        width: 1.5rem;
    }
}

.book-catologue img
{
    filter: drop-shadow(0px 0px 15px #00000070);
    border-radius: 30px 0px 0px 30px;
}

.mobbook-catologue img
{
    filter: drop-shadow(0px 0px 15px #00000070);
    border-radius: 30px 0px 0px 30px;
}

.alice-carousel__stage-item
{
    text-align: center;
}

.iscatlogue
{
    position: relative;
}

.catalogmenu_btn
{
    position: absolute !important;
    top: 70px;
    right: 10px;
}

.mobbook-catologue
{
    position: absolute;
    top: 0;
    right: 0;
}

@media screen and (max-width: 600px)
{
    .space-between-cat
    {
        flex-direction: column !important;
    }

    .space-between-cat .catalogue-uploadBtn-mob
    {
        margin-left: auto !important;
        display: inline-block !important;
        width: auto !important;
        padding-left: 20px;
        padding-right: 20px;
    }

    .space-between-cat .catalogue-uploadBtn-mob a
    {
        font-size: 16px !important;
    }

    .catalogmenu_btn .drawer-button
    {
        margin-left: 0 !important;
        margin-top: 0 !important;
    }

    .catalogue-mobile .font-elite
    {
        margin: 25px 0px;
    }

    .social_link
    {
        margin: 40px 0px;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px)
{
    .images-background
    {
        height: 110px;
        width: 110px;
        margin: 15px auto;
    }

    .thumbs .alice-carousel__wrapper
    {
        position: relative;
        overflow-x: hidden;
        overflow-y: hidden;
        box-sizing: border-box;
        max-width: 330px;
        height: auto;
        margin: 0 auto;
        width: 210px;
    }

    .thumbs .alice-carousel__stage-item
    {
        width: 100% !important;
    }

    .alice-carousel__prev-btn,
    .alice-carousel__next-btn
    {
        position: absolute;
        top: 55px;
        width: auto;
        font-size: 25px;
        cursor: pointer;
    }

    .center-content-c
    {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
}

.center-content-c
{
    padding-left: 100px;
    padding-right: 100px;
}

@media screen and (max-width: 1159px)
{
    .center-content-c
    {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
}

.catalogue-uploadBtn:hover
{
    background: #000 !important;
}

.cat-footer img
{
    filter: brightness(0.5);
}

.cat-footer .footer-links
{
    color: #808080 !important;
}

.footer ul li
{
    border-right: 2px solid #808080;
}

.cat-footer .footer p
{
    color: #808080;
}

.slider_side_title
{
    margin-left: 15px;
}

.slider_side_title .info-icon
{
    color: #a87833;
    font-size: 30px !important;
}

.slider_side_title .benefits-icon
{
    fill: rgb(168, 120, 51);
    width: 30px;
    font-size: 30px;
    margin: 0 6px;
    cursor: pointer;
}

@media screen and (max-width: 767px)
{
    .slider_side_title
    {
        margin-left: 35px;
    }

    .catalogue-body
    {
        flex-direction: column;
    }

    .all-islands-mob
    {
        padding-left: 0;
    }

    .all-islands-mob img
    {
        max-width: 100%;
    }

    .catalogue .footer
    {
        position: relative;
    }
}

@media screen and (max-width: 570px)
{
    .slider_side_title
    {
        margin-left: 0px;
    }

    .treesoflife-div
    {
        margin-left: 0px !important;
    }
}

.see-gead:hover
{
    background: #000;
    color: #fff;
}

@media screen and (max-width: 570px)
{
    .alice-carousel__stage-item button
    {
        font-size: 9px;
    }
}

@media screen and (max-width: 767px)
{
    .volcano-bottom-column-island
    {
        align-items: center;
    }
}

@media screen and (min-width: 992px) and (max-width: 1400px)
{
    .price_checout_btn
    {
        grid-template-columns: 2fr 2fr !important;
        padding-top: 20px;
    }
}
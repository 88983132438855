.announcementModal
{
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 990;
    width: 100%;
    height: 100vh;

    &_inner
    {
        width: 100%;
        height: 100vh;
        overflow: auto;
        text-align: center;
    }

    &_bg
    {
        width: 100%;
        height: 100%;
        margin: auto;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;
        opacity: 0.9;
    }

    &_nail1
    {
        position: absolute;
        top: 15px;
        left: 30px;
        width: 50px;
    }

    &_nail2
    {
        position: absolute;
        top: 15px;
        right: 30px;
        width: 50px;
    }

    &_nail3
    {
        position: absolute;
        bottom: 15px;
        left: 30px;
        width: 50px;
    }

    &_nail4
    {
        position: absolute;
        bottom: 15px;
        right: 30px;
        width: 50px;
    }

    &_title
    {
        display: flex;
        align-items: center;
        color: white;
        font-size: 24px;
        justify-content: center;

        img
        {
            margin-left: 10px;
            width: 18px;
        }
    }

    &_logo
    {
        text-align: center;

        img
        {
            width: 90px;
            display: inline-block;
            margin: 30px 0;
        }
    }

    &_text
    {
        color: #cbcbcb;
        font-family: sans-serif;
        padding: 0 180px;
        margin: 5px 0;
        font-size: 15px;

        img
        {
            display: inline-block;
            width: 50px;
        }
    }

    &_box
    {
        text-align: center;
        margin-bottom: 25px;

        img
        {
            width: 100px;
            display: inline-block;
        }
    }

    &_title2
    {
        color: white;
        font-family: sans-serif;
        font-size: 20px;
    }

    &_textBox
    {
        margin-bottom: 20px;
    }

    &_thougthPic
    {
        position: absolute;
        top: 120px;
        right: 50px;
        width: 200px;
    }

    &_magnet
    {
        text-align: right;
        padding-right: 100px;
        margin-bottom: 20px;
        position: relative;
    }

    &_magnet > img
    {
        width: 100px;
        display: inline-block;
    }

    &_btns
    {
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;

        img
        {
            width: 35px;
            cursor: pointer;
            margin: 0 8px;
        }
    }

    &_arrow
    {
        position: absolute;
        right: 230px;
        bottom: 150px;
        width: 50px;
        height: 50px;
    }
}
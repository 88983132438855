.index-modal
{
    background-color: rgb(255, 255, 255) !important;
    height: 700px;
    z-index: 6;
    text-align: left !important;
    overflow-y: scroll;
    position: absolute;
    width: 700px;
}

.index-modal-spinner
{
    background-color: #9e7d49 !important;
    height: 700px;
    z-index: 3;
    width: 700px;
    padding-top: 30vh;
    text-align: center !important;
    /* overflow-y: scroll; */
    position: absolute;

}

.index-modal-closebutton
{
    font-size: 30px;
    cursor: pointer;
}

.index-modal-div
{
    display: flex;
    flex-direction: row;
    background: transparent linear-gradient(90deg, #DFCD8B 0%, #A27B41 56%, #9D7942 100%) 0 0 no-repeat padding-box;
    justify-content: space-between;
}

.index-m-acc-header>.accordion-button::after,
.index-m-accordian_3-header>.accordion-button:not(.collapsed)::after,
.index-m-searchacc-header>.accordion-button:not(.collapsed)::after
{
    background-image: url("../../assets/images/down-arrow.png") !important;
    size: 20px;
}

.index-m-accordian_2>.accordion-button:focus,
.index-m-accordian_2>.accordion-button:not(.collapsed)
{
    color: white !important;
    background-color: black !important;
}

.index-m-searchacc-header>.accordion-button:not(.collapsed),
.index-m-accordian_3-header>.accordion-button:not(.collapsed)
{
    color: white !important;
    background-color: black !important;
}

.index-m-accordian_2>.accordion-button::after
{
    background-image: url("../../assets/images/down-arrow.png") !important;
    size: 20px;
}

.index-m-accordian_2>.accordion-button:not(.collapsed)::after,
.index-m-accordian_3-header>.accordion-button::after
{
    background-image: url("../../assets/images/subdown-arrow.png") !important;
}

.index-m-accordian_3-header>.accordion-button:focus
{
    background-color: black !important;
    color: white !important;
}

.index-modal-main .modal-header .btn-close
{
    background: transparent url("https://s3.us-east-2.amazonaws.com/inter-routes.com/cros-1656617767066.png") center / 1em auto no-repeat !important;
    margin-top: 4rem;
    font-size: x-large;
    right: 10px;
}

.team-button>.accordion-button:focus
{
    background-color: rgb(211, 166, 114) !important;
    color: white !important;
}

.index-m-acc-header>.accordion-button:not(.collapsed)::after,
.black-dropbutton>.accordion-button:not(.collapsed)::after,
.index-m-searchacc-header>.accordion-button:not(.collapsed)::after
{
    background-image: url("../../assets/images/down-main-arrow.png") !important;
}

.index-m-searchacc-header>.accordion-button::after
{
    background-image: url("../../assets/images/down-main-arrow.png") !important;

}

.suggestion-pill
{
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 16px;
    text-align: center;
    border: 1px solid black;
    padding: 4px;
    font-size: medium;
}

.display-flex>.accordion-button:not(.collapsed)::after,
.display-flex>.accordion-button::after
{
    margin-top: -10px;
    margin-left: -33vw;
}

.index-m-accordian
{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.index-m-accordian_1
{
    background-color: black;
    border-radius: 14px;
    height: 7.6vh;
    width: max-content;
    padding: 10px;
    color: rgb(255, 255, 250);
    display: flex;
    align-items: center;
    border: 1px solid #9D7942 !important;
    font-size: 0.6em;
}

.index-m-accordian_2
{
    border-radius: 14px;
    color: rgb(0, 8, 0);
    border-radius: 14px;
    width: max-content;
    padding: 5px;
}

.keynumber-subaccordian>button
{
    border: none;
    background-color: #040404;
    border: 2px solid #A27B41;
    color: white;
}

.index-m-accordian_3
{
    /* width: 200px; */
    margin-top: 20px;
    background-color: black;
}

.index-m-accordian_5
{
    background-color: black;
}

.index-m-accordian_3-item>button
{
    background-color: rgb(211, 166, 114);

}

.index-m-accordian_3-header
{
    background-color: black;
    color: white;
}

.index-m-accordian_3-header>button,
.index-m-accordian_3-item
{
    background-color: black;
    border: 1px solid #9D7942;
    color: white;
}

.index-m-accordian_2a
{
    border-radius: 14px;
    color: white !important;
    width: max-content;
    padding: 5px;
    font-family: segoe ui;
}

.keyword-fix{
    width: 85%;
}

.index-m-accordian_3-header>.accordion-collapse>.accordion-body>ul>li
{
    font-family: segoe ui;

}

.index-m-accordian_2>button
{
    background-image: #20141E;
    background: #20141E;
    color: white !important;
    border-radius: 14px;
    font-family: segoe ui;
}

.display-flex>button
{
    display: flex;
    flex-direction: column !important;
}

.index-m-heading
{
    padding: 10px 0 0 25px !important;
    font-size: 1.2vw;
    text-transform: uppercase;
    font-family: Special Elite, cursive !important;
    font-weight: bold;
    margin-bottom: 0 !important;
}

.index-m-searchheading
{
    margin-top: 12px;
    font-family: ui-serif;
}

.index-m-heading>span
{
    font-size: 1.6vw !important;
}

.index-m-searchbar
{
    padding: 10px 25px 25px !important;
    background: transparent linear-gradient(90deg, #DFCD8B 0%, #A27B41 56%, #9D7942 100%) 0 0 no-repeat padding-box;
    position: relative;
}

.index-m-searchbar>input
{
    width: 100%;
    height: 8vh;
    font-family: segoe ui;
    padding: 20px
}

.index-m-searchbar::-webkit-input-placeholder
{
    font-family: segoe ui;
}

.index-m-searchbar:-ms-input-placeholder
{
    font-family: segoe ui;
}

.index-m-searchbar:-moz-placeholder
{
    font-family: segoe ui;
}

.index-m-searchbar::-moz-placeholder
{
    font-family: segoe ui;
}

.index-m-searchbar>button
{
    background: transparent;
    border: none;
    font-size: 2vw;
    position: absolute;
    right: 35px;
    top: 15px;
}

.index-m-acc-header>button>span
{
    font-size: 2vw;
    font-weight: bold;
}

.index-diagram-div
{
    background-image: linear-gradient(to right, rgb(211, 166, 114), rgb(239, 218, 109));
    color: white;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    border: 1px solid rgb(185, 163, 100) !important;
    padding: 10px;
    font-size: 1.4vw;
}

.team-button,
.team-button>button
{
    background-color: rgb(211, 166, 114);
    padding: 5px;
    font-weight: bold;
    font-family: segoe ui;
    font-size: 15px;
    color: white
}

.team-button>button::after
{
    background-image: url("../../assets/images/down-arrow.png") !important;
}

.index-m-accordian_3-item>.accordion-button:focus
{
    background-color: rgb(211, 166, 114) !important;
    color: white !important;
}

.team-button>button:focus,
.index-m-accordian_4>
{
    background-color: rgb(211, 166, 114) !important;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 5px;
    font-size: 15px;
    color: white
}

.index-m-acc
{
    background: transparent linear-gradient(90deg, #DFCD8B 0%, #A27B41 56%, #9D7942 100%) 0 0 no-repeat padding-box;
    border: 1px solid rgb(185, 163, 100) !important;
}

.index-m-searchacc
{
    border: 1px solid rgb(185, 163, 100) !important;
    background: #040404;
    color: white
}

.index-m-searchacc-header
{
    background: #040404;
    color: white;
    border: 1px solid rgb(185, 163, 100) !important;
}

.index-m-searchacc-header>button
{
    font-size: 1.8vw;
    font-weight: bold;
    background: #040404;
    color: #9D7942 !important;
}

.index-m-acc-header>button:focus,
.index-m-acc-header>button:not(.collapsed)
{
    background-color: black !important;
    color: #9D7942 !important;
}

.index-m-acc-header>button
{
    font-size: 1.8vw;
    font-weight: bold;
    margin: 8px;
    background: black;
    color: #9D7942;
}
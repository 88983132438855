.userForm
{
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
    width: 100%;
    height: 100vh;

    &_inner
    {
        width: 100%;
        height: 100vh;
        overflow: auto;
		font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    }

    &_bg
    {
        width: 100%;
        height: 100%;
        margin: auto;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;
        opacity: 0.9;
    }

    &_nail1
    {
        position: absolute;
        top: 15px;
        left: 30px;
        width: 50px;
    }

    &_nail2
    {
        position: absolute;
        top: 15px;
        right: 30px;
        width: 50px;
    }

    &_nail3
    {
        position: absolute;
        bottom: 15px;
        left: 30px;
        width: 50px;
    }

    &_nail4
    {
        position: absolute;
        bottom: 15px;
        right: 30px;
        width: 50px;
    }

    &_title
    {
        color: #c69c6d;
        font-size: 24px;
        text-align: center;
        font-family: Orbitron;
        margin-top: 30px;
        text-align: center;
    }

    &_logo
    {
        text-align: center;

        img
        {
            width: 90px;
            display: inline-block;
            margin: 30px 0;
        }
    }

    &_text
    {
        color: #cbcbcb;
        font-family: 'Segoe UI';
        padding: 0 180px;
        margin: 20px 0;
        font-size: 18px;
    }
    
    &_btns
    {
        display: flex;
        justify-content: center;
        padding: 0 100px 20px;
        margin-top: 30px;
    }

    &_btn
    {
        background: -webkit-linear-gradient(right,
        #e9dd9c,
        #a87833,
        hsl(37, 37%, 45%),
        #c9a465,
        #ab915d);
        text-align: center;
        border-radius: 50px;
        cursor: pointer;
        padding: 10px 35px;
        color: white;
        font-family: raven-bold-inline !important;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &_btn:hover
    {
        color: black;
    }

    &_close
    {
        position: absolute;
        right: 90px;
        top: 22px;
        font-size: 26px;
        cursor: pointer;
        color: white;
    }

    &_emoji
    {
        width: 150px;
        margin: auto;
    }

    &_body
    {
        width: 700px;
        margin: auto;
        margin-top: 30px;
    }

    &_fields
    {
        display: flex;
        margin-bottom: 25px;

        > div
        {
            width: 50%;
        }
    }

    &_textInput
    {
        width: 97%;
        margin-right: 12px;
        height: 35px;
        padding-left: 10px;
        outline: none;
        color: #c69c6d;
    }

    &_body
    {
        label
        {
            color: white;
        }

        select
        {
            width: 97% !important;
            height: 35px;
            color: #c69c6d;
        }
    }

	&_box
	{
		width: 500px;
		margin: auto;
		margin-top: 50px;

		> div
		{
			margin-bottom: 25px;
		}
	}

	&_desc
	{
		color: white;
		text-align: center;
		margin: 30px 0;
	}

	&_label
	{
		color: white;
    	margin: 5px 0;
	}

	textarea
	{
		color: black;
		width: 320px;
		height: 100px;
		border-radius: 5px;
	}

	select
	{
		width: 320px;
    	height: 35px;
		color: black;
		border-radius: 5px;
		padding-left: 8px;
	}
}
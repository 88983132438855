.orion
{
    background-color: rgb(228, 228, 228);
    display: flex;
    justify-content: space-between;
    padding: 20px 0 50px;

    &_right
    {
        width: 1100px;
    }

    &_circleImg
    {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    &_circle
    {
        height: 700px;
        width: 700px;
        position: relative;
        border-radius: 100%;
        overflow: hidden;
    }

    &_star
    {
        background: gray;
        width: 12px;
        height: 12px;
        border-radius: 100px;
    }

	&_btns
	{
		margin-top: 15px;
		display: flex;
		align-items: center;
		
		button 
		{
			background: black;
			color: white;
			border-radius: 5px;
            margin-right: 20px;
			padding: 10px;
		}
    }

	&_plus
    {
        font-size: 50px;
        line-height: 35px;
        cursor: pointer;
        margin-left: 10px;
    }

	&_plusText
	{
		font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
		margin-left: 20px;
	}

    &_text
    {
        margin-top: 10px;

        button, a
        {
            background: black;
            color: white;
            border-radius: 5px;
            margin: 0 10px;
            padding: 5px 10px;
        }
    }

    &_iframe
    {
        height: 600px;
    }
}
.mob-landing-navbar {
  display: none;
}
.navbar-toggler-icon {
  background-image: url("../../assets/images/toggle.png") !important;
}
.landing-nav-bar {
  display: flex;
  border-bottom: 0.1rem solid #eee1a3;
  justify-content: center;
  background-color: transparent;
}
.navbar-responsive {
  display: none;
}
.navbar-dark .navbar-toggler {
  color: transparent;
  border-color: transparent;
}
ul.nav-unorder-list {
  display: flex;
  align-items: center;
  padding: 0;
  list-style: none !important;
  justify-content: center;
}
.login-wrapper {
  display: flex;
  flex: 25% 1;
  align-items: center;
  list-style: none;
  color: #cfcfcf;
  font-weight: 700;
  justify-content: space-evenly;
  padding-left: 4rem;
}

.logo-one-wrapper {
  height: 55px !important;
}

ul.login-wrapper.nav-item-text a {
  color: #cfcfcf !important;
}

ul.nav-unorder-list li {
  cursor: pointer;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
}

.nav-login,
.nav-about {
  cursor: pointer;
  font-family: ui-serif;
}

.under-logo-fix{
  margin-left: 120px;
}
@media only screen and (max-width: 1199px) {
  .img-custom-menu-medium {
    height: 90px;
  }
  .logo-one-wrapper {
    height: 60px !important;
  }
  .img-custom-menu {
    height: 90px;
  }
  }
@media (max-width: 991px) {
  
  .logo-two-wrapper {
    height: 90px !important;
}
  .logo-one-wrapper {
    height: 60px !important;
}
.img-custom-menu-responsive {
  height: 70px;
  cursor: pointer;
}
.img-custom-menu {
  height: 70px;
}
.img-custom-menu-medium {
  height: 70px;
}
}
@media (max-width: 900px) {
  .logo-two-wrapper {
    height: 90px !important;
}
  .nav-bar {
    display: none;
  }
  .navbar-responsive {
    display: block;
    padding-bottom: 0px;
    border-bottom: 0.1rem solid #eee1a3;
    justify-content: space-between;
    height: 115px;
    align-items: center;
} 

  ul.nav-unorder-list li {
    cursor: pointer;
    padding: 0.5rem 1rem 0.5rem 1rem;
  }
  .mob-landing-navbar {
    display: block;
    position: absolute;
    right: 0;
    top: -10px;
  }
  .navbar {
  display: block;
  top: 12px;
    right: 0;
    left: 20px;
  }
}
@media (max-width: 768px) {
  .navbar-responsive {
    height: 110px;
  }
  .mob-landing-navbar {
    margin-top: 0 !important;
    display: block;
  }
  .img-custom-menu-responsive {
    height: 85px;
    cursor: pointer;
  }
  .custom-collapse {
    min-width: 734px;
  }
  .navbar-collapse {
    border-radius: 5px;
    background-color: rgb(94, 94, 94);
    opacity: 0.9;
    border-color: #e4d173;
    color: white;
    visibility: visible;
    top: 40px !important;
}
.navbar-collapse a {
  display: block;
      width: 100%;
      padding: 0.25rem 1rem;
      clear: both;
      font-weight: 400;
      color: #ffffff !important;
      text-align: inherit;
      text-decoration: none;
      white-space: nowrap;
      background-color: transparent;
      border: 0;
  }
  .logo-two-wrapper {
    height: 78px !important;
  }
  .logo-one-wrapper {
    height: 45px !important;
}
  .logo-three-wrapper {
    height: 55px !important;
  }
}
@media (max-width: 767px) {
  #header .header-top {
    margin: 0 0 10px;
  }
}
@media (max-width: 575px) {
  .mob-landing-navbar {
    margin-top: -9.2vh !important;
    display: block;
  }
  .navbar-responsive {
    height: 6rem;
  }
  #header .logo {
    width: 80px;
  }
  #header .btn-enter-app {
    font-size: 20px;
    line-height: 24px;
    padding: 5px 10px;
  }
  #header .contact-details li {
    margin: 0 10px;
  }
  .login-wrapper {
    display: none;
  }
  ul.nav-unorder-list {
    display: flex;
    /* flex: auto; */
    align-items: center;
    padding: 0;
    padding: 0rem ;
    list-style: none !important;
    justify-content: space-evenly;
  }
  .custom-collapse {
    min-width: 372px;
  }
  ul.nav-unorder-list {
    display: flex;
    flex: 0 0 85%;
  }
}
@media (max-width: 426px) {
  .mob-landing-navbar {
    margin-top: -8vh !important;
    display: block;
  }
  ul.nav-unorder-list li {
    cursor: pointer;
    padding: 0.5rem 0.3rem 0.5rem 0.3rem;
  }

  .img-custom-menu-responsive {
    height: 45px;
  }

  .img-custom-menu-responsive {
    height: 45px;
  }

  .logo-one-wrapper {
    height: 40px !important;
  }

  .nav-item-logo .logo-two-wrapper {
    height: 70px !important;
}

.nav-item-logo .logo-three-wrapper {
  height: 55px !important;
}
}
@media screen and (max-width: 375px) {
  .navbar-responsive {
    height: 6.5rem;
  }
  .custom-collapse {
    min-width: 342px;
  }
  .navbar-collapse {
    border-radius: 5px;
    background-color: rgb(94, 94, 94);
    opacity: 0.9;
    border-color: #e4d173;
    color: white;
    border: 1px solid #e4d173;
    margin: 10px 0 0 0;
}
.navbar-collapse a {
display: block;
    width: 100%;
    padding: 0.25rem 1rem;
    clear: both;
    font-weight: 400;
    color: #ffffff !important;
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}
}

@media (max-width: 320px) {
  .navbar-collapse {
    top: calc(100% + 0px) !important;
  }
}

.custom-collapse {
  background-color: rgb(54, 53, 53);
  border-color: #eee1a3;
  box-shadow: none;
  min-width: 345px;
}
.img-custom-menu-responsive {
  height: 45px;
}

.logo-one-wrapper {
  height: 55px !important;
}

.logo-two-wrapper {
  height: 110px !important;
}

.logo-three-wrapper {
  height: 75px !important;
}
.text-custom-menu {
  font-family: ui-serif;
  font-size: 14px;
  color: rgb(155, 151, 151);
  /* align-self: center; */
  padding: 5px 10px;
}
.menu-navbar-responsive {
  position: relative;
  right: 10px;
}
.userForm2
{
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 990;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);

    &_inner
    {
        width: 600px;
        max-height: 100vh;
        overflow: auto;
        background-color: white;
        border-radius: 10px;
        position: relative;
        padding: 35px 20px;
    }

    &_close
    {
        position: absolute;
        right: 10px;
        top: 5px;
        font-size: 22px;
        cursor: pointer;
    }

	textarea
	{
        width: 100%;
        height: 80px;
        border-radius: 5px;
        border: 1px solid gray;
        padding: 5px;
        font-size: 14px;
        outline: none;
	}
}
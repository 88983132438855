.islandModal2
{
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 990;
    width: 100%;
    height: 100vh;
    background: black;
    overflow: auto;

    &_inner
    {
        width: 1366px;
        height: 700px;
        text-align: center;
        overflow: hidden;
        position: relative;
    }

    &_bg
    {
        width: 100%;
        height: 100%;
        margin: auto;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    }

    &_chest
    {
        width: 200px;
        position: absolute;
        bottom: 50px;
        right: 50px;
        cursor: pointer;
    }

    &_click
    {
        position: absolute;
        bottom: 95px;
        right: 77px;
        color: white;
        z-index: 1;
        cursor: pointer;
        margin: 0;
    }

    &_magneticField
    {
        width: 110px;
        position: absolute;
        right: 180px;
        top: 250px;

        > div
        {
            transform: translate(0px) !important;
        }
    }

    &_magneticField2
    {
        width: 110px;
        position: absolute;
        right: 290px;
        top: 200px;

        > div
        {
            transform: translate(0px) !important;
        }
    }

    &_magneticField3
    {
        width: 110px;
        position: absolute;
        right: 380px;
        top: 370px;

        > div
        {
            transform: translate(0px) !important;
        }
    }

    &_magneticField4
    {
        width: 110px;
        position: absolute;
        right: 550px;
        top: 340px;

        > div
        {
            transform: translate(0px) !important;
        }
    }

    &_magneticField5
    {
        width: 110px;
        position: absolute;
        right: 530px;
        top: 220px;

        > div
        {
            transform: translate(0px) !important;
        }
    }

    &_magnet1, &_magnet2, &_magnet3, &_magnet4, &_magnet5
    {
        width: 0;
        position: absolute;
        bottom: 0px;

        img
        {
            width: 100%;
        }
    }

    &_magnet1.active, &_magnet2.active, &_magnet3.active, &_magnet4.active, &_magnet5.active
    {
        width: 100px;
        transition: 1s;
    }

    &_magnet1.success
    {
        bottom: 330px;
        right: 185px;
        width: 100px;

        > div
        {
            transform: translate(0px) !important;
        }
    }

    &_magnet1
    {
        right: 320px;
    }

    &_magnet2.success
    {
        bottom: 380px;
        right: 295px;
        width: 100px;

        > div
        {
            transform: translate(0px) !important;
        }
    }

    &_magnet2
    {
        right: 420px;
    }

    &_magnet3.success
    {
        bottom: 210px;
        width: 100px;
        right: 385px;

        > div
        {
            transform: translate(0px) !important;
        }
    }

    &_magnet3
    {
        right: 520px;
    }

    &_magnet4.success
    {
        bottom: 240px;
        width: 100px;
        right: 555px;

        > div
        {
            transform: translate(0px) !important;
        }
    }

    &_magnet4
    {
        right: 620px;
    }

    &_magnet5.success
    {
        bottom: 360px;
        right: 535px;
        width: 100px;

        > div
        {
            transform: translate(0px) !important;
        }
    }

    &_magnet5
    {
        right: 720px;
    }

    &_msg
    {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 80px;
        color: white;

        p
        {
            font-size: 20px;
        }

        button
        {
            background: #c69c6d;
            font-family: sans-serif;
            padding: 5px 20px;
            border-radius: 15px;
            font-weight: 600;
        }
    }

    &_arrow1, &_arrow2, &_arrow3, &_arrow4, &_arrow5
    {
        position: absolute;
        width: 60px;
        height: 60px;
        color: red !important;
    }

    &_arrow1
    {
        top: 330px;
        right: 260px;
    }

    &_arrow2
    {
        top: 280px;
        right: 355px;
    }

    &_arrow3
    {
        top: 300px;
        right: 510px;
    }

    &_arrow4
    {
        top: 370px;
        right: 470px;
    }

    &_arrow5
    {
        top: 330px;
        right: 625px;
    }

    &_close
    {
        position: absolute;
        right: 30px;
        top: 20px;
        font-size: 30px;
        cursor: pointer;
    }

    &_msg2 
    {
        position: absolute;
        top: 200px;
        right: 20px;
        color: white;
        z-index: 1;
        font-family: sans-serif;
        width: 150px;
    }
}
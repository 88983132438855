@import url("https://use.typekit.net/rhs4inp.css");

/* html
{
    max-width: 100%;
    max-height: 100%;
    font-family: Orbitron;
    overflow-x: hidden;
} */

/* width */
::-webkit-scrollbar
{
    width: 15px;
}

/* Track */
::-webkit-scrollbar-track
{
    background: #3b3b3b;
}

/* Handle */
::-webkit-scrollbar-thumb
{
    background: #FFF3AD;
    border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover
{
    background: #FFF;
}

body
{
    max-width: 100%;
    max-height: 100%;
    font-family: Orbitron;
    overflow-x: hidden;
}

a,
a:hover
{
    text-decoration: none;
}

.map-height
{
    /* min-height: 100vh; */
    overflow: hidden;
}

.map-container > div:nth-child(1)
{
    top: 60px;
    left: 0px;
}

.map-container > div:nth-child(2)
{
    top: 60px;
    left: 0px;
}

.map-container .scissor_animation
{
    position: absolute;
}

.map-container-dragon div
{
    top: 60px;
    left: 0px;
}

.color-gold
{
    color: #a87833;
}

.card1-div
{
    top: 320px !important;
    left: 33px !important;
    background-color: white;
    border-left: 2px solid #D5D5D5;
    border-right: 2px solid #D5D5D5;
    border-bottom: 2px solid #D5D5D5;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 466px !important;
    min-height: 160px;
}

.card3-div
{
    left: 32px !important;
    background-color: white;
    border-left: 2px solid #D5D5D5;
    border-right: 2px solid #D5D5D5;
    border-bottom: 2px solid #D5D5D5;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 466px !important;
    min-height: 160px;
}

.card3-div
{
    top: 313px !important;
}

.card3-div .island-img2 {
    width: 150px;
    margin: auto;
    position: relative;
}

.card3-div .path1 {
    position: absolute;
    top: 30px;
    left: -15px;
    display: flex;
    align-items: center;
}

.card3-div .path2 {
    position: absolute;
    top: 95px;
    left: -80px;
    display: flex;
    align-items: center;
}

.card3-div .clue-img {
	width: 50px;
}

.btn-self-cracked
{
    background-color: #c69c6d;
    border-radius: 100px;
    color: #fff;
    height: 40px;
    width: 150px;
}

.logo-0,
.logo-1,
.logo-2
{
    width: 125px;
    margin: 0 10px;
}

.logo-0 img,
.logo-1 img,
.logo-2 img
{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.begin img
{
    height: 70px;
}

.begin img,
.begin svg
{
    display: inline-block;
}

.animate-step
{
    animation: animate 1s ease-in-out infinite alternate;
}

.modal-video .close-modal
{
    position: absolute;
    right: 10px;
    top: 5px;
    font-size: 25px;
    color: goldenrod;
    cursor: pointer;
}

.modal-video video
{
    height: 600px;
    width: 100%;
    background: black;
}

.modal-video .next-btn
{
    text-align: right;
    margin: 10px;
}

.modal-video .texts p
{
    margin-bottom: 5px;
    font-weight: 500;
}

.sound-toggle
{
    position: absolute;
    top: 0px;
    right: 30px;
    z-index: 99;
    color: white;
}

.maximise-btn
{
    position: absolute;
    bottom: 90px;
    left: 15px;
    border: 1px solid black;
    z-index: 11;
    background: black;
    color: white;
    padding: 8px 20px;
    border-radius: 5px;
}

.maximise-btn span
{
    color: white;
    font-size: 25px;
    line-height: 20px;
    margin-left: 5px;
}

.collapse 
{
    visibility: visible !important;
}

.img-reality
{
    position: absolute;
    top: 620px;
    left: 400px;
    width: 110px;
    z-index: 13;
}

#tooltip
{
    max-height: 400px;
    overflow: auto;
}

#tooltipCat .tooltip-inner 
{
    max-width: 500px;
}

.map-container .island-icon > div {
    top: 267px;
    left: 10px;
    transform: rotate(35deg);
}

.map-container .island-icon2 > div {
    top: 266px;
    left: 9px;
    transform: rotate(32deg);
}

.hide-thread > div > svg > g > g > g:nth-child(4) {
    display: none !important;
}

.hide-thread > div > svg > g > g > g:nth-child(5) {
    display: none !important;
}

.cable-animation > div {
    width: 900px !important;
    height: auto !important;
    top: 530px;
    left: 80px;
    position: absolute;
    z-index: 1;
}

.wave-img {
    position: absolute;
    top: 205px;
    left: 210px;
    z-index: 1;
    width: 50px;
}

.model-map-1 p img
{
    display: inline-block;
    width: 35px;
    margin-left: 10px;
    cursor: pointer;
}

.play-video.home {
    bottom: 25px;
    left: 10px;
}

.play-video.user-modal {
	top: 50px;
	left: 50%;
	transform: translateX(-50%);
}

.play-video {
    position: absolute;
    z-index: 2;
    border: 2px solid red;
    padding: 15px;
    border-radius: 100px;
}

.play-video .icon {
    width: 60px;
    cursor: pointer;
    animation: animateIcon 1.5s ease-in-out infinite alternate;
}

.play-video .play-video-box {
    background: white;
    box-shadow: 0 0 20px rgb(0 0 0 / 30%);
    border-radius: 10px;
    padding: 15px 20px 15px 50px;
    position: absolute;
    bottom: 80px;
    left: 10px;
    width: 250px;
}

.play-video .play-video-box span, .play-video .play-video-box p {
	color: black;
}

.play-video p {
    margin-bottom: 10px;
    font-family: 'raven-bold-inline';
    cursor: pointer;
    display: flex;
    align-items: center;
}

.play-video p:hover {
    color: red;
}

.play-video .back-arrow {
    position: absolute;
    left: 10px;
    top: 10px;
    cursor: pointer;
}

.play-video .title
{
    font-family: 'Segoe UI';
    margin-bottom: 20px;
    display: inline-block;
}

.play-video img
{
    width: 30px;
    display: inline-block;
    margin-right: 5px;
}

.model-map-1 .begin_button
{
    font-size: 26px;
}

@media (max-width: 768px)
{
    .map-right
    {
        min-height: 500px;
    }

    .string1,
    .string2
    {
        display: none;
    }

    .spider-animation .begin
    {
        top: 50px !important;
    }

    .card1-div,
    .card3-div
    {
        top: 248px !important;
        left: 24px !important;
        width: 336px !important;
    }

    .map-wrapper-dimension-text
    {
        padding-top: 1.5rem !important;
        font-size: 12px !important;
        padding-left: 0.3rem !important;
    }

    .map-left>img
    {
        margin: 2em 0px 0px 5em !important;
    }

    .map-container > div:nth-child(2)
    {
        width: 360px !important;
        height: 360px !important;
    }

    .spider-animation-close
    {
        top: 35px !important;
        right: 70px !important;
    }

    .maximise-btn 
    {
        bottom: 10px;
        left: 10px;
        font-size: 12px;
    }

    .maximise-btn span
    {
        font-size: 20px;
    }
}

@keyframes animate
{
    0%
    {
        color: black;
    }

    100%
    {
        color: #FFF3AD;
    }
}

@keyframes animateIcon
{
    0%
    {
        transform: rotate(-15deg);
    }

    100%
    {
        transform: rotate(15deg);
    }
}
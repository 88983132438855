.wiggling-redarrow
{
    align-self: flex-end;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    transform-origin: bottom;
}

.bounce-1
{
    animation-name: bounce-1;
    animation-timing-function: linear;
}

.spider-animation-close {
    position: absolute;
    top: 50px;
    right: 540px;
    font-size: 35px;
    z-index: 9999;
    cursor: pointer;
    color: #c69c6d;
}

@keyframes bounce-1
{
    0%
    {
        transform: translateY(0);
    }

    50%
    {
        transform: translateY(-50px);
    }

    100%
    {
        transform: translateY(0);
    }
}

.elementToFadeInAndOut
{
    width: 200px;
    height: 200px;
    background: red;
    -webkit-animation: fadeinout 4s linear forwards;
    animation: fadeinout 4s linear forwards;
}

.visible
{
    visibility: visible;
    opacity: 1;
    -webkit-animation: fadein 4s linear forwards;
    animation: fadein 4s linear forwards;
}

.hidden
{
    -webkit-animation: fadeout 4s linear forwards;
    animation: fadeout 4s linear forwards;
}

@-webkit-keyframes fadein
{
    0%
    {
        opacity: 0;
    }

    100%
    {
        opacity: 1;
    }
}

@keyframes fadein
{
    0%
    {
        opacity: 0;
    }

    100%
    {
        opacity: 1;
    }
}

@-webkit-keyframes fadeout
{
    0%
    {
        opacity: 1;
    }

    100%
    {
        opacity: 0;
    }
}

@keyframes fadeout
{
    0%
    {
        opacity: 1;
    }

    100%
    {
        opacity: 0;
    }
}

.fade-in-image
{
    animation: fadeIn 50s !important;
    -webkit-animation: fadeIn 50s !important;
    -moz-animation: fadeIn 50s !important;
    -o-animation: fadeIn 50s !important;
    -ms-animation: fadeIn 50s !important;
}

.map-expandbutton
{
    cursor: pointer;
    z-index: 100;
}

.fade-out-image
{
    animation: fadeout 50s !important;
    -webkit-animation: fadeout 50s !important;
    -moz-animation: fadeout 50s !important;
    -o-animation: fadeout 50s !important;
    -ms-animation: fadeout 50s !important;
}

.map-book-catologue img
{
    position: absolute;
    width: 70px;
    right: 45px;
    top: 125px;
    border-radius: 30px 0px 0px 30px;
    z-index: 5;
}

.display-none
{
    display: none;
}

.map-expandbutton
{
    cursor: pointer;
    z-index: 100;
    border: 1px solid black;
    padding: 2px 5px;
}

.map-book-catologue
{
    border-radius: 30px 0px 0px 30px;
    position: relative;
}

.map-height
{
    min-height: 100vh;
}

.map-main-container-mobile .custom-collapse
{
    margin-top: -20px;
    margin-left: -20px;
    border: 3px solid #eee1a3;
}

.navbar_mob_text
{
    font-size: 11px;
    color: white !important;
    letter-spacing: 2px;
}

.underconstruction_modal
{
    max-width: 800px;
}

.map-main-container
{
    min-height: 95vh;
    width: 100vw;
    display: flex;
    animation: fadein 2s;
    -moz-animation: fadein 2s;
    /* Firefox */
    -webkit-animation: fadein 2s;
    /* Safari and Chrome */
    -o-animation: fadein 2s;
}

.modal-header .btn-close
{
    font-weight: bold !important;
    position: absolute;
    justify-self: end;
}

.left-brain-section
{
    flex: 0 0 30%;
    background-image: url("../../assets/images/left_panel_2.png");
    position: relative;
}

.map-main-container-mobile
{
    background-image: url("../../assets/images/left_panel_2.png");
    background-position: center;
    position: relative;
}

.brain-circle
{
    position: relative;
}

.brain-circle img
{
    width: 80%;
}

img.img-fluid.brain-img-map
{
    position: absolute;
    top: 20%;
    width: 70%;
    left: 15%;
}

.map-wrapper
{
    height: 90vh;
}

svg>#Group_8170 :hover
{
    cursor: pointer;
}

.logos-wrapper
{
    margin-right: 20px;
    margin-bottom: -2rem;
}

.logos-wrapper li
{
    width: 30%;
}

.map-wrapper-dimension-text
{
    color: #8a8a8a;
    position: absolute;
    padding-top: 2.8rem;
    padding-left: 1rem;
    z-index: 4;
    font-family: novecento sans;
    text-transform: uppercase;
    font-weight: bold;
}

.map-wrapper img
{
    filter: drop-shadow(0px 0px 15px #00000070);
}

.map-svg
{
    width: 100%;
    object-fit: cover;
    margin-top: 5rem;
}

.map-svg-fix
{
    width: 1000px !important;
    max-width: 1000px !important;
    object-fit: cover;
    margin-top: 0 !important;
    position: absolute;
    top: 161px;
    left: 10px;
}

.top-forest
{
    top: 315px;
    left: 34px;
    width: 100px;
}

svg>#Group_8170 :hover
{
    cursor: pointer;
}

.logos-wrapper
{
    margin-right: 20px;
    margin-bottom: -2rem;
}

.logos-wrapper li
{
    width: 30%;
}

.logos-wrapper .nav-unorder-list
{
    justify-content: center;
}

.logos-wrapper li
{
    padding: 0rem 0.8rem 0rem 0.8rem;
}

.left-bottom-logos
{
    position: absolute;
    bottom: 5%;
}

.logos
{
    display: flex;
    align-items: flex-end;
    justify-content: space-evenly;
    width: 100%;
}

.logo-images
{
    height: 3rem;
}

.buttons-images
{
    height: 5rem;
}

.right-logos-header
{
    display: flex;
    justify-content: space-between;
    background-image: url("../../assets/images/topbar_v2.png");
}

.buttons-wrapper
{
    position: absolute;
    right: -26%;
    bottom: -45%;
    cursor: pointer;
}

.buttons-wrapper img
{
    width: 65%;
}

.right-map-section
{
    flex: 0 0 70%;
}

.saturn
{
    padding-top: 1.5rem;
}

.logo2-wrap
{
    padding-top: 0.3rem;
    margin-left: 2rem;
}

.sun
{
    padding-top: 0.7rem;
}

.inter-route
{
    color: #d7a45c;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: starship;
    font-weight: bold;
    font-size: 1.7rem;
    letter-spacing: 2px;
}

.bluemoon
{
    padding-top: 3rem;
}

.moon
{
    padding-top: 1rem;
}

.rightmap-logo
{
    padding: 0.6rem;
}

.rightmap-logo img
{
    width: 70%;
}

img.img-fluid.forest-img
{
    position: absolute;
    z-index: 1;
    top: 35%;
    left: 1%;
    width: 10%;
}

img.sky-img
{
    width: 100%;
    top: -7px;
    position: absolute;
    z-index: 3;
}

.map-img
{
    width: 100%;
    height: inherit;
    position: relative;
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
}

.forest-img
{
    position: absolute;
    margin-top: -24.2rem;
    margin-left: 70px;
    width: 70px;
}

.cloud-img
{
    position: absolute;
}

.book-img
{
    position: absolute;
    top: 0;
    right: -2rem;
    width: 120px;
}

.direction-img
{
    position: absolute;
    margin-top: -18rem;
    margin-left: 90px;
    width: 70px;
}

img.img-fluid.skeleton-img
{
    position: absolute;
    z-index: 1;
    bottom: 8%;
    left: 40%;
    height: 24%;
    width: 12%;
}

img.img-fluid.strangercircle-img
{
    position: absolute;
    z-index: 1;
    top: 54%;
    left: 4%;
    width: 8%;
}

img.img-fluid.bluecircle-img
{
    position: absolute;
    z-index: 1;
    top: 50%;
    width: 6%;
    left: 23%;
}

img.img-fluid.bluecircle2-img
{
    position: absolute;
    z-index: 1;
    top: 24%;
    width: 6%;
    left: 50%;
}

img.img-fluid.river-img
{
    position: absolute;
    z-index: 1;
    top: 30%;
    left: 35%;
    width: 12%;
}

.river
{
    position: absolute;
    margin-top: -26.4rem;
    margin-left: 20rem;
}

.river-img
{
    width: 80px;
    -webkit-transform: rotate(10deg);
    -moz-transform: rotate(10deg);
    -ms-transform: rotate(10deg);
    -o-transform: rotate(10deg);
    transform: rotate(10deg);
}

.river_a
{
    position: absolute;
    margin-top: -22.4rem;
    margin-left: 22rem;
}

.rivera-img
{
    width: 40px;
}

.dragon
{
    position: absolute;
    margin-top: -25.4rem;
    margin-left: 19.4rem;
}

.dragon-img
{
    width: 40px;
}

.dragon-img-map
{
    width: 60px;
    height: 60px;
    position: absolute;
    z-index: 9;
    border-radius: 50px;
    cursor: pointer;
    right: 500px;
    top: 50px;
}

.realitybox
{
    position: absolute;
    margin-top: -12.5rem;
    margin-left: 20.9rem;
}

.realitybox-img
{
    width: 150px;
}

.dragonline
{
    position: absolute;
}

.model-map-1 .modal-header
{
    margin-top: -40px !important;
}

.model-map-1 p
{
    margin-bottom: 15px !important;
}

.model-map-1 .modal-header .btn-close
{
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fc0'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
    margin-top: 5.5rem;
    font-size: xx-large;
}

.bluecircle
{
    position: absolute;
    margin-top: -20.2rem;
    margin-left: 16.2rem;
}

.bluecircle-img
{
    width: 30px;
}

.spark_green
{
    position: absolute;
    margin-top: -38.3rem;
    margin-left: -5.5rem;
}

.spark_green-img
{
    width: 40px;
    height: 80px;
    -webkit-transform: rotate(30deg);
    -moz-transform: rotate(30deg);
    -ms-transform: rotate(30deg);
    -o-transform: rotate(30deg);
    transform: rotate(30deg);
}

.red_star
{
    position: absolute;
    margin-top: -33.6rem;
    margin-left: -6.5rem;
}

.spark_star
{
    position: absolute;
    margin-top: -36rem;
    margin-left: -14.5rem;
}

.btn-primary
{
    background-color: transparent;
    border: 0px;
    box-shadow: none;
}

.btn-check:active+.btn-primary:focus,
.btn-check:checked+.btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show>.btn-primary.dropdown-toggle:focus
{
    background-color: transparent;
    border: 0px;
    box-shadow: none;
}

.btn-primary:hover
{
    background-color: transparent;
    border: 0px;
    box-shadow: none;
}

.btn-primary.dropdown-toggle
{
    background-color: transparent;
    border: 0px;
    box-shadow: none;
}

.btn-check:focus+.btn-primary,
.btn-primary:focus
{
    background-color: transparent;
    border: 0px;
    box-shadow: none;
}

.btn-check:active+.btn-primary,
.btn-check:checked+.btn-primary,
.btn-primary.active,
.btn-primary:active,
.show>.btn-primary.dropdown-toggle
{
    background-color: transparent;
    border: 0px;
    box-shadow: none;
}

.map-main-container .navbar-toggler-icon
{
    color: black !important;
}

.map-main-container-mobile
{
    display: none;
}

.map_sky_mobile img
{
    width: 100%;
}

.instruction-close
{
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 20px;
    cursor: pointer;
    z-index: 51;
}

.instruction-open 
{
    position: absolute;
    top: 125px;
    left: 15px;
	z-index: 5;
}

.instruction-open img
{
    color: white;
    width: 50px;
    cursor: pointer;
}

.instruction-open .list {
	position: absolute;
    top: 55px;
    left: 0;
    width: 200px;
    background: white;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0 0 15px rgb(0 0 0 / 30%);
}

.instruction-open .list p {
	margin-top: 5px;
	cursor: pointer;
}

.arrow-brain {
    position: absolute;
    top: 220px;
    right: 60px;
    width: 100px;
    transform: rotate(95deg);
}

.map-left {
    position: relative;
}

.map-left span.view
{
    color: white;
    position: absolute;
    top: 160px;
    right: 70px;
    font-size: 14px;
    font-family: Segoe ui;
    font-weight: 600;
    cursor: pointer;
}

.map-container .see-more
{
    font-size: 14px;
    font-weight: 600;
    color: #9e7d49;
    cursor: pointer;
}

.map-container .red-arrow4
{
    position: absolute;
    top: -90px;
    left: -35px;
    z-index: 1;
    transform: rotate(270deg);
    width: 90px;
}

.map-container .red-arrow2
{
    position: absolute;
    top: 15px;
    left: -110px;
    z-index: 1;
    transform: rotate(245deg);
    width: 90px;
}

.map-container .red-arrow3
{
    position: absolute;
    top: -150px;
    left: 70px;
    z-index: 1;
    transform: rotate(-45deg);
    width: 90px;
}

.map-container .red-arrow5
{
    position: absolute;
    bottom: -195px;
    left: -50px;
    z-index: 1;
    transform: rotate(270deg);
    width: 90px;
}

.map-container .red-arrow6
{
    position: absolute;
    top: -90px;
    left: -35px;
    z-index: 1;
    transform: rotate(240deg);
    width: 90px;
}

.map-container .island-img 
{
    margin-top: 271px;
    width: 76px;
    margin-left: -1px;
}

.card3-div .small-msg-box
{
    position: absolute;
    right: 500px;
    bottom: 10px;
    width: 400px;
    background: white;
    z-index: 1;
    font-family: sans-serif;
    text-align: center;
    padding: 10px;
    border-radius: 5px;
    color: black;
    box-shadow: 0 0 15px rgb(0 0 0 / 30%);
}

.card3-div .small-msg-box img
{
    width: 80px;
    margin: auto;
}

.card3-div .small-msg-box .link
{
    text-decoration: underline;
    margin-top: 10px;
    cursor: pointer;
}

.breadcrumb
{
    position: absolute;
    z-index: 51;
    cursor: pointer;
}

.breadcrumb p
{
    font-family: 'Segoe UI';
    font-size: 12px;
    position: absolute;
    top: 42px;
    font-weight: 700;
}

.breadcrumb-img
{
    width: 120px;
}

.breadcrumb-arrow
{
    position: absolute;
    top: 10px;
    left: 110px;
    width: 60px;
    transform: rotate(125deg);
}

.breadcrumb.first
{
    top: 350px;
    left: 450px;
}

.breadcrumb.second
{
    top: 370px;
    left: 860px;
}

.breadcrumb.sixth
{
    top: 570px;
    left: 150px;
}
/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/
@media only screen and (max-width: 991px)
{
    .map-main-container .navbar-toggler-icon
    {
        background-image: url("../../assets/images/toggle.png") !important;
    }
}

@media screen and (max-width: 768px)
{
    .map_heading_tablet
    {
        background-repeat: no-repeat;
        height: 60px;
        margin-left: 27vw;
        margin-top: 15px;
    }

    .map_heading_tablet img
    {
        height: 40px;
    }

    .navbar_logo2_mobile
    {
        position: absolute;
        margin-left: 38rem;
        margin-top: 2rem;
    }

    .navbar_logo2_mobile img
    {
        height: 15px;
        width: 15px;
    }

    .navbar_logo3_mobile
    {
        position: absolute;
        margin-left: 8rem;
        margin-bottom: 2.4rem;
    }

    .map_sky_mobile
    {
        position: absolute;
        width: auto;
        top: -7px;
        z-index: -2;
    }

    .text_dimension_mobile
    {
        color: #8a8a8a;
        padding-left: 10px;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 2.4vh;
    }

    .book_mob
    {
        right: -15px;
        position: absolute;
    }

    .bottom_mobile
    {
        padding: 0.5rem;
        width: 98%;
        display: flex;

        background-position: center;
        /* Center the image */
        background-repeat: no-repeat;
        /* Do not repeat the image */
        background-size: cover;
        /* Resize the background image to cover the entire container */
    }

    .directions_mob
    {
        margin-top: 1rem;
        margin-left: 10px;
    }

    .directions_mob img
    {
        width: 10%;
    }

    .map_bottom
    {
        display: flex;
        justify-content: center;
        object-fit: cover;
        margin-top: 12px;
    }

    .map_bottom img
    {
        height: 100px;
        width: 100%;
    }

    .map_mob
    {
        margin-top: -2.5rem;
    }

    .map_mob>img
    {
        width: 100vw;
    }

    .brain_mobile
    {
        position: absolute;
    }

    .brain-mob
    {
        height: 12rem;
        width: 12rem !important;
    }

    .brain_mobile img
    {
        margin-top: -100vh;
        width: 42vw;
        margin-left: 14rem;
    }

    .bottom_logos_mobile
    {
        display: flex;
        width: 100%;
        justify-content: space-around;
    }

    .logos_img_mobile
    {
        height: 4.5rem;
    }

    .brain-logos-container
    {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-content: space-between;
        height: 100%;
        width: 100%;
    }

    .brain-image-container
    {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 2rem;
    }

    .bottom_logos_img_mobile
    {
        width: 8%;
        height: 8%;
    }

    .bottom_right_logos_mobile
    {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: -72vh;
        margin-left: 620px;
    }

    .bottom_right_logos_img_mobile
    {
        width: 100px;
        height: 100px;
    }

    .bottom_right_red_star_mobile
    {
        width: 4rem;
        height: 4rem;
        margin-left: 1.2rem;
    }

    .green_spark_mobile
    {
        height: 2rem;
        width: 3rem !important;
    }

    .red_star_mobile
    {
        height: 2rem;
        width: 3rem !important;
    }

    .buttons-container
    {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-content: center;
        align-items: center;
    }

    .logos_img_mobile
    {
        width: 3rem !important;
    }

    .logos_img_mobile_heart
    {
        width: 4rem;
    }

    .star-container
    {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
    }

    .heart-container
    {
        display: flex;
        flex-direction: column;
    }

    .black_forest_mob
    {
        position: absolute;
        margin-top: -24rem;
        margin-left: 3rem;
    }

    .skeleton_mob
    {
        margin-left: 23rem;
        margin-top: -11rem;
        margin-bottom: 5.2rem;
    }

    .river_1_mob
    {
        position: absolute;
        margin-top: -24rem;
        margin-left: 15.2rem;
    }

    .river_2_mob
    {
        position: absolute;
        margin-top: -21.3rem;
        margin-left: 18rem;
    }

    .river_1_mob img
    {
        width: 120%;
    }

    .dragon_mob
    {
        position: absolute;
        margin-top: -23.3rem;
        margin-left: 15rem;
    }

    .dragon_mob img
    {
        width: 150%;
    }

    .model-map-1 .modal-header .btn-close
    {
        background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fc0'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
        margin-top: 5rem;
        font-size: medium;
    }

    .sun_mob
    {
        position: absolute;
        margin-left: 170px;
        margin-top: -40px;
    }

    .sun_mob img
    {
        width: 20px;
    }

    .dropdown-menu
    {
        background-color: rgb(16, 15, 15, 0.85) !important;
    }

    .saturn_mob
    {
        position: absolute;
        margin-left: 68vw;
        margin-top: -20px;
    }

    .saturn_mob img
    {
        width: 25px;
    }

    /* .map-main-container .navbar-toggler-icon {
    display: none;
  } */
    .navigation_tablet
    {
        margin-left: 10px;
        margin-top: 10px;
    }

    .navigation_tablet img
    {
        width: 42px !important;
    }

    .map-main-container .nav-bar-responsive
    {
        margin-left: 120px;
    }

    /* edit */
    .map-main-container-mobile
    {
        display: block;
    }
}

.msg-box
{
    position: absolute;
    right: 100px;
    bottom: 10px;
    width: 300px;
    background: black;
    z-index: 1;
    font-family: sans-serif;
    text-align: center;
    padding: 10px;
    border-radius: 10px;
    color: white;
    opacity: 0.9;
}

.msg-box img
{
    width: 30px;
    display: inline-block;
}

.sea-of-meaning
{
    position: absolute;
    top: 390px;
    left: -110px;
    z-index: 1;
    font-family: 'raven-bold-inline';
    transform: rotate(280deg);
}

.cable-img {
    position: absolute;
    top: 642px;
    width: 930px;
    left: 50px;
    z-index: 1;
}

.sky-animation {
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 3;
}

.spider-animation5
{
    position: absolute;
    z-index: 2;
    top: 0px !important;
    left: 30px !important;
}

.spider-animation5 > div
{
    width: 470px !important;
}

.spider-animation6
{
    position: absolute;
    z-index: 2;
    top: 0px !important;
    left: 30px !important;
}

.brain-animation1
{
    position: absolute;
    z-index: 2;
    top: 80px !important;
    left: 30px !important;
}

.brain-animation1 > div
{
    width: 470px !important;
}

.spider-animation6 > div
{
    width: 470px !important;
}

.wave-animation > div {
    width: 600px !important;
    height: auto !important;
    top: 10px;
    left: -25px;
    position: absolute;
    z-index: 1;
}

.wave-animation2 > div {
    width: 750px !important;
    height: auto !important;
    bottom: 60px;
    left: 280px;
    position: absolute;
    z-index: 9;
    transform: rotate(-10deg);
}

.wave-animation3 > div {
    width: 350px !important;
    height: auto !important;
    top: -20px;
    left: 280px;
    position: absolute;
    z-index: 9;
    transform: rotate(-10deg);
}

.wave-animation4 > div {
    width: 1200px !important;
    height: auto !important;
    bottom: 250px;
    right: 800px;
    position: absolute;
    z-index: 9;
    transform: rotate(80deg);
}

.reality-bg
{
    position: absolute;
    z-index: 1;
    top: 530px;
    left: 60px;
    width: 120px;
}

.reality-man
{
    position: absolute;
    z-index: 2;
    top: 570px;
    left: 75px;
}

.reality-man > div
{
    width: 130px !important;
}

.reality-gears
{
    position: absolute;
    z-index: 2;
    top: 620px;
    left: -8px;
}

.reality-gears > div
{
    width: 120px !important;
}

.reality-bulb
{
    position: absolute;
    z-index: 2;
    top: 635px;
    left: 162px;
}

.reality-bulb > div
{
    width: 100px !important;
}

.reality-steam
{
    position: absolute;
    z-index: 3;
    top: 470px;
    left: 35px;
}

.reality-steam > div
{
    width: 70px !important;
}

.reality-lungs
{
    position: absolute;
    z-index: 3;
    bottom: -30px;
    right: -50px;
}

.reality-lungs > div
{
    width: 300px !important;
}

.reality-blood
{
    position: absolute;
    z-index: 3;
    top: 530px;
    left: 110px;
}

.reality-blood > div
{
    width: 200px !important;
}

.spinning-wheels-img
{
    width: 70px;
    margin: auto;
    cursor: pointer;
}

.radio-wave-img
{
    width: 220px;
    margin: auto;
}

.brain-video {
    margin: auto;
    margin-top: 200px;
    width: 80%;
}

.brain-video .youtube-container > div{
    overflow: hidden;
    /* Keep it the right aspect-ratio */
    aspect-ratio: 16/9;
    /* No clicking/hover effects */
    pointer-events: none;
}

.brain-video .youtube-container > div iframe {
    /* Extend it beyond the viewport... */
    width: 300%;
    height: 100%;
    /* ...and bring it back again */
    margin-left: -100%;
}

.release-wave {
    font-family: Orbitron;
    font-size: 26px;
    color: white;
    cursor: pointer;
    width: 280px;
    margin: auto;
    position: relative;
    z-index: 4;
}

.spinning-wheels-img2 {
    width: 120px;
    margin: auto;
    cursor: pointer;
    position: relative;
    z-index: 4;
}

.brain-card-wave-img {
    width: 35px;
    background: black;
    margin-left: 10px;
}

.card-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.card-top div {
	display: flex;
	align-items: center;
}

.card-top span {
	margin: 0 15px 0 5px;
	font-size: 14px;
}

.card-top img {
    width: 30px;
    cursor: pointer;
}

.mirror-img
{
    position: absolute;
    z-index: 12;
    width: 30px;
    cursor: pointer;
}

.mirror-img1
{
    top: 210px;
    left: 500px;
}

.mirror-img2
{
    top: 470px;
    left: 400px;
}

.mirror-img3
{
    top: 390px;
    left: 250px;
}

.mirror-img4
{
    top: 570px;
    left: 250px;
}

.mirror-img5
{
    top: 370px;
    left: 550px;
}

.mirror-img6
{
    top: 350px;
    left: 60%;
    transform: translateX(-70%);
	position: relative;
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px)
{
    .left-brain-section
    {
        display: none;
    }

    /* .right_end_logo_mobile {
    background-repeat: no-repeat;
    background-image: url("../../assets/images/right_top_logo.png") !important;
    height: 40px;
  } */
    .navbar_logo2_mobile
    {
        position: absolute;
        margin-left: 38rem;
        margin-top: 2rem;
    }

    .navbar_logo2_mobile img
    {
        height: 20px;
        width: 20px;
    }

    .bottom_right_logos_mobile
    {
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* margin-top: -240px; */
        margin-left: 320px;
    }
}

@media (min-width: 425px) and (max-width: 570px)
{
    .sun_mob
    {
        position: absolute;
        margin-left: 94px;
        margin-top: -28px;
    }

    .sun_mob img
    {
        width: 10px;
    }

    .saturn_mob
    {
        position: absolute;
        margin-left: 318px;
        margin-top: -28px;
    }

    .saturn_mob img
    {
        width: 25px;
    }

    /* .navbar_logo1_mobile {
    position: absolute;
    margin-left: 2.8rem;
    margin-top: -2.8rem;
  }
  .navbar_logo1_mobile img {
    height: 40px;
    width: 40px;
  } */
    .map-main-container .navbar-toggler-icon
    {
        display: block;
        background-image: url("../../assets/images/toggle.png") !important;
        width: 24px;
        height: 24px;
        margin-left: -9px;
    }

    /* .right_end_logo_mobile {
    background-repeat: no-repeat;
    background-image: url("../../assets/images/right_top_logo.png") !important;
    width: 200px;
    height: 45px;
    margin-left: 86vw;
    margin-top: -8vh;
  } */
    .map_heading_mobile
    {
        display: block;
        background-repeat: no-repeat;
        background-image: url("../../assets/images/map_heading.png") !important;
        width: 200px;
        height: 40px;
        margin-left: 30vw;
        margin-top: 10px;
    }

    .navbar_logo2_mobile
    {
        position: absolute;
        margin-left: 20rem;
        margin-top: 2rem;
    }

    .navbar_logo2_mobile img
    {
        height: 13px;
        width: 13px;
    }

    .navbar_logo3_mobile
    {
        position: absolute;
        margin-left: 80px;
        margin-bottom: 2.4rem;
    }

    .brain_mobile
    {
        position: absolute;
    }

    .brain_mobile img
    {
        margin-top: -59vh;
        width: 45vw;
        margin-left: 7rem;
    }

    .bottom_logos_img_mobile
    {
        width: 30px;
        height: 30px;
    }

    .bottom_right_logos_mobile
    {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: -43vh;
        margin-left: 340px;
    }

    .bottom_right_logos_img_mobile
    {
        width: 60px;
        height: 60px;
    }

    .bottom_right_red_star_mobile
    {
        width: 2rem;
        height: 2rem;
        margin-left: 1rem;
        margin-bottom: 3.9rem;
    }

    .black_forest_mob
    {
        position: absolute;
        margin-top: -15.5rem;
        margin-left: 1rem;
    }

    .skeleton_mob
    {
        margin-left: 12rem;
        margin-top: -8rem;
        margin-bottom: 2.7rem;
    }

    .skeleton_mob img
    {
        width: 40%;
    }

    .river_1_mob
    {
        position: absolute;
        margin-top: -15.4rem;
        margin-left: 8.4rem;
    }

    .river_2_mob
    {
        position: absolute;
        margin-top: -13.7rem;
        margin-left: 9.8rem;
    }

    .river_2_mob img
    {
        width: 80%;
    }

    .river_1_mob img
    {
        width: 80%;
    }

    .dragon_mob
    {
        position: absolute;
        margin-top: -15rem;
        margin-left: 8rem;
    }

    .map-main-container .nav-bar-responsive
    {
        margin-left: 0px !important;
    }

    .dragon_mob img
    {
        width: 80%;
    }

    .model-map-1 .modal-header .btn-close
    {
        background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fc0'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
        margin-top: 4rem;
        font-size: medium;
    }

    .navigation_tablet
    {
        display: none !important;
    }

    .map_heading_tablet
    {
        display: none !important;
    }
}

@media (min-width: 375px) and (max-width: 424px)
{
    .sun_mob
    {
        position: absolute;
        margin-left: 84px;
        margin-top: -36px;
    }

    .sun_mob img
    {
        width: 8px;
    }

    .saturn_mob
    {
        position: absolute;
        margin-left: 278px;
        margin-top: -28px;
    }

    .saturn_mob img
    {
        width: 25px;
    }

    /* .navbar_logo1_mobile {
    position: absolute;
    margin-left: 2.2rem;
    margin-top: -3rem;
  }
  .navbar_logo1_mobile img {
    height: 48px;
    width: 45px;
  } */
    .map-main-container .navbar-toggler-icon
    {
        background-image: url("../../assets/images/toggle.png") !important;
        width: 17px;
        height: 17px;
        display: block;
        margin-left: -9px;
    }

    /* .right_end_logo_mobile {
    background-repeat: no-repeat;
    background-image: url("../../assets/images/right_top_logo.png") !important;
    height: 48px;
    margin-left: 310px;
    margin-top: -44px;
  } */
    .map_heading_mobile
    {
        display: block;
        background-repeat: no-repeat;
        background-image: url("../../assets/images/map_heading.png") !important;
        width: 200px;
        height: 40px;
        margin-left: 26vw;
        margin-top: 10px;
    }

    .navbar_logo2_mobile
    {
        position: absolute;
        margin-left: 17.4rem;
        margin-top: 2rem;
    }

    .navbar_logo2_mobile img
    {
        height: 13px;
        width: 13px;
    }

    .navbar_logo3_mobile
    {
        position: absolute;
        margin-left: 80px;
        margin-bottom: 2.4rem;
    }

    .navbar_logo3_mobile img
    {
        width: 40%;
    }

    .brain_mobile
    {
        position: absolute;
    }

    .brain_mobile img
    {
        margin-top: -83vw;
        width: 42vw;
        margin-left: 6.5rem;
    }

    .bottom_logos_img_mobile
    {
        width: 30px;
        height: 30px;
    }

    .bottom_right_logos_mobile
    {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: -14rem;
        /* margin-bottom: 5.5vh; */
        margin-left: 77vw;
    }

    .bottom_right_logos_img_mobile
    {
        width: 60px;
        height: 60px;
    }

    .bottom_right_red_star_mobile
    {
        width: 2rem;
        height: 2rem;
        margin-left: 1rem;
        margin-bottom: 2.9rem;
    }

    .black_forest_mob
    {
        position: absolute;
        margin-top: -14rem;
        margin-left: 1rem;
    }

    .skeleton_mob
    {
        margin-left: 6rem;
        margin-top: -9rem;
        margin-bottom: 3rem;
    }

    .skeleton_mob img
    {
        width: 48%;
    }

    .river_1_mob
    {
        position: absolute;
        margin-top: -16rem;
        margin-left: 7rem;
    }

    .river_2_mob
    {
        position: absolute;
        margin-top: -14.4rem;
        margin-left: 8.5rem;
    }

    .river_1_mob img
    {
        width: 80%;
    }

    .river_2_mob img
    {
        width: 80%;
    }

    .dragon_mob
    {
        position: absolute;
        margin-top: -15.6rem;
        margin-left: 6.9rem;
    }

    .dragon_mob img
    {
        width: 80%;
    }

    .map-main-container .nav-bar-responsive
    {
        margin-left: 0px !important;
    }

    .model-map-1 .modal-header .btn-close
    {
        margin-top: 4rem;
        font-size: medium;
    }

    .navigation_tablet
    {
        display: none !important;
    }

    .text_dimension_mobile
    {
        color: #8a8a8a;
        padding-top: 2px;
        padding-left: 10px;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 1.8vh;
        letter-spacing: 1px;
    }

    .map_heading_tablet
    {
        display: none !important;
    }
}

@media (min-width: 320px) and (max-width: 374px)
{
    .text_dimension_mobile
    {
        color: #8a8a8a;
        padding-top: 2px;
        padding-left: 10px;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 1.8vh;
        letter-spacing: 1px;
    }

    .map-main-container .navbar-toggler-icon
    {
        background-image: url("../../assets/images/toggle.png") !important;
        width: 20px;
        height: 20px;
        display: block;
        margin-left: -9px;
    }

    /* .right_end_logo_mobile {
    background-repeat: no-repeat;
    background-image: url("../../assets/images/right_top_logo.png") !important;
    height: 40px;
    margin-left: 260px;
    margin-top: -44px;
  } */
    .map-main-container .nav-bar-responsive
    {
        margin-left: 0px !important;
    }

    .map_heading_mobile
    {
        display: block;
        background-repeat: no-repeat;
        background-image: url("../../assets/images/map_heading.png") !important;
        width: 200px;
        height: 40px;
        margin-left: 20vw;
        margin-top: 10px;
    }

    /* .navbar_logo1_mobile {
    position: absolute;
    margin-left: 1.8rem;
    margin-top: -3rem;
  }
  .navbar_logo1_mobile img {
    height: 30px;
    width: 30px;
  } */
    .navbar_logo2_mobile
    {
        position: absolute;
        margin-left: 15.5rem;
        /* margin-top: 2rem; */
    }

    .navbar_logo2_mobile img
    {
        height: 7px;
        width: 7px;
    }

    .navbar_logo3_mobile
    {
        position: absolute;
        margin-left: 50px;
        margin-bottom: 2.4rem;
    }

    .navbar_logo3_mobile img
    {
        height: 6px;
        width: 6px;
    }

    .brain_mobile
    {
        position: absolute;
    }

    .brain_mobile img
    {
        margin-top: -47vh;
        width: 45vw;
        margin-left: 5rem;
    }

    .bottom_logos_img_mobile
    {
        width: 2rem;
        height: 2rem;
    }

    .bottom_right_logos_mobile
    {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: -34vh;
        margin-left: 78vw;
    }

    .bottom_right_logos_img_mobile
    {
        width: 2.4rem;
        height: 2.4rem;
    }

    .bottom_right_red_star_mobile
    {
        width: 2rem;
        height: 2rem;
        margin-left: 0.3rem;
        margin-bottom: 2.8rem;
    }

    .map_bottom img
    {
        height: 50px;
        width: 100%;
    }

    .black_forest_mob
    {
        position: absolute;
        margin-top: -11.8rem;
        margin-left: 1rem;
    }

    .black_forest_mob img
    {
        width: 60%;
    }

    .skeleton_mob
    {
        position: absolute;
        margin-left: 7rem;
        margin-top: -5.4rem;
    }

    .skeleton_mob img
    {
        width: 75%;
    }

    .river_1_mob
    {
        position: absolute;
        margin-top: -11.8rem;
        margin-left: 6rem;
    }

    .river_2_mob
    {
        position: absolute;
        margin-top: -10.4rem;
        margin-left: 7.5rem;
    }

    .river_1_mob img
    {
        width: 70%;
    }

    .river_2_mob img
    {
        width: 70%;
    }

    .dragon_mob
    {
        position: absolute;
        margin-top: -11.6rem;
        margin-left: 6rem;
    }

    .dragon_mob img
    {
        width: 60%;
    }

    .navbar-collapse
    {
        background: -webkit-linear-gradient(right,
                #e9dd9c,
                #a87833,
                #9e7d49,
                #c9a465,
                #ab915d);
    }

    .model-map-1 .modal-header .btn-close
    {
        background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fc0'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
        margin-top: 4rem;
        font-size: medium;
    }

    .sun_mob
    {
        display: none;
    }

    .saturn_mob
    {
        display: none;
    }

    .navigation_tablet
    {
        display: none !important;
    }

    .map_heading_tablet
    {
        display: none !important;
    }
}
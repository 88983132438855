.forum-iframe{
  width: 100% !important;
  height: 100vh !important;
}
.videofile-type{
  margin: auto; height: 180px; width: auto
}
.filetype-box{
    background-color: #F8F2F0;
    height: 100px;
    width: 100px;
    margin: 40px auto;
    padding: 30px;
}
.video-styling{
  margin: auto; height: 180px; width: auto
}
.directory-filetype{
  background-color: #F8F2F0;
  height: 100px;
  width: 100px;
  margin: 40px auto;
  padding: 30px;
}
.infinite-scroll-component {
  width: 1200px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  overflow: visible !important;
}
.text-caption {
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: auto;
  padding: 30px;
}

.text-seemore {
  font-size: 0.8vw;
  margin: 0 auto;
  text-align: center;
}
.div-latestimage {
  height: 180px;
}
.text-center {
  text-align: center;
}
.input-group {
  width: 20%;
  margin-left: 2vw;
  height: 20px;
}
.form-check-input.is-valid,
.was-validated .form-check-input:valid {
  color: #a87833 !important;
}
.form-check-input.is-valid ~ .form-check-label,
.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid,
.was-validated .form-check-input:valid {
  color: black !important;
  border-color: black;
}
.premiere-description .custom-label {
    font-weight: 600;
    cursor: pointer;
    text-decoration: underline;
    position: relative;
    z-index: 2;
}
.form-check-input.is-valid:checked,
.was-validated .form-check-input:valid:checked {
  background-color: black !important;
}
.boxInnerdiv {
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 10px;
  padding: 10px;
  background-color: black;
  color: white;
  z-index: 1;
}
.boxInnerdiv:hover {
    color: gray;
}
.icon-right {
  position: absolute;
  top: 40%;
  right: 10px;
  font-size: 30px;
}
.icon-left {
  position: absolute;
  top: 40%;
  left: 10px;
  font-size: 30px;
}
.preview-wrap {
  text-align: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  margin: 2rem 5rem;
}
.default-image{
  height: 100% !important;
  margin: auto;
}
.box {
   cursor: pointer;
  float: left;
  position: relative;
  width: 26%;
  padding-bottom: 20%;
  margin: 10px;
  transition: 1s ease;
}
.artifacts-bottom-row{
  margin-top: 30px;
  margin-bottom: 10px;
  position: absolute;
  top:90%;
  left: 9.5rem;
  bottom: 10%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  height: 60px;
  width: 60px;
  border: 3px solid #B8955A;
  overflow: hidden;
}
.artifacts-bottom-row > img{
  padding: 4px;
  cursor: pointer;
  margin-top:-2px;
  height: 100%;
  width: 100%;
}
.hidden-artifacts{
  height: 310px;
  position: absolute;
  background-color: white;
  padding-bottom: 20px;
  border: 1px solid;
  overflow: hidden;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    url("https://s3.us-east-2.amazonaws.com/inter-routes.com/bg_1-1681228267443.png");

}
.box-artifact {
  position: relative;
  cursor: 'pointer';
  text-align: center;
  padding-bottom: 20%;
  margin-top: 20%;
  margin: 10px;
  height: 44vh;
  transition: 1s ease;
}
.artifact-div{
  position: absolute;
  top: 10.5rem;
  /* margin-left: auto;
  margin-right: auto; */
  left: 9rem;
  transform: translate(-50%, -50%);
}
.artifact-div > img{
  display: initial !important;
}
.boxInner img {
  object-fit: scale-down;
}
.boxInner {
  position: absolute;
  left: 10px;
  right: 10px;
  top: 10px;
  bottom: 10px;
  border: 1px solid;
  overflow: hidden;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    url("https://s3.us-east-2.amazonaws.com/inter-routes.com/bg_1-1681228267443.png");
  /* opacity: 0.5; */
  color: black;
}
.box:hover .boxInner img {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
  transition: 1s ease;
}
.box:hover .boxInner video {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
  transition: 1s ease;
}

.box:hover .boxInner .stick-notesl2{
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
  transition: 1s ease;
}
.box:hover .boxInner .titleBox {
  display: none;
}
.titleBox{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.boxInner .titleBox {
  position: absolute;
  bottom: 0;
  font-size: 1vw;
  background: #c9a465;
  font-family: raven-bold-inline;
  color: rgb(255, 255, 255);
  padding: 10px;
  width: 100%;
  text-align: center;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
body.no-touch .boxInner:hover .titleBox,
body.touch .boxInner.touchFocus .titleBox {
  margin-bottom: 0;
}
.checkbox-wrap {
  text-align: center;
  justify-content: space-around;
  display: flex;
}
.checkbox-wrap img {
    width: 40px;
    height: 40px;
    cursor: pointer;
    margin-right: 10px;
}
.main-directory {
  padding-top: 4vh;
  font-family: Special Elite, cursive;
}
.premiere-description{
 text-align: center;
  width: 20vw;
}
.artifact-name{
  font-family: raven-bold-inline;
  text-transform: uppercase;
  font-size: larger;
  font-weight: bold;
  color: #B8955A;
  cursor: pointer;

}

@media only screen and (max-width: 480px) {
  .box {
    width: 100%;
    padding-bottom: 100%;
  }
  .boxInner .titleBox {
    font-size: 3vw;
  }
}
@media only screen and (max-width: 650px) and (min-width: 481px) {
  /* Tablet view: 2 tiles */
  .box {
    width: 50%;
    padding-bottom: 50%;
  }
  .boxInner .titleBox {
    font-size: 2.2vw;
  }
}
@media only screen and (max-width: 1050px) and (min-width: 651px) {
  /* Small desktop / ipad view: 3 tiles */
  .box {
    width: 33.3%;
    padding-bottom: 33.3%;
  }
}
@media only screen and (max-width: 1290px) and (min-width: 1051px) {
  /* Medium desktop: 4 tiles */
  .box {
    width: 25%;
    padding-bottom: 22%;
  }
  .boxInner .titleBox {
    font-size: 1vw;
  }
}
.stick-notesl2 {
  transform: rotate(-5deg);
  background: #cfc;
  box-shadow: 5px 5px 7px rgb(33 33 33 / 70%);
  -webkit-box-shadow: 5px 5px 7px rgb(33 33 33 / 70%);
  margin: 0 auto;
  width: 141px;
  height: 135px;
  align-items: center;
  margin-left:26px;
  padding: 15px 15px;
  position: relative;
  top: 10px;
}
.stick-notesl2 p.text-seemore{
  display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
p.heading-stick {
  font-size: 16px;
  font-weight: bold;
  word-break: break-word;
  padding: 0 20px 0 10px;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.directory-animation-container {
    top: 100px;
    right: 50px;
    position: absolute;
    z-index: 10;
}

.directory-animation > div {
    width: 500px !important;
    height: auto !important;
    position: absolute;
    top: 0;
    right: 0;
}

.directory-card
{
    position: absolute;
    width: 496px;
    top: 350px;
    right: 2px;
    background-color: white;
    border-left: 2px solid #D5D5D5;
    border-right: 2px solid #D5D5D5;
    border-bottom: 2px solid #D5D5D5;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    min-height: 160px;
    padding: 0 10px;
    font-family: 'Segoe UI';
}

.directory-card span
{
    cursor: pointer;
    font-weight: 600;
    color: #9e7d49;
    font-size: 14px;
}

.directory-animation-container .key
{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 10px;
    margin-right: 10px;
}

.directory-animation-container .directory-text
{
    font-size: 12px;
    text-align: center;
    margin: 10px 0;
}

.directory-animation-container .btns
{
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
}

.directory-animation-container .btns button
{
    font-size: 12px;
}

.wiggling-div-directory
{
    justify-content: flex-end !important;
    margin-top: 10px;
}

.directory-animation-close {
    position: absolute;
    top: 150px;
    font-size: 24px;
    font-family: sans-serif;
    cursor: pointer;
    right: -20px;
}

.directory-magnifying-glass {
    position: absolute;
    right: 25px;
    top: 240px;
    z-index: 3;
    cursor: pointer;
}
.welcome-text span.Typewriter__wrapper {
  color: rgb(183 158 105);
  font-size: 2.4rem;
  font-weight: 700;
  text-align: center;
}
div#desc-wrapper-type {
  text-align: center;
  width: 70%;
}
.desc-wrapper-type span.Typewriter__wrapper {
  font-size: 15px;
  font-family: system-ui;
  line-height: normal;
  text-align: center;
  color: #ffff;
  font-weight: 700;
}
.input-password-container {
  position: relative;
}

.eye-icon {
  position: absolute;
  top: 23px;
  right: 25px;
  cursor: pointer;
}
.eye-icon img {
  width: 20px;
}
.desc-wrapper-type span.Typewriter__cursor {
  color: #ffff;
  font-size: 15px;
  font-weight: 700;
}
.welcome-text span.Typewriter__cursor {
  color: rgb(183 158 105);
  font-size: 2.5rem;
  font-weight: 400;
}

.subwelcome-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100vh;
}
.signup-section {
  display: flex;
  animation: fadein 2s;
  -moz-animation: fadein 2s; /* Firefox */
  -webkit-animation: fadein 2s; /* Safari and Chrome */
  -o-animation: fadein 2s; /* Opera */
  width: 100vw;
}
.treasure_box {
  margin-top: 10rem;
}
h4 {
  font-size: 0.8rem;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
h4::before,
h4::after {
  display: inline-block;
  content: '';
  border-top: 0.3rem solid black;
  width: 4rem;
  margin: 0rem 1rem;
  transform: translateY(-0.2rem);
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadein {
  /* Firefox */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadein {
  /* Safari and Chrome */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fadein {
  /* Opera */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.welcome-container {
  flex: 0 0 50%;
  background-image: url('../../assets/images/BG.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 120vh;
  position: relative;
}

.signup-form-container {
  flex: 0 0 50%;
  background-image: url('../../assets/images/signup-formbg.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  background-position: center;
  position: relative;
}

ul.signup-nav-unorder-list {
  list-style: none;
  align-items: center;
  padding-left: 0;
  width: 90%;
  justify-content: center;
  align-self: center;
  display: flex;
  margin: 0 auto;
}

ul.signup-nav-unorder-list li {
  padding: 15px 15px 15px 15px;
}
.welcome-text {
  padding: 0rem 4rem 2rem 4rem;
  text-align: center;
}

.welcome-text h1 {
  color: rgb(183 158 105);
  font-size: 2.5rem;
  font-weight: 700;
}

.typewriter-wrapper {
  max-width: 70%;
  margin: auto;
}

label.label-wrapper {
  color: #232323;
  font-size: 1.6rem;
  font-weight: 700;
  padding: 0rem 0rem 2rem 0rem;
}

.form-container {
  width: 70%;
  justify-content: center;
  align-self: center;
  margin: 0 auto;
  padding-top: 5rem;
}

.input-email {
  padding: 1.2rem !important;
  border-radius: 2rem;
}

.input-email::placeholder {
  color: #a29e97;
  font-size: 14px;
}

.input-password::placeholder {
  color: #a29e97;
  font-size: 14px;
}

.input-password {
  padding: 1.2rem !important;
  border-radius: 2rem;
}

button.continue-btn {
  width: 100% !important;
  padding: 0.8rem !important;
  border-radius: 2rem !important;
  border: none !important;
  font-size: 1.3rem !important;
  background: #d4a954 !important;
  outline: none !important;
}

.account-text {
  text-align: end;
  font-family: 'Orbitron' !important;
  padding-top: 1rem;
}

.typewriter-wrapper.desc-wrapper-type {
  font-family: system-ui;
}

.underline-text {
  color: #d4a954;
  border-bottom: 1px solid;
  cursor: pointer;
}

.show-div {
  display: block;
}

.hide-div {
  display: none;
}

.login-pagefadein-wrapper {
  -webkit-animation: fadein 5s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 5s; /* Firefox < 16 */
  -ms-animation: fadein 5s; /* Internet Explorer */
  -o-animation: fadein 5s; /* Opera < 15.1 */
  animation: fadein 5s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media screen and (max-width: 1920px) {
  span.shown-on-mobile {
    display: none;
  }
  span.shown-on-desktop {
    display: block;
  }
}

@media screen and (max-width: 1366px) {
  .form-container {
    padding-top: 3rem;
  }
}

@media screen and (max-width: 768px) {
  .signup-section {
    flex-direction: column;
  }

  span.shown-on-mobile {
    display: block;
    height: 6rem;
  }

  .welcome-container {
    padding-bottom: 30px;
    height: 120vh;
  }

  .signup-form-container {
    padding-bottom: 30px;
  }

  span.shown-on-desktop {
    display: none;
  }
}
@media screen and (max-width: 600px) {
  .signup-section {
    flex-direction: column;
  }

  span.shown-on-mobile {
    display: block;
    /* border-bottom: 1px solid #b79e69; */
    height: 6rem;
  }

  .welcome-container {
    padding-bottom: 30px;
    height: 120vh;
  }

  .signup-form-container {
    padding-bottom: 30px;
  }

  span.shown-on-desktop {
    display: none;
  }
}

@media screen and (max-width: 375px) {
  .welcome-text {
    padding: 0rem 1rem 2rem 1rem;
    text-align: center;
    font-size: 2rem !important;
  }
  .typewriter-wrapper {
    max-width: 85%;
    margin: auto;
  }
  .form-container {
    width: 85%;
    padding-top: 2rem;
  }
  .account-text {
    font-size: 14px;
  }
  .react-typewriter-text {
    padding: 0;
    margin: 0;
    text-align: center;
  }
}

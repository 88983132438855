.brainModal3
{
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 990;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

    &_inner
    {
        width: 900px;
        max-height: 100vh;
        overflow: auto;
        background-color: white;
        border-radius: 10px;
        position: relative;
        padding: 40px 20px 30px;

        img
        {
            margin: auto;
            width: 600px;
        }
    }

    p
    {
        margin-bottom: 30px;
    }

    &_close
    {
        position: absolute;
        right: 10px;
        top: 5px;
        font-size: 22px;
        cursor: pointer;
        font-family: sans-serif;
    }

    &_next
    {
        position: absolute;
        top: 50%;
        right: 15px;
        font-size: 40px;
        cursor: pointer;
    }

    &_prev
    {
        position: absolute;
        top: 50%;
        left: 15px;
        font-size: 40px;
        cursor: pointer;
    }
}
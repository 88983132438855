.starchart
{
    background-image: url('../../../../assets/images/star_chart/background.jpg');
    padding: 130px 50px 20px 20px;
    min-height: calc(100vh - 12px);
    margin-top: -80px;

    &_inner
    {
        display: flex;
        justify-content: space-between;
        position: relative;
    }

    &_strip
    {
        width: 100%;
        background-color: black;
    }

    &_left
    {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    &_right
    {
        width: 35%;
        flex-shrink: 0;
    }

    &_info
    {
        width: 100%;
        color: white;
        font-family: sans-serif;
        margin-left: 50px;
    }

    &_select
    {
        color: black;
        margin-top: 5px;
    }

    &_dropdown
    {
        font-family: 'Segoe UI';
        margin-top: 30px;
        width: 350px;
    }

    &_dropdownHead
    {
        border: 1px solid gray;
        height: 40px;
        border-radius: 8px;
        color: white;
        display: flex;
        align-items: center;
        padding-left: 15px;
        background: rgba(255,255,255,0.1);
        margin-bottom: 5px;
    }

    &_dropdownbody
    {
        border: 1px solid gray;
        border-radius: 8px;
        padding: 5px 10px;
        background: rgba(255, 255, 255, 0.1);
        max-height: 400px;
        overflow: auto;
    }

    &_cateogry 
    {
        color: gold;
        border-bottom: 1px solid gray;
        padding: 8px 0;
    }

    &_cName
    {
        color: white;
        border-bottom: 1px solid gray;
        padding: 8px 0 8px 15px;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        p
        {
            cursor: pointer;
        }

        img
        {
            width: 30px;
        }
    }

    &_dropdownbody > div:last-child > &_cName:last-child
    {
        border: none;
    }

	&_constModal
	{
		position: fixed;
		left: 0;
		top: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 99;
		width: 100%;
		height: 100vh;
	}

	&_constModalInner
	{
		border-radius: 20px;
        padding: 20px 35px;
		background: rgb(0 0 0 / 90%);
		position: relative;
		width: 95%;
		max-height: 95vh;

		.line
        {
            width: 150px;
            height: 1px;
            background: white;
            margin: 25px 0;
        }

        button
        {
            background: -webkit-linear-gradient(right,
            #e9dd9c,
            #a87833,
            hsl(37, 37%, 45%),
            #c9a465,
            #ab915d);
            color: white;
            border-radius: 100px;
            padding: 5px 15px;
            margin: 5px 0;
            font-weight: 500;
            font-family: 'Segoe UI';
        }

        img
        {
            max-width: 160px;
			display: block;
			margin: auto;
        }

        p
        {
            color: white;
            margin: 10px 0;
			font-size: 14px;
        }
	}

    &_line
    {
        width: 150px;
        height: 1px;
        background-color: white;
        margin: 20px 0;
    }

    &_close
    {
        position: absolute;
        top: 0;
        right: 15px;
        font-size: 25px;
        cursor: pointer;
    }

    &_bar
    {
        width: 50px;
        margin-right: 30px;
        
        > div
        {
            width: 100%;
            border: 1px solid #9a9a9a;
            border-radius: 5px;
            padding: 10px 0;
    
            img
            {
                margin: 12px auto;
                cursor: pointer;
                width: 35px;
            }
        }
    }

    &_circle
    {
        height: 650px;
        width: 650px;
        position: relative;

        .react-flow
        {
            border-radius: 100%;
        }

    }

    &_circleImg
    {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    &_title
    {
        display: flex;
        align-items: center;

        p
        {
            font-size: 36px;
            font-family: 'raven-bold-inline';
            color: #F2C54D;
        }
    }

    &_fields 
    {
        margin-top: 35px;

        p
        {
            color: white;
            font-family: 'Segoe UI';
            font-size: 18px;
            font-weight: 500;
        }

        input
        {
            background: rgba(255,255,255, 0.1);
            border: 1px solid gray;
            width: 100%;
            border-radius: 5px;
            height: 45px;
            outline: none;
            color: white;
            padding-left: 10px;
            margin: 10px 0;
            font-family: 'Segoe UI';
        }
    }

    &_save
    {
        background: -webkit-linear-gradient(right,
        #e9dd9c,
        #a87833,
        hsl(37, 37%, 45%),
        #c9a465,
        #ab915d);
        color: white;
        border-radius: 100px;
        padding: 8px 40px;
        font-family: 'raven-bold-inline';
        margin-top: 10px;
    }

    &_hiddenCover {
        z-index: 1;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    &_info
    {
        width: 100%;
        color: white;
        font-family: 'Segoe UI';
        margin-left: 50px;
        margin-bottom: 20px;
    }

    &_arrow
    {
        font-size: 50px;
        position: absolute;
        top: 50%;
        right: 50px;
    }

    &_box
    {
        position: relative;

        img
        {
            width: 35px;
        }
    }

    &_tooltip
    {
        background: black;
        color: white;
        font-family: sans-serif;
        border-radius: 5px;
        padding: 10px;
        position: absolute;
        top: 0;
        left: 55px;
        display: none;
        min-width: 200px;
        max-width: 280px;
        z-index: 999;
    }

    &_box:hover &_tooltip
    {
        display: block;
    }

    &_label
    {
        font-size: 10px;
    }

    &_name
    {
        font-size: 13px;
        margin: -5px 0 3px;
        color: darkgrey;
    }

    &_barBox
    {
        position: relative;
    }

    &_barBox:hover &_bartooltips
    {
        display: block;
    }

    &_bartooltips
    {
        background-color: #957341;
        color: white;
        position: absolute;
        top: 10px;
        left: 50px;
        font-family: sans-serif;
        z-index: 1;
        font-size: 12px;
        width: 150px;
        padding: 5px 15px;
        display: none;
    }

    &_bartooltips::after
    {
        content: " ";
        position: absolute;
        left: -7px;
        top: 6px;
        border-top: 8px solid transparent;
        border-right: 8px solid #957341;
        border-left: none;
        border-bottom: 8px solid transparent;
    }

    &_lineType {
        background: transparent;
        border: 1px solid gray;
        color: white;
        font-family: sans-serif;
        width: 150px;
        height: 40px;
        border-radius: 5px;
        margin: 20px 0;

        option {
            color: black;
            font-size: 18px;
        }
    }

    &_btn
    {
        width: 100%;
        margin-top: 50px;

        button
        {
            background: -webkit-linear-gradient(right,
            #e9dd9c,
            #a87833,
            hsl(37, 37%, 45%),
            #c9a465,
            #ab915d);
            color: white;
            border-radius: 100px;
            padding: 8px 40px;
            font-family: 'raven-bold-inline';
            margin-left: 300px;
        }
    }

    &_sidebar
    {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100%;
        background-color: rgba(0,0,0,0.6);
        display: flex;
        justify-content: flex-end;
    }
    
    &_sidebarBox
    {
        background-image: url('../../../../assets/images/star_chart/background2.png');
        width: 50%;
        display: flex;
        align-items: center;
        padding: 50px 0;
        position: relative;
        padding: 0 80px;

        img
        {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(0, -50%);
            cursor: pointer;
        }

        h1
        {
            color: #F2C54D;
            font-family: 'raven-bold-inline';
            margin-bottom: 50px;
        }

        p
        {
            font-size: 14px;
            color: #e7e7e7;
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            line-height: 35px;
        }
    }

    &_openArrow
    {
        height: 60px;
        position: absolute;
        top: 50%;
        right: 0;
        cursor: pointer;
    }

    &_custom
    {
        overflow: visible !important;
    }

    &_msg
    {
        color: white;
        margin-top: 50px;
        font-size: 26px;
        text-align: center;
    }

    &_msg2
    {
        color: white;
        margin-top: 10px;
        font-size: 26px;
        text-align: center;
    }

    &_pagination
    {
        position: absolute;
        bottom: 15px;
        left: 50%;
        transform: translateX(-50%);
        color: white;

        a
        {
            margin: 0 15px;
            font-family: sans-serif;
            color: white;
            text-decoration: none;
        }

        button:disabled
        {
            font-family: sans-serif;
            margin: 0 15px;
            color: gray;
        }
    }

    .react-flow__panel
    {
        display: none;
    }
}

.doublestarchart .starchart_inner {
    justify-content: center;
}

.doublestarchart .starchart_inner > div {
    margin: 0 40px;
}
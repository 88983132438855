.step--box
{
    background-color: black;
    font-family: raven-bold-inline;
    color: white;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.div-droppable-dotted
{
    height: 600px;
    width: 600px;
    border: 5px dotted #c9c8c8;
    margin: auto;
    border-radius: 50%;
    line-height: 40px;
    position: relative;
    margin-top: 105px;
}

.div-droppable
{
    margin: auto;
    position: relative;
}

.div-droppable .spinning-cups > div
{
    width: 150px !important;
    height: 350px !important;
    top: 70px;
    left: 50%;
    transform: translateX(-50%);
}

.island-outer
{
    min-height: 160px;
}

.island_icons
{
    margin-top: 150px;
    padding-left: 20px;
}

img.draggedimg
{
    max-width: 550px;
    height: auto;
    width: 100%;
}

button.refresh_btn
{
    border: none;
    background: transparent;
    color: #fff;
    font-family: raven-bold-inline;
    text-transform: uppercase;
    font-size: 13px;
}

.profile-gotoisland a
{
    margin: 0 !important;
}

/* .volcano-island img {
  width: 100%;
} */
.font-white
{
    color: white !important;
}

.chain-arrow1
{
    margin-left: 25px;
    margin-top: -28px;
    transform: rotate(320deg);
    font-size: 30px;
    color: #9e7d49;
    position: absolute;
}

.chain-arrow2
{
    position: absolute;
    margin-left: 42px;
    margin-bottom: 20px;
    margin-top: -60px;
    transform: rotate(320deg);
    font-size: 30px;
    color: #9e7d49;
}

.usernameisland-text
{
    color: #8a8a8a;
    font-family: raven-bold-inline;
    text-transform: uppercase;
    text-align: center;
}

.openhardcopy-span
{
    color: #8a8a8a;
    font-family: Special Elite, cursive;
    font-size: 17px;
    text-align: center;
}

.openhardcopy-button
{
    background-color: black;
    padding: 6px;
    border-radius: 8px;
    width: 350px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.openhardcopy-button img
{
    margin-right: 10px;
}

.classified-div
{
    cursor: pointer;
    position: absolute;
    margin-top: -170px;
    margin-left: -18px;
    transform: rotate(-70);
}

.classified-div img
{
    height: auto;
    width: 60px;
}

.div-openbutton
{
    text-align: center;
    cursor: pointer;
    margin-bottom: 12px;
    margin-top: 10px;
}

.file-review-text
{
    color: #8a8a8a;
    font-family: Special Elite, cursive;
    font-size: 17px;
    text-transform: uppercase;
}

.blink-arrow
{
    position: absolute;
    margin-top: 0px;
    margin-left: 115px;
    font-size: 30px;
}

.margin-LR
{
    margin-left: auto;
    margin-right: auto;
}

.font-raven
{
    font-family: raven-bold-inline;
}

.savebtn-label
{
    text-transform: uppercase;
    transform: rotate(-5deg);
    position: absolute;
    margin-top: 410px;
    margin-left: 240px;
}

.cancel-label
{
    text-transform: uppercase;
    transform: rotate(-5deg);
    position: absolute;
    margin-top: 420px;
    margin-left: 80px;
}

.uploadBtn-label
{
    text-transform: uppercase;
    transform: rotate(-5deg);
    position: absolute;
    margin-top: 280px;
    margin-left: 140px;
}

.uploadPhoto-letter3
{
    text-align: center;
    font-size: 12px;
    margin-left: 140px;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.letter-mob-div
{
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.uploadcaseprofile-button button
{
    padding-top: 5px;
}

.font-elite
{
    font-family: Special Elite, cursive;
}

.view-content-button
{
    background: -webkit-linear-gradient(right,
            #e9dd9c,
            #a87833,
            #9e7d49,
            #c9a465,
            #ab915d) !important;
    cursor: pointer;
    color: white;
    border-radius: 5px;
    font-family: Special Elite, cursive !important;
    font-size: 17px;
    text-align: center;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

.crossBtn
{
    font-family: raven-bold-inline;
    text-transform: uppercase;
    transform: rotate(-5deg);
    position: absolute;
    margin-top: 320px;
    margin-left: 80px;
}

.saveBtn
{
    font-family: raven-bold-inline;
    text-transform: uppercase;
    transform: rotate(-5deg);
    position: absolute;
    margin-top: 290px;
    margin-left: 240px;
}

.text-center
{
    text-align: center;
}

.file-review
{
    color: #8a8a8a;
    font-family: Special Elite, cursive;
    font-size: 17;
    text-transform: uppercase;
}

.file-text
{
    color: #8a8a8a;
    font-family: Special Elite, cursive;
    text-transform: uppercase;
}

.getIsland
{
    text-align: center;
    margin: 50px;
}

.space-around
{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.divLetterMain
{
    margin-top: 160px;
    position: absolute;
    padding: 25px;
    margin-left: 20px;
}

.divLetterMain .custom-p1 
{
    font-size: 26px;
    color: red;
    font-family: 'raven-bold-inline';
}

.letter-modal .letterTitle
{
    position: absolute;
    top: 140px;
    font-family: 'raven-bold-inline';
    font-size: 28px;
}

/* .divFinalImage
{
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
} */

.island-main-container
{
    width: 100%;
    display: flex;
    animation: fadein 2s;
    -moz-animation: fadein 2s;
    /* Firefox */
    -webkit-animation: fadein 2s;
    /* Safari and Chrome */
    -o-animation: fadein 2s;
}

.modal-content
{
    margin-top: -6rem;
    width: 100%;
    background-color: transparent;
    border: none;
}

.model-island-1 .modal-content
{
    margin-top: -3rem;
    width: 100%;
    background-color: transparent;
    border: none;
}

.modal-body
{
    width: 100%;
    /* margin-left: -27px !important; */
    text-align: center;
}

.logo-img
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.logo-img .article
{
    position: absolute;
    margin-top: 300px;
    margin-left: 20px;
}

.logo-img .logo2
{
    position: absolute;
    margin-top: 150px;
    margin-left: 20px;
    width: 90px;
}

.modal-header
{
    border: none;
    /* margin-top: 20px; */
}

.letter-body
{
    font-family: Segoe UI;
    padding-left: 20px !important;
    padding-right: 20px !important;
    margin-top: 1rem;
}

.letter-body-custom
{
    font-size: 20px;
    font-weight: 600;
    font-family: 'raven-bold-inline';
}

.letter-body .img-mega-phone
{
    width: 50px;
    display: inline-block;
}

.letter-body span
{
    cursor: pointer;
    font-weight: 600;
    font-size: 20px;
    text-decoration: underline;
}

.letter-body .else
{
    color: red;
    font-style: italic;
}

.letter-body a
{
    color: inherit;
}

.letter-body a:hover
{
    text-decoration: none;
    color: inherit;
}

.letter-body .skull-img {
    width: 30px;
    display: inline-block;
    margin: 0 12px;
}

.modal-footer
{
    display: flex;
    margin-top: -70px;
    border: none;
    justify-content: space-around !important;
    z-index: 1;
}

.text-island
{
    color: #8a8a8a;
    font-family: Special Elite, cursive;
    font-size: 17;
    text-transform: uppercase;
}

.left-brain-section_island
{
    flex: 0 0 30%;
    background-color: #20141e;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
}

img.img-fluid.left-island-logo
{
    width: 18%;
}

.volcano-bottom-row-island
{
    display: flex;
    justify-content: space-between;
    margin-left: 4rem;
    margin-right: 4rem;
}

.volcano-bottom-column-island
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}

.access_Island_text
{
    bottom: 0;
}

.arctic_tree
{
    position: absolute;
    margin-top: 2.8rem;
    margin-left: 2.3rem;
}

.arctic-island img
{
    padding-top: 0.5rem;
}

.islands-background
{
    height: 120px;
    width: 120px;
    background-color: white;
    margin-left: 15px;
    text-align: center;
    padding: 10px;
    border-radius: 20px;
}

.left-navbar-island
{
    background-image: url("../../assets/images/topbar_v2.png");
    height: 70px;
}

.right-island-section
{
    flex: 0 0 70%;
    position: relative;
}

.right-island-section .index-modal, .right-island-section .index-modal-spinner
{
    top: 0px;
    right: 300px;
}

.logo2-wrap-static
{
    padding-top: 0.3rem;
    margin-left: 2rem;
}

.inter-route
{
    color: #d7a45c;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: starship;
    font-weight: bold;
    font-size: 1.7rem;
    letter-spacing: 2px;
}

.rightisland-logo
{
    padding: 0.6rem;
}

.island-wrapper
{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-left: -2rem;
}

.rightisland-logo img
{
    width: 70%;
}

.book-img
{
    position: absolute;
    top: 10px;
    right: -2rem;
    width: 120px;
}

.volcano-island
{
    display: flex;
    justify-content: center !important;
}

.reset-tooltip
{
    background: #d3d3d3;
    padding: 15px;
    border-radius: 15px;
    width: 97%;
}

.dragtext
{
    color: #000;
    font-family: "Special Elite", cursive;
    text-align: center;
    margin: 0px auto;
    justify-content: center;
    font-size: 10px;
    opacity: 1;
    position: relative;
}

/* .dragtext .blink {position: absolute;top: 20px;left: 0;right: 0; margin: 0 auto;} */
.go_back_arrow
{
    background: -webkit-linear-gradient(right,
            #e9dd9c,
            #a87833,
            hsl(37, 37%, 45%),
            #c9a465,
            #ab915d);
    text-align: center;
    border-radius: 50px;
    cursor: pointer;
    padding: 10px 20px;
    color: white;
    font-family: raven-bold-inline !important;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.go_back_arrow img
{
    margin-right: 5px;
    display: inline-block;
}

.theme-btn
{
    background: -webkit-linear-gradient(right,
            #e9dd9c,
            #a87833,
            hsl(37, 37%, 45%),
            #c9a465,
            #ab915d);
    /* width: 110%; */
    text-align: center;
    border-radius: 50px;
    cursor: pointer;
    padding: 5px 20px;
    color: white;
    font-family: raven-bold-inline !important;
    font-size: 14px;
    border: 0px;
}

.theme-btn:hover
{
    background: #000;
}

.island-username
{
    color: #8a8a8a;
    padding-top: 20px;
    font-family: raven-bold-inline;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    font-size: 20px;
    margin-bottom: 15px;
}

.file-review-div
{
    margin-bottom: 30px;
    text-align: center;
}

.display-center
{
    display: flex;
    justify-content: center;
}

.diagonalchain
{
    display: flex;
    justify-content: flex-end;
    margin-left: 2px;
    margin-top: -25px;
}

.island-reviewtext
{
    color: #8a8a8a;
    font-family: Special Elite, cursive;
    font-size: 17px;
    text-transform: uppercase;
    margin-bottom: 50px;
    display: inline-block;
}

.font-15
{
    font-size: 15px;
}

.island-suitcase-wrapper
{
    border-radius: 26px;
    transition: 0.3s all;
    animation: 5s bounce-1 ease infinite;
    /* animation: slide-top 2s linear infinite alternate both; */
    cursor: pointer;
}

/* @keyframes slide-top {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(-100px);
              transform: translateY(-100px);
    }
  } */

.island-suitcase-wrapper img
{
    width: 60px !important;
}

.animation-island
{
    text-align: center;
    margin-top: 75px;
}

.animation-island .skull-img 
{
    position: absolute;
    width: 35px;
    top: 140px;
    left: 40px;
    z-index: 1;
    cursor: pointer;
}

.animation-island .thread-img 
{
    position: absolute;
    width: 35px;
    top: 140px;
    left: 85px;
    z-index: 1;
    cursor: pointer;
}

.animation-island .fingerprint-img 
{
    position: absolute;
    width: 30px;
    top: 140px;
    left: 125px;
    z-index: 1;
    cursor: pointer;
}

.animation-island .img-3d
{
    position: absolute;
    width: 30px;
    top: 140px;
    left: 165px;
    z-index: 1;
    cursor: pointer;
}

.animation-island .star-img 
{
    position: absolute;
    width: 30px;
    top: 140px;
    left: 210px;
    z-index: 1;
    cursor: pointer;
}

.animation-island .signal-img 
{
    position: absolute;
    width: 35px;
    top: 140px;
    left: 250px;
    z-index: 1;
    cursor: pointer;
}

.animation-island .portal-img
{
    position: absolute;
    width: 25px;
    top: 165px;
    left: 50px;
    z-index: 1;
    cursor: pointer;
}

.animation-island .crowd-img
{
    position: absolute;
    width: 25px;
    top: 165px;
    left: 85px;
    z-index: 1;
    cursor: pointer;
}

.animation-island .whistle-img
{
    position: absolute;
    width: 30px;
    top: 140px;
    left: 295px;
    z-index: 1;
    cursor: pointer;
}

.animation-island p
{
    font-family: sans-serif;
    position: absolute;
    top: 72px;
    line-height: 18px;
}

.animation-island span
{
    position: absolute;
    top: 200px;
    right: 116px;
    font-family: 'raven-bold-inline';
    cursor: pointer;
}

.video-island
{
    width: 345px !important;
    border: 1px dashed red;
}

.show-expand-button
{
    position: absolute;
    margin-left: 14.3rem;
    margin-top: -3rem;
    background: -webkit-linear-gradient(right,
            #e9dd9c,
            #a87833,
            #9e7d49,
            #c9a465,
            #ab915d);
    border-radius: 50px;
    transition: 0.3s all;
    animation: 5s bounce ease infinite;
    cursor: pointer;
    text-align: center;
    padding: 5px 20px;
}

.show-expand-button span
{
    border: none;
    color: white !important;
    font-size: 0.6rem;
    font-family: raven-bold-inline;
}

.save-button
{
    background: -webkit-linear-gradient(right,
            #e9dd9c,
            #a87833,
            #9e7d49,
            #c9a465,
            #ab915d);
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    border-radius: 50px;
    cursor: pointer;
    color: white !important;
    padding: 8px 10px;
    display: inline-block;
}

.uploadBtn
{
    background: -webkit-linear-gradient(right,
            #e9dd9c,
            #a87833,
            #9e7d49,
            #c9a465,
            #ab915d);
    width: 25%;
    border-radius: 50px;
    cursor: pointer;
    border: none;
    color: white !important;
    padding: 10px;
}

.close-button
{
    position: absolute;
    background: -webkit-linear-gradient(right,
            #e9dd9c,
            #a87833,
            hsl(37, 37%, 45%),
            #c9a465,
            #ab915d);
    border-radius: 50px;
    padding: 5px 20px;
    text-align: center;
    cursor: pointer;
    z-index: 2;
    top: 30px;
}

.close-button span
{
    border: none;
    color: white !important;
    font-size: 0.8rem;
    font-family: raven-bold-inline;
}

#fileInput
{
    display: none;
}

#icon
{
    width: 100px;
    cursor: pointer;
}

.model-island-1 .modal-header .btn-close
{
    background: transparent url("https://s3.us-east-2.amazonaws.com/inter-routes.com/cros-1656617767066.png") center/1em auto no-repeat;
    margin-top: 10rem;
    font-size: xx-large;
}

.model-island-2
{
    background-color: rgb(153, 152, 149, 0.7) !important;
    display: flex;
    justify-content: center;
}

.model-island-2 .modal-dialog
{
    background-color: rgb(255, 255, 255) !important;
}

.imageDestication
{
    margin-left: 95px;
    max-height: 180px;
    height: 180px;
    max-width: 300px;
    margin-top: 50px;
    transform: rotate(-4.4deg);
}

/* IMAGE CROPPER COMPONENT CSS */
.img-container-main
{
    display: flex;
    margin-left: 60px;
    margin-top: 90px;
    margin-bottom: -10px;
}

.img-container
{
    width: 640px;
    height: 480px;
}

.icon-container-main
{
    cursor: pointer;
}

/* ANIMATED ARROW */
.arrow
{
    margin-top: 200px;
    margin-left: 340px;
    position: absolute;
}

.arrow p
{
    width: 220px;
    margin-left: -80px;
}

.arrow img
{
    position: absolute;
    transform: scaleX(-1) rotate(210deg);
}

/* ANIMATED ARROW */
.arrow img:hover
{
    animation: shake 0.5s !important;
    /* animation-iteration-count: infinite; */
}

@keyframes shake
{
    0%
    {
        transform: translate(1px, 1px) rotate(0deg);
    }

    10%
    {
        transform: translate(-1px, -2px) rotate(-1deg);
    }

    20%
    {
        transform: translate(-3px, 0px) rotate(1deg);
    }

    30%
    {
        transform: translate(3px, 2px) rotate(0deg);
    }

    40%
    {
        transform: translate(1px, -1px) rotate(1deg);
    }

    50%
    {
        transform: translate(-1px, 2px) rotate(-1deg);
    }

    60%
    {
        transform: translate(-3px, 1px) rotate(0deg);
    }

    70%
    {
        transform: translate(3px, 1px) rotate(-1deg);
    }

    80%
    {
        transform: translate(-1px, -1px) rotate(1deg);
    }

    90%
    {
        transform: translate(1px, 2px) rotate(0deg);
    }

    100%
    {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}

.arrow-text
{
    background: -webkit-linear-gradient(right,
            #e9dd9c,
            #a87833,
            #9e7d49,
            #c9a465,
            #ab915d);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: raven-bold-inline;
    font-size: 25px;
    margin-bottom: 0px;
}

.blink
{
    animation: blinker 1s step-start infinite;
    font-size: 20px;
}

.island_image
{
    height: 300px;
}

.hard-copy-text
{
    text-align: center;
    background-color: black;
    padding: 10px;
    border-radius: 6px;
    font-size: 2vw;
    font-family: Special Elite, cursive;
}

.animation-close {
    position: absolute;
    top: 150px;
    right: 0px;
    font-size: 35px;
    z-index: 2;
    cursor: pointer;
    color: #c69c6d;
}

.drop-main
{
    position: relative;
}

.drop-inner > div
{
    width: 400px;
    height: 400px;
    background: transparent;
    position: absolute;
    left: 90px;
    top: 30px;
}

.flash-icon
{
    font-family: sans-serif;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    font-size: 36px;
    font-weight: 600;
    animation: iMark 1s ease-in-out infinite alternate;
    position: absolute;
    top: 80px;
    right: 50px;
}

@keyframes iMark
{
    0%
    {
        color: white;
    }

    100%
    {
        color: red;
    }
}

@keyframes blinker
{
    50%
    {
        opacity: 0;
    }
}

.letter3-mob
{
    display: none !important;
}

@media only screen and (max-width: 991px)
{}

@media only screen and (max-width: 767px)
{
    .letter3-mob
    {
        display: none !important;
    }

    .island_heading_tablet img
    {
        margin-right: 12rem !important;
    }

    .left-brain-section_island,
    .right-island-section
    {
        display: none;
    }

    .island_mobile
    {
        width: 155% !important;
        height: 12%;
        background-image: url("../../assets/images/left_panel_2.png");
        background-position: center;
        position: relative;
        top: 0;
    }

    .island_mobile .custom-collapse
    {
        margin-top: -20px;
        margin-left: -20px;
        border: 3px solid #eee1a3;
    }

    .left-island-logo
    {
        margin-top: 3px !important;
        margin-right: 8px;
    }

    .island_mobile .island-nav-bar-responsive
    {
        margin-left: 120px;
    }

    .island_heading_tablet
    {
        background-repeat: no-repeat;
        height: 60px;
        margin-left: 17vw;
    }

    .island_heading_tablet img
    {
        height: 40px;
    }

    .island_heading_mobile
    {
        display: none;
    }

    .island_navigation_tablet
    {
        margin-left: 10px;
        /* margin-top: 15px; */
    }

    .island_navigation_tablet img
    {
        width: 42px !important;
    }

    .text-island-mob
    {
        color: #8a8a8a;
        font-family: Special Elite, cursive;
        font-size: 2.5vw;
        text-transform: uppercase;
        margin-top: 10px !important;
    }

    .close-button-mob
    {
        margin-left: 14.5vw;
        background: -webkit-linear-gradient(right,
                #e9dd9c,
                #a87833,
                #9e7d49,
                #c9a465,
                #ab915d);
        width: 30%;
        border-radius: 50px;
        cursor: pointer;
        padding: 4px;
        text-align: center;
        cursor: pointer;
    }

    .close-button-mob span
    {
        border: none;
        color: white !important;
        font-size: 0.8rem;
        font-family: raven-bold-inline;
    }

    .show-expand-button-mob
    {
        /* position: absolute; */
        margin-left: 13.7rem;
        background: -webkit-linear-gradient(right,
                #e9dd9c,
                #a87833,
                #9e7d49,
                #c9a465,
                #ab915d);
        width: 20%;
        border-radius: 50px;
        margin-top: -3.2rem;
        transition: 0.3s all;
        animation: 5s bounce ease infinite;
        cursor: pointer;
        text-align: center;
        padding: 2px;
    }

    .show-expand-button-mob span
    {
        border: none;
        color: white !important;
        font-size: 0.6rem;
        font-family: raven-bold-inline;
    }

    .classified-mob
    {
        cursor: pointer;
        transform: rotate(-70);
    }

    /* .modal-content {
    width: 150% !important;
  }
  .modal-dialog {
    margin-left: -20px !important;
  } */
    /* .island_mobile_div1 {
    width: 125% !important;
  } */

    .offcanvas
    {
        background-image: url("../../assets/images/left_panel_2.png");
        margin-top: 85px !important;
    }

    .offcanvas-backdrop.show
    {
        opacity: 0 !important;
    }

    .offcanvas-header
    {
        padding: 0.5rem;
    }

    .offcanvas-body
    {
        padding: 0.4rem 1rem;
        /* margin-top: -1rem; */
    }

    .save-button-mobile
    {
        background: -webkit-linear-gradient(right,
                #e9dd9c,
                #a87833,
                #9e7d49,
                #c9a465,
                #ab915d);
        margin-top: -25px;
        margin-left: auto;
        margin-right: auto;
        width: 35%;
        text-align: center;
        border-radius: 50px;
        cursor: pointer;
        color: white !important;
        padding: 2px;
        width: 150px;
        margin-bottom: 20px;
    }

    .bottom-map-mob
    {
        -webkit-box-shadow: 0 0 10px rgb(170, 170, 170);
        box-shadow: 0 0 10px rgb(170, 170, 170);
        margin-top: 8px;
        margin-left: 12px;
        border-radius: 15px;
    }

    .island_image
    {
        height: auto;
        width: 100%;
    }

    .hard-copy-text
    {
        text-align: center;
        background-color: black;
        padding: 10px;
        border-radius: 6px;
        font-size: 18px;
        color: white;
    }

    .hard-copy-text img
    {
        display: inline-block;
    }
}

@media (min-width: 425px) and (max-width: 570px)
{
    .offcanvas
    {
        margin-top: 85px !important;
    }

    /* .navbar-dark .navbar-toggler {
    display: block !important;
  } */

    .letter3-mob
    {
        display: block !important;
        text-align: center;
        font-size: 12;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .island_heading_tablet img
    {
        margin-right: 3.6rem !important;
    }

    .navbar
    {
        position: inherit !important;
    }

    .island_navigation_tablet
    {
        display: none !important;
    }

    .island_heading_mobile
    {
        display: block !important;
    }

    .island_mobile .navbar-toggler-icon
    {
        display: block !important;
    }

    .island_heading_tablet
    {
        margin-left: -5vw !important;
        margin-top: -15px !important;
    }

    .island_heading_tablet img
    {
        margin-left: -5vw !important;
        margin-top: 0px !important;
    }

    .text-island-mob
    {
        font-size: 3.5vw;
    }

    .island_mobile_div1
    {
        width: 100% !important;
    }

    .island_image
    {
        height: 200px;
    }

    .volcano-bottom-row-island
    {
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .file-text
    {
        font-size: 3vw;
    }

    .hard-copy-text
    {
        font-size: 2.4vw;
    }

    .hard-copy-image
    {
        height: 12px !important;
    }

    .video-island
    {
        width: 180px !important;
        border: 1px dashed red;
    }

    .classified-mob
    {
        cursor: pointer;
    }

    .show-expand-button-mob
    {
        margin-left: 6.4rem;
        width: 40%;
        margin-top: -2rem;
        font-size: 0.6vw !important;
    }

    .go_back_arrow
    {
        cursor: pointer;
    }

    .drawer-button
    {
        margin-left: -25.3rem !important;
        margin-top: 0rem !important;
        position: absolute;
    }

    /* .modal-content {
    width: 100% !important;
    padding-left: 7% !important;
    margin-top: -5rem;
    margin-bottom: 2rem !important;
  } */
    .letter-mob
    {
        overflow-y: scroll;
        height: 320px !important;
    }

    .letter3-web
    {
        display: none !important;
    }

    .uploadBtn
    {
        width: 28%;
        border-radius: 50px;
        cursor: pointer;
        border: none;
        color: white !important;
        padding: 2px;
    }

    .cropper-wrap-box
    {
        width: 380px !important;
        height: 500px !important;
    }

    .without_FI_tap
    {
        margin-left: -180px !important;
        margin-top: -15px !important;
        position: absolute !important;
        width: 140px !important;
    }

    .without_FI_Ubutton
    {
        width: 130px;
        font-family: raven-bold-inline !important;
        text-transform: uppercase;
        transform: rotate(-5deg);
        position: absolute;
        margin-top: 90px;
        margin-left: -170px;
    }

    .imageDesticationTap
    {
        position: absolute;
        margin-right: auto;
        margin-top: -390px;
        margin-left: 100px;
    }

    .imageDestication
    {
        margin-left: 100px;
        max-height: 180px;
        height: 180px;
        max-width: 200px;
        margin-top: -216px;
        transform: rotate(-4.5deg);
    }

    .bag_upload
    {
        height: 220px;
        width: 220px;
    }

    .crossBtn
    {
        margin-left: -220px !important;
        margin-top: 220px !important;
        width: 30%;
    }

    .saveBtn
    {
        margin-left: -90px !important;
        margin-top: 210px !important;
    }
}

@media (min-width: 375px) and (max-width: 424px)
{

    /* .navbar-dark .navbar-toggler {
    display: block !important;
  } */
    .crossBtn
    {
        margin-left: -220px !important;
        margin-top: 220px !important;
        width: 30%;
    }

    .saveBtn
    {
        margin-left: -90px !important;
        margin-top: 210px !important;
    }

    .letter3-mob
    {
        display: block !important;
        text-align: center;
        font-size: 12;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .letter3-web
    {
        display: none !important;
    }

    .island_heading_tablet img
    {
        margin-right: 0.6rem !important;
    }

    .navbar
    {
        position: inherit !important;
    }

    .island_navigation_tablet
    {
        display: none !important;
    }

    .island_heading_mobile
    {
        display: block !important;
    }

    .island_mobile .navbar-toggler-icon
    {
        display: block !important;
    }

    .island_heading_tablet
    {
        margin-left: -17vw !important;
        margin-top: -15px !important;
    }

    .text-island-mob
    {
        font-size: 3.5vw;
        margin-top: 20px;
    }

    .drawer-button
    {
        margin-left: -26rem !important;
        margin-top: 0.4rem !important;
        position: inherit !important;
    }

    /* .drawer-button {
    margin-left: -25.3rem !important;
    margin-top: -30rem !important;
    position: absolute;
  } */
    .volcano-bottom-row-island
    {
        margin-left: 1rem;
        margin-right: 4rem;
    }

    .go_back_arrow
    {
        width: 65%;
        margin-left: 2rem;
    }

    .file-text
    {
        font-size: 2.8vw;
    }

    .hard-copy-image
    {
        height: 9px !important;
    }

    .open_button
    {
        height: 25px;
    }

    .island-suitcase-wrapper img
    {
        width: 30px !important;
    }

    .video-island
    {
        width: 140px !important;
        border: 1px dashed red;
    }

    .show-expand-button-mob
    {
        margin-left: 5.1rem;
        width: 42%;
        margin-top: -1rem;
        padding: 0px;
        padding-bottom: 5px;
    }

    .show-expand-button-mob span
    {
        border: none;
        font-size: 1.8vw;
        font-family: raven-bold-inline;
        margin-bottom: 10px;
    }

    .close-button-mob
    {
        margin-left: 14.5vw;
        width: 40%;
        border-radius: 50px;
        cursor: pointer;
        padding: 0px;
    }

    .close-button-mob span
    {
        border: none;
        color: white !important;
        font-size: 0.6rem;
        font-family: raven-bold-inline;
    }

    .classified-mob
    {
        cursor: pointer;
        margin-bottom: -15px;
        margin-top: 2px;
    }

    .letter-mob
    {
        overflow-y: scroll;
        height: 250px !important;
    }

    /* .modal-content {
    width: 100% !important;
    padding-left: 7% !important;
    margin-top: -5rem;
    margin-bottom: 2rem !important;
  } */
    .letter3-web
    {
        display: none !important;
    }

    .cropper-wrap-box
    {
        width: 380px !important;
        height: 500px !important;
    }

    .uploadBtn
    {
        width: 38%;
        padding: 2px;
        margin-left: -2px;
    }

    .without_FI_tap
    {
        margin-left: -172px !important;
        margin-top: -11px !important;
        position: absolute !important;
        width: 120px;
    }

    .without_FI_Ubutton
    {
        font-family: raven-bold-inline !important;
        text-transform: uppercase;
        font-size: 12px !important;
        transform: rotate(-5deg);
        position: absolute;
        margin-left: -165px !important;
        margin-top: 90px !important;
    }

    .upload-text
    {
        font-size: 4vw !important;
    }

    .letter3-mob
    {
        margin-left: 20px;
    }

    .bag_upload
    {
        height: 220px;
        width: 220px;
    }

    .arcticbrr img
    {
        width: 88% !important;
    }

    .imageDesticationTap
    {
        position: absolute;
        margin-right: auto;
        margin-top: -390px;
        margin-left: 50px;
    }

    .imageDestication
    {
        margin-left: 58px;
        max-height: 180px;
        height: 180px;
        max-width: 200px;
        margin-top: -216px;
        transform: rotate(-4.5deg);
    }

    .offcanvas
    {
        margin-top: 85px !important;
        width: 80%;
    }

    .islands-background
    {
        width: 80px;
        height: 80px;
    }

    .islands-background img
    {
        height: 40px !important;
    }

    .access_Island_text a img
    {
        width: 90%;
    }
}

@media (min-width: 320px) and (max-width: 374px)
{

    /* .navbar-dark .navbar-toggler {
    display: block !important;
  } */
    .cancelicon
    {
        display: none !important;
    }

    .crossBtn
    {
        margin-left: -160px !important;
        margin-top: 176px !important;
        font-size: 8px !important;
        width: 26% !important;
    }

    .saveBtn
    {
        margin-left: -70px !important;
        margin-top: 170px !important;
        font-size: 8px !important;
        width: 26% !important;
    }

    .navbar-toggler
    {
        display: block !important;

        /* margin-top: -16px; */
        margin-left: 0px;
    }

    .island_heading_tablet img
    {
        margin-right: 7px !important;
    }

    /* .navbar-toggler {
    position: inherit !important;
    margin-top: -15.5rem !important;
    margin-left: -0.6rem !important;
  } */
    .rounded-circle
    {
        width: 25px !important;
        height: 25px !important;
    }

    .drawer-button
    {
        margin-left: -19.3rem !important;
        margin-top: 0 !important;
        position: absolute;
    }

    .upload-text
    {
        font-size: 4vw !important;
    }

    .letter3-mob
    {
        display: block !important;
        text-align: center;
        font-size: 12;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .letter3-web
    {
        display: none !important;
    }

    .island_navigation_tablet
    {
        display: none !important;
    }

    .island_heading_mobile
    {
        display: block !important;
    }

    .island_mobile .navbar-toggler-icon
    {
        display: block !important;
        width: 20px;
    }

    .island_heading_tablet
    {
        margin-left: -30vw !important;
        margin-top: -12px !important;
    }

    .text-island-mob
    {
        font-size: 3.5vw;
    }

    .uploadBtn
    {
        width: 38%;
        padding: 2px;
        margin-left: -2px;
    }

    .letter-mob
    {
        overflow-y: scroll;
        height: 230px !important;
    }

    .without_FI_Ubutton
    {
        width: 100px;
        font-family: raven-bold-inline !important;
        text-transform: uppercase;
        transform: rotate(-5deg);
        font-size: 2.4vw;
        position: absolute;
        margin-left: -200px !important;
        margin-top: 110px !important;
    }

    .bag_upload
    {
        height: 180px;
        width: 180px;
    }

    .letter3-mob
    {
        margin-left: 12px;
    }

    /* .modal-content {
    width: 100% !important;
    padding-left: 7% !important;
    margin-top: -5rem;
    margin-bottom: 2rem !important;
  } */
    .without_FI_tap
    {
        margin-left: -152px !important;
        margin-top: -11px !important;
        position: absolute !important;
        width: 120px;
    }

    .without_FI_Ubutton
    {
        font-family: raven-bold-inline !important;
        text-transform: uppercase;
        transform: rotate(-5deg);
        position: absolute;
        margin-left: -132px !important;
        margin-top: 90px !important;
    }

    .imageDestication
    {
        margin-left: 60px;
        max-height: 140px;
        height: 140px;
        max-width: 200px;
        margin-top: -176px;
        transform: rotate(-4.5deg);
    }

    .imageDesticationTap
    {
        width: 120px;
        position: absolute;
        margin-right: auto;
        margin-top: -323px;
        margin-left: 63px;
    }

    .island_image
    {
        height: auto;
        width: 100%;
    }

    .getIsland
    {
        text-align: center;
        margin: 20px;
    }

    .volcano-bottom-row-island
    {
        margin-left: 8px;
        margin-right: 1px;
    }

    .file-text
    {
        font-size: 3.2vw;
    }

    .video-island
    {
        width: 130px !important;
        border: 1px dashed red;
    }

    .show-expand-button-mob
    {
        margin-left: 4.7rem;
        width: 42%;
        margin-top: -50px !important;
        padding: 0px;
        padding-bottom: 5px;
    }

    .show-expand-button-mob span
    {
        border: none;
        font-size: 2.4vw;
        font-family: raven-bold-inline;
        margin-bottom: 10px;
    }

    .close-button-mob
    {
        margin-left: 15.5vw;
        width: 40%;
        border-radius: 50px;
        cursor: pointer;
        padding: 0px;
    }

    .close-button-mob span
    {
        border: none;
        color: white !important;
        font-size: 2.5vw;
        margin-bottom: 1rem;
        font-family: raven-bold-inline;
    }

    .island-suitcase-wrapper img
    {
        width: 40px !important;
    }

    .hard-copy-image
    {
        height: 12px !important;
    }

    .go_back_arrow
    {
        width: auto !important;
        margin-left: 2.5rem;
    }

    .arcticbrr img
    {
        width: 88% !important;
    }

    .offcanvas
    {
        margin-top: 65px !important;
        width: 80%;
    }

    .islands-background
    {
        width: 80px;
        height: 80px;
    }

    .islands-background img
    {
        height: 40px !important;
    }

    .access_Island_text a img
    {
        width: 90%;
    }

    .arrow-text
    {
        font-size: 20px;
    }

    .classified-mob
    {
        margin-bottom: 5px;
    }
}

@media screen and (max-width: 600px)
{
    .navisland .navisland-drawer-button
    {
        margin: 0px !important;
        position: absolute;
        right: 0 !important;
        left: auto;
    }

    .island_mobile_div1
    {
        display: block !important;
        width: calc(100% - 25px) !important;
    }

    .volcano-bottom-row-island
    {
        margin-left: 1rem;
        margin-right: 0rem !important;
    }

    .getIsland
    {
        margin: 25px;
    }
}

.book-img img
{
    filter: drop-shadow(0px 0px 15px #00000070);
    border-radius: 30px 0px 0px 30px;
}

@media screen and (max-width: 767px)
{
    .island_mobile_div1
    {
        width: 100% !important;
    }

    .navisland
    {
        margin-left: -40px;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px)
{
    .div-droppable-dotted
    {
        height: 350px;
        width: 350px;
        line-height: 0;
    }

    .right-island-section
    {
        flex: unset;

        width: 75%;
    }

    .left-brain-section_island
    {
        flex: unset;

        width: 25%;
    }

    .image_list img:nth-child(4),
    .image_list img:nth-child(5),
    .image_list img:nth-child(6)
    {
        display: none;
    }
}

.locked_island_bottom
{
    display: flex;
    flex-wrap: wrap;
    padding-left: 30px !important;
}

.locked_island_bottom_lock
{
    margin-top: -8vh;
    margin-left: -2vw;
    position: absolute;

}

.locked_island_bottom_img
{
    width: 9vw;
    padding: 0 1vh;
    background-color: white;
    border-radius: 14px;
    margin-right: 20px;
    margin-bottom: 20px;
    text-align: center;
    display: flex;
    align-items: center;

}

.locked_island_bottom_img img
{
    max-width: 90%;
    /* max-height: 90%; */
}

.access_Island_text a img
{
    width: 100%;
}

.go_back_arrow:hover,
.close-button:hover,
.view-content-button:hover,
.show-expand-button:hover
{
    background: #000 !important;
}

.openhardcopy-button:hover
{
    background: -webkit-linear-gradient(right,
            #e9dd9c,
            #a87833,
            #9e7d49,
            #c9a465,
            #ab915d) !important;
}

@media screen and (min-width: 768px)
{
    .navisland
    {
        display: none !important;
    }
}

@media screen and (max-width: 767px)
{
    .volcano-bottom-row-island
    {
        flex-direction: column;
    }

    .close-button-mob
    {
        margin: auto;
        width: auto !important;
    }
}

@media (min-width: 767px) and (max-width: 1024px)
{
    .popup_reset
    {
        display: none;
    }

    .two_btn
    {
        grid-template-columns: 12fr !important;
    }

    .save-button
    {
        font-size: 10px;
    }
}

.two_btn
{
    display: grid;
    grid-template-columns: 5fr 7fr;
    align-items: baseline;
    gap: 10px;
}

.button_reset_show
{
    text-align: center;
}

.popup_reset
{
    background: #f3f2ef;
    font-size: 14px;
    font-family: Special Elite, cursive;
    padding: 14px;
    border-radius: 5px;
    text-align: center;
}

.button_reset_show span
{
    font-size: 13px;
}

.button_reset_show
{
    padding: 8px 10px;
    position: relative;
}

.popup_reset
{
    position: absolute;
    left: 0;

    top: 100%;
}

.dropdown-item.active,
.dropdown-item:active
{
    background-color: transparent;
}

.text_dropdown
{
    position: relative;
}

.island_dropdown
{
    position: absolute;
    top: 29.3%;
    left: 17%;
}

.island_dropdown button#dropdown-basic img
{
    width: 40px;
}

.island_dropdown .dropdown-menu.show a
{
    border-bottom: 1px solid #cecece;
}

.island_dropdown .dropdown-menu.show a:last-child
{
    border-bottom: none;
}

.island_dropdown .dropdown-menu.show
{
    margin: 0 !important;
    padding: 0;
    max-height: 15rem;
    overflow-y: auto;
}

.island_dropdown .dropdown-menu.show::-webkit-scrollbar
{
    width: 8px;
}

.island_dropdown .dropdown-menu.show::-webkit-scrollbar-track
{
    background: #f1f1f1;
}

.island_dropdown .dropdown-menu.show::-webkit-scrollbar-thumb
{
    background: #888;
    border-radius: 1rem;
}

.island_dropdown .dropdown-menu.show::-webkit-scrollbar-thumb:hover
{
    background: #555;
}

.dropdown-item.active
{
    background-color: #e9ecef;
}

a.dropdown-item
{
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

input[type="radio"]
{
    position: relative;
    height: 18px;
    width: 18px;
    margin-top: 15px;
    accent-color: #b48949;
}

input[type="radio"]::before
{
    height: 10px;
    width: 10px;
    content: "";
    position: absolute;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.4s ease-in-out;
}

.dropdown-menu
{
    min-width: 7.5rem !important;
}

a.dropdown-item:hover input[type="radio"]::before
{
    background: #b48949;
}

@media (min-width: 768px) and (max-width: 992px)
{
    .island_dropdown
    {
        top: 11.6%;
        left: 5%;
    }
}

@media (min-width: 1201px) and (max-width: 1366px)
{
    .island_dropdown
    {
        top: 11.6%;
        left: 16%;
    }
}

@media (min-width: 1025px) and (max-width: 1200px)
{
    .island_dropdown
    {
        top: 11%;
        left: 11%;
    }
}

@media (min-width: 992px) and (max-width: 1024px)
{
    .island_dropdown
    {
        top: 11.4%;
        left: 5%;
    }
}
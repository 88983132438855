.gmapModal
{
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,0.3);

    &_inner
    {
        width: 1200px;
        text-align: center;
        background-color: white;
        position: relative;
    }

    &_close
    {
        position: absolute;
        right: 10px;
        top: 5px;
        font-size: 22px;
        cursor: pointer;
        font-family: sans-serif;
    }

    &_title
    {
        font-size: 18px;
        margin: 10px 0;
    }

    &_legends
    {
        display: flex;
        margin-bottom: 10px;
        margin-left: 10px;


        > div
        {
            margin-right: 10px;
            display: flex;
            align-items: center;
        }
    }

    &_legendName
    {
        font-size: 14px;
        font-family: 'Segoe UI';
    }

    &_legendColor
    {
        width: 15px;
        height: 10px;
        display: inline-block;
        margin-right: 5px;
    }

    &_legendColor.color1
    {
        background: #000000;
    }

    &_legendColor.color2
    {
        background: #FF00DA;
    }

    &_legendColor.color3
    {
        background: #FF0000;
    }

    #googleMaps
    {
        height: calc(100vh - 180px);
        position: relative;
    }
}